import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    'ui:order': ['size', 'size_other', 'top_plate_ply', 'bottom_plate_ply', 'side_plate_ply'],
    size: {
        'ui:title': 'Steel Beam Size',
        'ui:placeholder': 'Select Size',
    },
    size_other: {
        'ui:title': 'Size Other',
        'ui:placeholder': 'Enter Size',
    },
    top_plate_ply: {
        'ui:placeholder': 'Select Top Plate Ply',
    },
    bottom_plate_ply: {
        'ui:placeholder': 'Select Bottom Plate Ply',
    },
    side_plate_ply: {
        'ui:placeholder': 'Select Side Plate Ply',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    newSettings.size_other = formData.size === 'other' ? formData.size_other : ''

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
