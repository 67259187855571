import { DRAWABLE_TYPES } from '../../../../shared/constants/drawable-types'

export const TOASTER_TIMER = 2000

export const otherValue = 'other'

export const placeholderToSpeciesField = { label: 'Select Species', value: '' }
export const placeholderToGradeField = { label: 'Select Grade', value: '' }
export const placeholderToThicknessField = { label: 'Select Thickness', value: '' }

// used only to fetch options
export const tabNameToFetchKeyMap = {
    lumber_settings: 'lumber',
    exterior_settings: 'exterior_roofing',
    waste_factors: 'framing_waste_factor',
    project_materials: 'framing_project_material',
}

// used to get key for each tab
export enum BUILDING_SETTING_KEYS {
    LUMBER_SETTINGS = 'lumber_settings',
    EXTERIOR_SETTINGS = 'exterior_settings',
    EXTERIOR_WASTE_FACTORS = 'exterior_waste_factors',
    WASTE_FACTORS = 'waste_factors',
    PROJECT_MATERIALS = 'project_materials',
    INFORMATION = 'information',
}

// used to get tab label
export enum buildingKeyToTabLabel {
    'lumber_settings' = 'Lumber',
    'exterior_settings' = 'Exterior Roofing',
    'exterior_waste_factors' = 'Exterior Waste Factors',
    'waste_factors' = 'Framing Waste Factors',
    'project_materials' = 'Framing Project Materials',
    'information' = 'Information',
}

// used to know which tab needs fetch options
export const fieldsToFetchData = [
    BUILDING_SETTING_KEYS.LUMBER_SETTINGS,
    BUILDING_SETTING_KEYS.EXTERIOR_SETTINGS,
    BUILDING_SETTING_KEYS.WASTE_FACTORS,
    BUILDING_SETTING_KEYS.PROJECT_MATERIALS,
]

// used to display specific field for option
export const BUILDING_SETTING_OPTION_KEYS = {
    SOFFIT_TYPE: 'soffit_type',
    PLATE_OPTIONS: 'plate_option',
    NON_VENTED_EAVE: 'non_vented_eave',
    NON_VENTED_GABLE: 'non_vented_gable',
    SLAB_ON_GRADE: 'slab_on_grade',
    GROUND_FLOOR_TREATED: 'ground_floor_treated',
    INCLUDE_APRON: 'include_apron',
    INCLUDE_SILL: 'include_sill',
}

// used to get checkbox label in case it's not the same as key
export const fieldKeyToFieldLabel = {
    non_vented_eave: 'NON-VENTED EAVE',
    non_vented_gable: 'NON-VENTED GABLE',
    slab_on_grade: 'Is Slab On Grade',
    ground_floor_treated: 'Is Ground Floor Treated',
    include_apron: 'Interior Window Apron',
    include_sill: 'Interior Window Sill',
}

/** FIELDS ORDER */

export const exteriorRoofingFieldsOrder = [
    'rafter_tail',
    'eave_blocking_width',
    'rafter_tail_size',
    'rafter_tail_length',
    'rafter_tail_spacing',
    'soffit_type',
    'soffit_tg_width',
    'soffit_vent_type',
    'gable_width',
    'non_vented_eave',
    'non_vented_gable',
    'hip_and_ridge_length',
    'fc_texture',
    'roof_vent',
    'lp_texture',
    'underlayment',
    'eave_width',
    'roof_starter',
    'valley_type',
]

export const exteriorWasteFactorFieldsOrder = [
    'roof_waste',
    'bb_shake_waste',
    'roof_accessories',
    'vinyl_trim_waste',
    'siding_waste',
    'default_trim_waste',
    'soffit_waste',
]

export const wasteFactorFieldsOrder = ['floor_waste', 'stud_waste', 'wall_waste', 'roof_waste']

export const projectMaterialsFieldsOrder = [
    'foundation_wall_foam_board_thickness',
    'crawlspace_wall_foam_board_thickness',
    'wall_sheathing_size',
    'slab_foam_thickness',
    'crawlspace_slab_foam_thickness',
    'wall_sheathing',
    'garage_slab_foam',
    'wall_sheathing_2',
    'plate_option',
    'gable_sheathing',
    'slab_on_grade',
    'roof_sheathing',
    'ground_floor_treated',
    'shearwall_sheathing',
    'sub_fascia',
    'house_wrap',
    'include_apron',
    'include_sill',
]

export const informationFieldsOrder = [
    'finished_basement_ceiling',
    'garage_area',
    'third_floor_area',
    'roof_area',
    'main_floor_area',
    'second_floor_area',
    'fourth_floor_area',
    'fifth_floor_area',
    'eave_length',
    'gable_area',
    'gable_length',
]

// fields order by tab
export const FIELDS_ORDER_BY_ACTIVE_BUILDING_TAB = {
    [BUILDING_SETTING_KEYS.EXTERIOR_SETTINGS]: exteriorRoofingFieldsOrder,
    [BUILDING_SETTING_KEYS.EXTERIOR_WASTE_FACTORS]: exteriorWasteFactorFieldsOrder,
    [BUILDING_SETTING_KEYS.WASTE_FACTORS]: wasteFactorFieldsOrder,
    [BUILDING_SETTING_KEYS.PROJECT_MATERIALS]: projectMaterialsFieldsOrder,
    [BUILDING_SETTING_KEYS.INFORMATION]: informationFieldsOrder,
}

/** END OF FIELDS ORDER */

/** FIELD LABELS */

export enum BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS {
    rafter_tail = 'Rafter Tail',
    eave_blocking_width = 'Eave Blocking Width',
    rafter_tail_size = 'Rafter Tail Size',
    rafter_tail_length = 'Rafter Tail Length',
    rafter_tail_spacing = 'Rafter Tail Spacing',
    soffit_type = 'Soffit Type',
    soffit_vent_type = 'Soffit Vent Type',
    non_vented_eave = 'Non-Vented Eave',
    non_vented_gable = 'Non-Vented Gable',
    fc_texture = 'Fiber Cement Texture',
    lp_texture = 'LP Texture',
    soffit_tg_width = 'Soffit TG Width',
    eave_width = 'Eave Width (In)',
    eave_width_other = 'Other Eave Width (In)',
    gable_width = 'Gable Width (In)',
    gable_width_other = 'Other Gable Width (In)',
    valley_type = 'Valley Type',
    hip_and_ridge_length = 'Hip and Ridge Length',
    roof_starter = 'Roof Starter',
    roof_vent = 'Roof Vent',
    underlayment = 'Underlayment',
}

export enum BUILDING_EXTERIOR_WASTE_FACTORS_SETTINGS_LABELS {
    roof_waste = 'Roof Waste',
    roof_accessories = 'Roof Accessories',
    siding_waste = 'Siding Waste',
    bb_shake_waste = 'BB/Shake Waste',
    vinyl_trim_waste = 'Vinyl Trim Waste',
    default_trim_waste = 'Default Trim Waste',
    soffit_waste = 'Soffit Waste',
}

export enum BUILDING_FRAMING_WASTE_FACTORS_SETTINGS_LABELS {
    basement_plate_ply = 'Basement Plate Ply',
    crawlspace_plate_ply = 'Crawlspace Plate Ply',
    main_plate_ply = 'Main Plate Ply',
    '2nd_plate_ply' = '2nd + Plate Ply',
    garage_plate_ply = 'Garage Plate Ply',
    floor_waste = 'Floor Waste',
    stud_waste = 'Stud Waste',
    wall_waste = 'Wall Waste',
    roof_waste = 'Roof Waste',
}

export enum BUILDING_FRAMING_PROJECT_MATERIALS_SETTINGS_LABELS {
    foundation_wall_foam_board_thickness = 'Foundation Wall Foam Board Thickness',
    crawlspace_wall_foam_board_thickness = 'Crawlspace Wall Form Board Thickness',
    slab_foam_thickness = 'Slab Foam Thickness',
    crawlspace_slab_foam_thickness = 'Crawlspace Slab Foam Thickness',
    garage_slab_foam = 'Foundation Garage Slab Foam',
    plate_option = 'Plate Options',
    sub_fascia = 'Sub Fascia',
    roof_sheathing = 'Roof Sheathing',
    wall_sheathing_size = 'Wall Sheathing Size',
    wall_sheathing = 'Wall Sheathing',
    gable_sheathing = 'Gable Sheathing',
    wall_sheathing_2 = 'Wall Sheathing 2',
    shearwall_sheathing = 'Generic Shearwall Sheathing',
    slab_on_grade = 'Slab On Grade',
    ground_floor_treated = 'Ground Floor Treated',
    house_wrap = 'House Wrap',
    include_apron = 'Interior Window Apron',
    include_sill = 'Interior Window Sill',
}

export enum BUILDING_FRAMING_INFORMATION_LABELS {
    finished_basement_ceiling = 'Finished Basement Ceiling',
    garage_area = 'Garage Area',
    third_floor_area = '3rd Floor Area',
    roof_area = 'Roof Area',
    main_floor_area = 'Main Floor Area',
    second_floor_area = '2nd Floor Area',
    fourth_floor_area = '4th Floor Area',
    fifth_floor_area = '5th Floor Area',
    eave_length = 'Eave Length',
    gable_area = 'Gable Area',
    gable_length = 'Gable Length',
}

export const FIELD_LABELS_BY_ACTIVE_BUILDING_TAB = {
    [BUILDING_SETTING_KEYS.EXTERIOR_SETTINGS]: BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS,
    [BUILDING_SETTING_KEYS.EXTERIOR_WASTE_FACTORS]: BUILDING_EXTERIOR_WASTE_FACTORS_SETTINGS_LABELS,
    [BUILDING_SETTING_KEYS.WASTE_FACTORS]: BUILDING_FRAMING_WASTE_FACTORS_SETTINGS_LABELS,
    [BUILDING_SETTING_KEYS.PROJECT_MATERIALS]: BUILDING_FRAMING_PROJECT_MATERIALS_SETTINGS_LABELS,
    [BUILDING_SETTING_KEYS.INFORMATION]: BUILDING_FRAMING_INFORMATION_LABELS,
}

/** END OF FIELD LABELS */

// FIELD LABELS FOR LUMBER SETTINGS
export const BUILDING_LUMBER_SETTINGS_LABELS = {
    eave_length: [
        {
            name: 'blocking',
            label: 'Eave Blocking',
        },
    ],
}

/** VALIDATION */
export const isSoffitWidthRequired = ['v groove', 'fine line', 'beaded ceiling']

export const soffitWidthEnabledOptions = [
    'cedar tg',
    'pine tg',
    'tg pat122',
    '#2yp pat122',
    'd yp tg spruce aqua seal',
    'v groove',
    'fine line',
    'beaded ceiling',
]

// options in soffit type which display conditional field soffit_vent_type
export const soffitTypeVentTypes = [
    '4X8 BC PLY',
    '4X8 FC',
    '4X8 PANEL CEDAR',
    '4X8 PLYBEAD',
    '4X8 PLYWOOD',
    '4X8 ROUGH SAWN',
    '4X8 LP SMARTFIBER (3/8)',
    '4X8 T111',
    'CEDAR TG',
    'CHANNEL RUSTIC CEDAR',
    'PANEL',
]

export enum buildingSettingsValidationText {
    required = 'is required',
    between_0_99 = 'must be between 0 and 99',
}

export const lumberFields: Partial<Record<DRAWABLE_TYPES, string[]>> = {
    [DRAWABLE_TYPES.WALL]: ['studs', 'top_plates', 'bottom_plates', 'wall_bracing', 'fire_blocking'],
    [DRAWABLE_TYPES.EAVE_LENGTH]: ['fascia_boards_1', 'fascia_boards_2', 'blocking', 'rafter_tail', 'sub_fascia'],
    [DRAWABLE_TYPES.GABLE_LENGTH]: [
        'fascia_boards_1',
        'fascia_boards_2',
        'sub_fascia',
        'ladder_framing',
        'bracing',
        'rafter_tail',
    ],
    [DRAWABLE_TYPES.DROPPED_BEAM]: ['beam'],
    [DRAWABLE_TYPES.FLUSH_BEAM]: ['beam'],
    [DRAWABLE_TYPES.RIDGE_BEAM]: ['beam'],
    [DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM]: ['beam'],
    [DRAWABLE_TYPES.HEADER]: ['beam'],
    [DRAWABLE_TYPES.LEDGER]: ['ledger'],
    [DRAWABLE_TYPES.BLOCKING]: ['blocking'],
    [DRAWABLE_TYPES.POST]: ['post'],
    [DRAWABLE_TYPES.FLOOR_SYSTEM]: ['joist', 'rimboard', 'floor_truss'],
    [DRAWABLE_TYPES.ROOF_SYSTEM]: ['joist', 'rafters'],
}

// all field keys
export const FIELD_KEY = {
    EAVE_WIDTH: 'eave_width',
    GABLE_WIDTH: 'gable_width',
    RAFTER_TAIL: 'rafter_tail',
    SOFFIT_TYPE: 'soffit_type',
}

export const fieldsThatHaveOtherValue: string[] = [FIELD_KEY.EAVE_WIDTH, FIELD_KEY.GABLE_WIDTH]

// fields which based on selection display other fields
export const fieldsWhichHaveConditionalFields = [FIELD_KEY.RAFTER_TAIL, FIELD_KEY.SOFFIT_TYPE]

export const RAFTER_TAIL_FIELDS = {
    eave_blocking_width: 'eave_blocking_width',
    rafter_tail_size: 'rafter_tail_size',
    rafter_tail_length: 'rafter_tail_length',
    rafter_tail_spacing: 'rafter_tail_spacing',
}

export const SOFFIT_TYPE_FIELDS = {
    soffit_vent_type: 'soffit_vent_type',
}

// options fields are fields for which we get the data initially but do not render them, only when condition is match
export const optionalFields = [
    RAFTER_TAIL_FIELDS.eave_blocking_width,
    RAFTER_TAIL_FIELDS.rafter_tail_size,
    RAFTER_TAIL_FIELDS.rafter_tail_length,
    RAFTER_TAIL_FIELDS.rafter_tail_spacing,

    SOFFIT_TYPE_FIELDS.soffit_vent_type,
]

export const EXTERIOR_SETTINGS_DEFAULT_ACTIVE_BOOLEAN_FIELDS = [BUILDING_SETTING_OPTION_KEYS.NON_VENTED_GABLE]

export const EXTERIOR_SETTINGS_DEFAULT_INACTIVE_BOOLEAN_FIELDS = [BUILDING_SETTING_OPTION_KEYS.NON_VENTED_EAVE]

// currently used under non_vented_eave_and_gable feature flag
export const EXTERIOR_SETTINGS_BOOLEAN_FIELDS = [
    ...EXTERIOR_SETTINGS_DEFAULT_ACTIVE_BOOLEAN_FIELDS,
    ...EXTERIOR_SETTINGS_DEFAULT_INACTIVE_BOOLEAN_FIELDS,
]

export const PROJECT_MATERIALS_DEFAULT_INACTIVE_BOOLEAN_FIELDS = [
    BUILDING_SETTING_OPTION_KEYS.SLAB_ON_GRADE,
    BUILDING_SETTING_OPTION_KEYS.GROUND_FLOOR_TREATED,
    BUILDING_SETTING_OPTION_KEYS.INCLUDE_APRON,
    BUILDING_SETTING_OPTION_KEYS.INCLUDE_SILL,
]
