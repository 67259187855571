import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

const OTHER = 'other'
const TWO_X_BUILD_UP = '2X BUILD UP'

export const uiSchema: UiSchema = {
    'ui:order': [
        'type',
        'glulam_type',
        'size',
        'size_other_1',
        'size_other_2',
        'height',
        'height_other',
        'called_out_cap',
        'called_out_anchor',
    ],
    type: {
        'ui:placeholder': 'Select Post Type',
    },
    glulam_type: {
        'ui:title': 'Glulam Type',
        'ui:placeholder': 'Select Glulam Type',
    },
    size: {
        'ui:placeholder': 'Select Size',
    },
    height: {
        'ui:placeholder': 'Select Height',
    },
    height_other: { 'ui:title': 'Height other (feet)' },
    called_out_cap: {
        'ui:title': 'Called Out Cap',
        'ui:placeholder': 'Select Called Out Cap',
    },
    called_out_anchor: {
        'ui:title': 'Called Out Base',
        'ui:placeholder': 'Select Called Out Base',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (formData.material !== 'GL') {
        settings.glulam_type = null
    }

    if (formData.height !== OTHER && formData.hasOwnProperty('height_other')) {
        newSettings.height_other = null
    }

    if (formData.height === OTHER && formData.hasOwnProperty('height_other')) {
        newSettings.height = formData.height_other
    }

    if (formData.type === TWO_X_BUILD_UP && formData.size.includes('/')) {
        newSettings.size = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
