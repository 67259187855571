import { distinctUntilChanged, map, takeWhile } from 'rxjs'

import { PathTool } from '../path/Path.tool'
import { Coordinate } from '../../../../../../models/activeDrawable'
import {
    Cursors,
    HIGHLIGH_LINEAR_COLOR_GRADIENT_ACCENTS,
    HIGHLIGHT_COLOR_OPTIONS,
    IMUPState,
    PaperToolConfig,
    VIEW_MODE,
} from '../../../../../types'
import addSelectFunctionalityToHighlight from '../../../../utils/functionality-bindings/addSelectFunctionalityToHighlight'

/**
 * Highlight.tool.ts
 * Allows user to insert colored highlight layers on a 2D plan
 */
export class Highlight extends PathTool {
    static NAME = 'HIGHLIGHT'
    static CURSOR = Cursors.CROSSHAIR

    private static HIGHLIGHT_BLEND_MODE = 'multiply'

    private startingPoint: paper.Point | null = null
    private toBeCreatedHighlightPath: paper.Path | null = null
    private highlightHexString: string = HIGHLIGHT_COLOR_OPTIONS['red']

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Highlight.NAME
        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                map((state) => {
                    return { opacity: state.tools.highlightOpacityValue, color: state.tools.highlightColor }
                }),
                distinctUntilChanged()
            )
            .subscribe(({ color }) => {
                this.highlightHexString = color
            })
    }

    private extractCoordinatesFromRectangle = (topLeft: paper.Point, bottomRight: paper.Point): Coordinate[] => {
        const rect = new this.paper.Rectangle(topLeft, bottomRight)
        const path = new this.paper.Path.Rectangle(rect)

        return path.segments.map((seg) => [seg.point.x, seg.point.y])
    }

    public createHighlight = (coordinates: Coordinate[], color: paper.Color): paper.Path => {
        // This path describes the Material Icons Map Pin SVG
        const highlightLayerPath = new this.paper.Path()

        for (const coord of coordinates) {
            highlightLayerPath.add(new this.paper.Point(coord[0], coord[1]))
        }

        highlightLayerPath.closePath()
        highlightLayerPath.strokeWidth = 0

        const gradientAccentHexColor: string | undefined = HIGHLIGH_LINEAR_COLOR_GRADIENT_ACCENTS[color.toCSS(true)]

        if (!gradientAccentHexColor) {
            highlightLayerPath.fillColor = color
        } else {
            const highlightGradient = new this.paper.Gradient()
            const accentGradientStart = new this.paper.GradientStop(new this.paper.Color(gradientAccentHexColor))
            const gradientStop = new this.paper.GradientStop(color)

            highlightGradient.stops = [accentGradientStart, gradientStop]
            const highlightFinalColor = new this.paper.Color(
                highlightGradient,
                highlightLayerPath.bounds.topLeft,
                highlightLayerPath.bounds.bottomRight
            )

            highlightLayerPath.fillColor = highlightFinalColor
        }

        highlightLayerPath.blendMode = Highlight.HIGHLIGHT_BLEND_MODE

        addSelectFunctionalityToHighlight(highlightLayerPath)

        return highlightLayerPath
    }

    /**
     * Clear the points that are being drawn with the tool
     */
    cancel = () => {
        if (this.toBeCreatedHighlightPath) this.toBeCreatedHighlightPath.remove()

        this.startingPoint = null
        this.toBeCreatedHighlightPath = null

        this.setState('common', { cursor: this.cursor })
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        if (!this.startingPoint) {
            this.startingPoint = event.point

            this.toBeCreatedHighlightPath = this.createHighlight(
                this.extractCoordinatesFromRectangle(this.startingPoint, this.startingPoint),
                new this.paper.Color(this.highlightHexString)
            )
        }
    }

    onMouseUp = () => {
        this.setState('common', { cursor: Highlight.CURSOR }) // resets the cursor in case panning was done
        if (this.toBeCreatedHighlightPath && this.toBeCreatedHighlightPath.segments.length > 1) {
            if (this.toBeCreatedHighlightPath) {
                this.setState('2D', { highlightsToCreate: [this.toBeCreatedHighlightPath.id], cursor: this.cursor })
            }

            // The shape has been created at this point, tool state has been reset
            this.toBeCreatedHighlightPath = null
            this.startingPoint = null
        }
    }

    onMouseDrag = (event) => {
        if (this.toolPanning(event)) return

        if (this.startingPoint instanceof this.paper.Point) {
            // remove the previous rectangle that is no longer relevant
            this.paper.project.activeLayer.lastChild.remove()

            this.toBeCreatedHighlightPath = this.createHighlight(
                this.extractCoordinatesFromRectangle(this.startingPoint, event.point),
                new this.paper.Color(this.highlightHexString)
            )
        }
    }
}

export default Highlight
