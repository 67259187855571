import store from '../../../../stores'
import { setAltSelectedItems, updateSelectedPaperId } from '../../../slices/2D'
import { setActiveMasterSetPlanOption } from '../../../slices/masterSetPlan'

function select2DMasterSetPlanOption(): () => void {
    return function selectMasterSetPlanOption(this: paper.Path) {
        this.selected = true
        store.dispatch(setActiveMasterSetPlanOption(this.data.masterSetPlanId))
        store.dispatch(updateSelectedPaperId(this.id))
    }
}

function deselect2DMasterSetPlanOption(): () => void {
    return function deselectMasterSetPlanOption(this: paper.Path) {
        this.selected = false
        store.dispatch(setActiveMasterSetPlanOption(null))
        store.dispatch(updateSelectedPaperId(null))

        // reset multiple selected items
        store.dispatch(setAltSelectedItems(null))
    }
}

export default function addSelectFunctionalityToMasterSetPlanOption(masterSetPlanOptionPath: paper.PathItem): void {
    masterSetPlanOptionPath.data.onSelect = select2DMasterSetPlanOption().bind(masterSetPlanOptionPath)
    masterSetPlanOptionPath.data.onDeselect = deselect2DMasterSetPlanOption().bind(masterSetPlanOptionPath)
}
