import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { linkGeometryFromFlag } from '../../../api/projects-api'
import { FlagEnum, NewOpeningLink, NormalizedFlag } from '../../../models/flags'
import managers from '../../lib/managers'
import { Flag } from '../../lib/toolBoxes/2D'
import addSelectFunctionalityToFlag from '../../lib/utils/functionality-bindings/addSelectFunctionalityToFlag'
import { ActiveGeometryGroup, selectActiveGeometryGroup } from '../../slices/geometry'
import { selectMaterialFlags, updateFlag } from '../../slices/materialFlags'

export const linkGeometryFromFlagAction = createAction<{
    flag_id: number
    newOpeningLinks?: NewOpeningLink[]
}>('linkGeometryFromFlag')

export function* handleLinkGeometryFromFlag({
    payload: { flag_id, newOpeningLinks },
}: ReturnType<typeof linkGeometryFromFlagAction>) {
    try {
        const paperManager = yield call(managers.get2DManager)

        if (!paperManager) return

        const flagTool: Flag = yield call(paperManager.getTool, Flag.NAME)
        const flags: NormalizedFlag[] = yield select(selectMaterialFlags)

        const flagToUpdate = flags.find((f) => f.id === flag_id)

        if (!flagToUpdate) return

        const newOpeningLinksToAdd: NewOpeningLink[] = newOpeningLinks || []

        if (!newOpeningLinks) {
            // Fallback to activeDrawableGroup if newOpeningLinks is not provided
            const activeDrawableGroup: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)

            if (!activeDrawableGroup || !activeDrawableGroup.openings.length) return

            newOpeningLinksToAdd.push(
                ...activeDrawableGroup.openings
                    .filter((opening) => opening.isActive)
                    .flatMap((opening) =>
                        opening.opening_locations.map((location) => ({
                            document_chunk_id: location.document_chunk_id,
                            coordinates: location.coordinates[0],
                            opening_id: location.opening_id,
                        }))
                    )
            )
        }

        const newFlagResponse = yield call(linkGeometryFromFlag, flag_id, {
            opening_links: newOpeningLinksToAdd,
        })

        if (newOpeningLinks?.length) {
            const type = flagToUpdate.type?.id === 1 ? FlagEnum.TASK : FlagEnum.NOTE

            // add saved flags to blueprint
            const flagGroups: paper.Group[] = yield all(
                newOpeningLinks.map((link) =>
                    call(
                        flagTool.drawFlagByCoords,
                        link.coordinates,
                        flagToUpdate.id,
                        link.opening_id,
                        flagToUpdate.order,
                        type
                    )
                )
            )

            yield all(
                flagGroups.map((group) => call(addSelectFunctionalityToFlag, group.children[0] as paper.CompoundPath))
            )

            yield put(updateFlag(newFlagResponse))
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForLinkGeometryFromFlag(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(linkGeometryFromFlagAction.type, handleLinkGeometryFromFlag)
}
