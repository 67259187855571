import { call, fork, select, StrictEffect, throttle } from 'redux-saga/effects'

import { updateSelectedCoordinates } from '../../slices/2D'
import { selectActiveMode } from '../../slices/common'
import { SAGA_THROTTLE_TIMER, VIEW_MODE } from '../../types'
import { updateActiveDrawableCoordinates } from '../2D/updateDrawableCoordinates'

export function* handleCoordinatesUpdate(
    action: ReturnType<typeof updateSelectedCoordinates>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2DFor3D:
            case VIEW_MODE.Markup2D:
                yield fork(updateActiveDrawableCoordinates, action)
                break

            // At this moment editing is not available in 3D
            case VIEW_MODE.Markup3D:
                break

            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForCoordinatesUpdate(): Generator<StrictEffect, void, unknown> {
    yield throttle(SAGA_THROTTLE_TIMER, updateSelectedCoordinates.type, handleCoordinatesUpdate)
}
