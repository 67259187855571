import { FormValidation, UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'
import { validateGivenFieldsAreFractions } from './validation/fractionValidation'

const otherHeightValue = 'other height'
const otherWidthValue = 'other width'
const otherValue = 'other'

export const uiSchema: UiSchema = {
    schedule_code: {
        'ui:title': 'Schedule Code',
    },
    handling: {
        'ui:title': 'Handling',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    swing: {
        'ui:title': 'Swing',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    transom: {
        'ui:title': 'Has Mulled Transom?',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    transom_comment: {
        'ui:title': 'Transom Height',
        'ui:placeholder': 'Transom Height',
    },
    width_other: {
        'ui:title': 'Other Width',
        'ui:placeholder': 'Door Width',
    },
    height_other: {
        'ui:title': 'Other Height',
        'ui:placeholder': 'Door Height',
    },
    wall_depth_other: {
        'ui:title': 'Other Wall Depth',
    },
    sidelight_width: {
        'ui:title': 'Sidelight Width',
        'ui:placeholder': 'Door sidelight',
    },
    fire_door: {
        'ui:title': 'Fire Door?',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    discrepancy: {
        'ui:title': 'Discrepancy Exists?',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    discrepancy_comment: {
        'ui:title': 'Discrepancy Comment',
    },
    remarks: {
        'ui:title': 'Remarks',
        'ui:widget': 'textarea',
    },
    configuration: {
        'ui:title': 'Configuration',
        'ui:widget': 'textarea',
    },
    'ui:order': [
        'schedule_code',
        'type',
        'number_wide',
        'configuration',
        'operation',
        'handling',
        'swing',
        'transom',
        'transom_comment',
        'width_string',
        'width_other',
        'height_string',
        'height_other',
        'wall_depth',
        'wall_depth_other',
        'hardware',
        'sidelight',
        'sidelight_width',
        'fire_door',
        'discrepancy',
        'discrepancy_comment',
        'remarks',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.transom === 'true') validateGivenFieldsAreFractions(['transom_comment'], formData, errors)
    if (formData.height_string === otherHeightValue) validateGivenFieldsAreFractions(['height_other'], formData, errors)
    if (formData.width_string === otherWidthValue) validateGivenFieldsAreFractions(['width_other'], formData, errors)
    if (formData.wall_depth === otherValue) validateGivenFieldsAreFractions(['wall_depth_other'], formData, errors)
    if (formData.sidelight) validateGivenFieldsAreFractions(['sidelight_width'], formData, errors)
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...{ ...formData, number_wide: Number(formData.number_wide) } }

    if (!formData.sidelight) {
        newSettings.sidelight = ''
        newSettings.sidelight_width = null
    }

    if (formData.number_wide !== '2') {
        newSettings.operation = null
    }

    if (formData.width_string !== otherWidthValue) {
        newSettings.width_other = null
    }

    if (formData.height_string !== otherHeightValue) {
        newSettings.height_other = null
    }

    if (formData.discrepancy === 'false') {
        newSettings.discrepancy_comment = null
    }

    if (formData.transom === 'false') {
        newSettings.transom_comment = null
    }

    if (formData.wall_depth !== otherValue) {
        newSettings.wall_depth_other = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
