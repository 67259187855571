/**
 * List of azure feature flag ids
 */
export enum AzureFeatureFlagIds {
    testAzureFeatureFlagId = 'testFeatureFlagId', // used only for testing
    sg_framing_roofing = 'sg_framing_roofing',
    robust_overhang = 'robust_overhang',
    non_vented_eave_and_gable = 'non_vented_eave_and_gable',
    master_plans_sets = 'master_plans_sets',
    wall_sheathing_enhancements = 'wall_sheathing_enhancements',
    new_gateway = 'new_gateway',
    hardware_tab = 'hardware_tab',
    new_blocking_tool = 'new_blocking_tool',
    steel_beam_plate = 'steel_beam_plate',
    ai_suggestions_filter = 'ai_suggestions_filter',
}
