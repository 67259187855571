export const DRAWABLE_TYPES_LABELS = {
    automated_beam_and_header: 'Suggested Headers',
    appliance: 'Appliance',
    new: 'New',
    window: 'Window',
    patio: 'Patio Door',
    interiorDoor: 'Interior Door',
    entryDoor: 'Entry Door',
    exterior_door: 'Exterior Door',
    interior_door: 'Interior Door',
    siding: 'Siding',
    vent: 'Vent',
    ai: 'AI',
    floorLine: 'Floor Line',
    roofing: 'Roofing',
    trim: 'Trim',
    corner: 'Corner',
    post_wrap: 'Post Wrap',
    le_window: 'Windows',
    le_door: 'Doors',
    le_unknown: 'Suggested Openings',
    soffit_cornice: 'Soffit/Cornice',
    sidelight: 'Sidelight',
    wall: 'Wall',
    stairs: 'Stairs',
    post: 'Bearing Column',
    steel_beam_plate: 'Steel Beam Plate',
    lf_railing: 'L/F Railing',
    hardware: 'Hardware',
    joist: 'Joists',
    le_joist: 'Suggested Joists',
    i_joist: 'I-Joist',
    interior_finished_ceiling: 'Interior Finished Ceiling',
    basement_finished_ceiling: 'Basement Finished Ceiling',
    ceiling_joist: 'Ceiling Joist',
    floor_joist: 'Floor Joist',
    sleeper_joist: 'Sleeper Joist',
    rafter: 'Rafter',
    floor_truss_system: 'Floor Truss',
    floor_truss_bracing: 'Floor Truss Bracing',
    ledger: 'Ledger',
    blocking: 'Blocking',
    headers: 'Headers',
    header: 'Header',
    dropped_beam: 'Drop Beam',
    flush_beam: 'Flush Beam',
    hip_and_valley_beam: 'Hip/Valley Beam',
    ridge_beam: 'Ridge Beam',
    lf_fivedotfive_decking: 'L/F 5.5" Decking',
    foundation_pilon: 'Foundation Pilon',
    trayed_ceiling_and_soffit: 'Trayed Ceiling and Soffit',
    fypon_bracket: 'Fypon Bracket',
    gable_area: 'Gable Area',
    floor_area: 'Floor Area',
    screen_area: 'Screen Area',
    shearwall: 'Shearwall',
    under_valley_sheathing: 'Under Valley Sheathing',
    canales: 'Canales',
    valley_plate: 'Valley Plate',
    framing_roofing: 'Framing Roofing',
    eave_length: 'Eave Length',
    gable_length: 'Gable Length',
    floor_system: 'Floor System',
    roof_system: 'Roof System',
    exterior_roofing: 'Exterior Roofing',
    roof_line: 'Roof Line',
    flashing: 'Flashing',
    siding_starter: 'Siding Starter',
    porch_ceiling: 'Porch Ceiling',
    wildcard_area_tool: 'Wildcard Area Tool',
    wildcard_count_tool: 'Wildcard Count Tool',
    wildcard_line_tool: 'Wildcard Line Tool',
    louver_hog_fence: 'Louver/Hog Fence',
    lf_screen_opening: 'L/F Screen Opening',
    block_rough: 'Block Rough Opening',
    cantilever_closure: 'Cantilever Closure',
    lf_casing: 'L/F Casing',
    garage_area: 'Garage Area',
}

/* LE stands for line_engineering. le_window & le_door are types identified by IMUP
For window, it's clear enough, for doors, we do not know exact type.
User will be prompt to choose type of the door on FE (opening-type-choice-radiobuttons.tsx) */
export const IMUP_WINDOW_AND_DOOR_TYPES_LABELS = {
    le_window: 'Window',
    le_door: 'Door',
    le_unknown: 'Windows And Doors',
    patio: 'Patio Door',
    entryDoor: 'Entry Door',
    sidelight: 'Sidelight',
    //interiorDoor: 'Interior Door', commented out because it's not implemented in IMUP yet
}

export const IMUP_WINDOW_AND_DOOR_TYPES = [
    'window',
    'patio',
    'entryDoor',
    'interiorDoor',
    'le_window',
    'le_door',
    'le_unknown',
    'sidelight',
]

export const LE_WINDOW_DOOR_SUGGESTIONS = ['le_window', 'le_door', 'le_unknown']

/**
 * Original drawable types that comes from backend
 */
export enum DRAWABLE_TYPES {
    AUTOMATED_BEAM_AND_HEADER = 'automated_beam_and_header',
    APPLIANCE = 'appliance',
    NEW = 'new',
    WINDOW = 'window',
    PATIO = 'patio',
    INTERIOR_DOOR = 'interiorDoor',
    ENTRY_DOOR = 'entryDoor',
    EXTERIOR_DOOR = 'exterior_door',
    INTERIOR_DOOR_IMUP = 'interior_door',
    SIDING = 'siding',
    VENT = 'vent',
    AI = 'ai',
    FLOOR_LINE = 'floorLine',
    ROOFING = 'roofing',
    TRIM = 'trim',
    CORNER = 'corner',
    POST_WRAP = 'post_wrap',
    ACCESSORIES = 'accessories',
    LE_WINDOW = 'le_window',
    LE_DOOR = 'le_door',
    LE_UNKNOWN = 'le_unknown',
    SOFFIT_CORNICE = 'soffit_cornice',
    SIDELIGHT = 'sidelight',

    // Wall Types
    WALL = 'wall',
    KING_STUD = 'king_stud',
    SHEARWALL = 'shearwall',

    STAIRS = 'stairs',
    POST = 'post',
    STEEL_BEAM_PLATE = 'steel_beam_plate',
    LF_RAILING = 'lf_railing',
    HARDWARE = 'hardware',

    ROOF_SYSTEM = 'roof_system',
    FLOOR_SYSTEM = 'floor_system',

    // TODO: delete these types when we will use only markup only
    I_JOIST = 'i-joist',
    CEILING_JOIST = 'ceiling_joist',
    RAFTER = 'rafter',
    JOIST = 'joist',
    LE_JOIST = 'le_joist',
    FLOOR_JOIST = 'floor_joist',
    SLEEPER_JOIST = 'sleeper_joist',
    FLOOR_TRUSS_SYSTEM = 'floor_truss_system',
    FLOOR_TRUSS_BRACING = 'floor_truss_bracing',

    INTERIOR_FINISHED_CEILING = 'interior_finished_ceiling',
    BASEMENT_FINISHED_CEILING = 'basement_finished_ceiling',
    LEDGER = 'ledger',
    BLOCKING = 'blocking',
    HEADER = 'header',
    FLUSH_BEAM = 'flush_beam',
    DROPPED_BEAM = 'dropped_beam',
    HIP_AND_VALLEY_BEAM = 'hip_and_valley_beam',
    RIDGE_BEAM = 'ridge_beam',
    LF_FIVE_DOT_FIVE_DECKING = 'lf_fivedotfive_decking',
    LF_SCREEN_OPENING = 'lf_screen_opening',
    FOUNDATION_PILON = 'foundation_pilon',
    TRAYED_CEILING_AND_SOFFIT = 'trayed_ceiling_and_soffit',
    FYPON_BRACKET = 'fypon_bracket',
    GABLE_AREA = 'gable_area',
    FLOOR_AREA = 'floor_area',
    GARAGE_AREA = 'garage_area',
    CANALES = 'canales',
    NOTE = 'note',
    HIGHLIGHTER = 'highlighter',
    VALLEY_PLATE = 'valley_plate',
    UNDER_VALLEY_SHEATHING = 'under_valley_sheathing',
    WILDCARD_COUNT = 'wildcard_count_tool',
    WILDCARD_AREA = 'wildcard_area_tool',
    WILDCARD_LINE = 'wildcard_line_tool',
    EXTERIOR_ROOFING = 'exterior_roofing',
    FRAMING_ROOFING = 'framing_roofing',
    EAVE_LENGTH = 'eave_length',
    GABLE_LENGTH = 'gable_length',
    SIDING_STARTER = 'siding_starter',
    PORCH_CEILING = 'porch_ceiling',
    FLASHING = 'flashing',
    ROOF_LINE = 'roof_line',
    SCREEN_AREA = 'screen_area',
    LOUVER_HOG_FENCE = 'louver_hog_fence',
    BLOCK_ROUGH = 'block_rough',
    CANTILEVER_CLOSURE = 'cantilever_closure',
}

// TODO: delete these types when we will use only markup only
export const IMUP_JOIST_TYPES_GROUP = [
    DRAWABLE_TYPES.I_JOIST,
    DRAWABLE_TYPES.CEILING_JOIST,
    DRAWABLE_TYPES.RAFTER,
    DRAWABLE_TYPES.LE_JOIST,
    DRAWABLE_TYPES.JOIST,
    DRAWABLE_TYPES.SLEEPER_JOIST,
    DRAWABLE_TYPES.FLOOR_JOIST,
]

export const NEW_IMUP_JOIST_TYPES_GROUP = [DRAWABLE_TYPES.ROOF_SYSTEM, DRAWABLE_TYPES.FLOOR_SYSTEM]

// drawable.settings.type
export enum DRAWABLE_SETTINGS_TYPES {
    RAFTER = 'rafter',
    I_JOIST = 'i-joist',
    DIMENSIONAL_JOIST = 'dimensional joist',
    DIMENSIONAL_SLEEPER_JOIST = 'dimensional sleeper joist',
    FLOOR_TRUSS = 'floor truss',
}

export const DRAWABLE_COLORS_BY_TYPE = {
    post_wrap: {
        color: '#7DCD85',
    },
    stairs: {
        color: '#CAA472',
    },
    post: {
        color: '#21d6a0',
    },
    steel_beam_plate: {
        color: '#ff3c97',
    },
    lf_railing: {
        color: '#15f001',
    },
    hardware: {
        color: '#ee8429',
    },
    'i-joist': {
        color: '#7a7d92',
    },
    ceiling_joist: {
        color: '#e6f356',
    },
    floor_joist: {
        color: '#c80fae',
    },
    sleeper_joist: {
        color: '#30b050',
    },
    roof_system: {
        color: '#3fff11',
    },
    floor_system: {
        color: '#c9acc5',
    },
    dimensional_joist: {
        color: '#fdcece',
    },
    dimensional_sleeper_joist: {
        color: '#30b050',
    },
    floor_truss: {
        color: '#72c1fd',
    },
    rafter: {
        color: '#1b9fd5',
    },

    floor_truss_system: {
        color: '#72c1fd',
    },
    floor_truss_bracing: {
        color: '#a64d79',
    },

    ledger: {
        color: '#c9b949',
    },
    blocking: {
        color: '#c80fae',
    },
    header: {
        color: '#8ffb19',
    },
    dropped_beam: {
        color: '#0061e0',
    },
    flush_beam: {
        color: '#68df00',
    },
    hip_and_valley_beam: {
        color: '#10ff10',
    },
    ridge_beam: {
        color: '#10ff10',
    },
    automated_beam_and_header: {
        color: '#2b1618',
    },
    lf_fivedotfive_decking: {
        color: '#b21256',
    },
    foundation_pilon: {
        color: '#9c5eff',
    },
    trayed_ceiling_and_soffit: {
        color: '#acd269',
    },
    fypon_bracket: {
        color: '#c00c39',
    },
    floor_area: {
        color: '#94f2e7',
    },
    interior_finished_ceiling: {
        color: '#69b8fe',
    },
    basement_finished_ceiling: {
        color: '#d34af2',
    },
    canales: {
        color: '#2852fa',
    },
    exterior_door: {
        color: '#02ffff',
    },
    interior_door: {
        color: '#95a8fa',
    },
    valley_plate: {
        color: '#99d60b',
    },
    under_valley_sheathing: {
        color: '#99d60b',
    },
    gable_area: {
        color: '#9ea784',
    },
    louver_hog_fence: {
        color: '#008080',
    },
    block_rough: {
        color: '#30d5c8',
    },
    cantilever_closure: {
        color: '#0000ff',
    },
    lf_casing: {
        color: '#ff0f19',
    },
    king_stud: {
        color: '#ffa500',
    },
    shearwall: {
        color: '#ffa500',
    },
    garage_area: {
        color: '#0d9d49',
    },
    appliance: {
        color: '#03f664',
    },
}

export const DRAWABLE_COLORS_BY_SELECTION = {
    aluminum: {
        label: 'aluminum',
        keyName: 'aluminum',
        color: '#F94144',
    },
    steel: {
        label: 'steel',
        keyName: 'steel',
        color: '#F94144',
    },
    steelShake: {
        label: 'steel shake',
        keyName: 'steelShake',
        color: '#F94144',
    },
    steelBB: {
        label: 'steel b&b',
        keyName: 'steelBB',
        color: '#F94144',
    },
    steelLap: {
        label: 'steel lap',
        keyName: 'steelLap',
        color: '#F94144',
    },
    sqft: {
        label: 'sq ft',
        keyName: 'sqft',
        color: '#F94144',
    },
    vinyl: {
        label: 'vinyl',
        keyName: 'vinyl',
        color: '#F3722C',
    },
    lpLap: {
        label: 'lp lap',
        keyName: 'lpLap',
        color: '#F3722C',
    },
    fiberCementLap: {
        label: 'fiber cement lap',
        keyName: 'fiberCementLap',
        color: '#F3722C',
    },
    stone: {
        label: 'stone',
        keyName: 'stone',
        color: '#F9844A',
    },
    smoothPanel: {
        label: 'smooth panel',
        keyName: 'smoothPanel',
        color: '#F8961E',
    },
    lpBB: {
        label: 'lp b&b',
        keyName: 'lpBB',
        color: '#F9C74F',
    },
    vinylBB: {
        label: 'vinyl b&b',
        keyName: 'vinylBB',
        color: '#F9C74F',
    },
    fiberCementBB: {
        label: 'fiber cement b&b',
        keyName: 'fiberCementBB',
        color: '#F9C74F',
    },
    lpShake: {
        label: 'lp shake',
        keyName: 'lpShake',
        color: '#90BE6D',
    },
    vinylShake: {
        label: 'vinyl shake',
        keyName: 'vinylShake',
        color: '#90BE6D',
    },
    fiberCementShake: {
        label: 'fiber cement shake',
        keyName: 'fiberCementShake',
        color: '#90BE6D',
    },
}

// export const DRAWABLE_COLORS_BY_MATERIAL_FIELD = {
//     solidBeam: {
//         label: 'solid beam',
//         keyName: 'solidBeam',
//         color: '#2f165d',
//     },
// }

// the order is matter, e.g. for walls names, we could have name with or without floor level,
// in this case, name which contain floor level should be before
export const DRAWABLE_COLORS_BY_NAME = {
    porchCeiling: {
        label: 'porch ceiling',
        keyName: 'porchCeiling',
        color: '#43AA8B',
    },
    eaveLength: {
        label: 'eave length',
        keyName: 'eaveLength',
        color: '#4D90BE',
    },
    gableLength: {
        label: 'gable length',
        keyName: 'gableLength',
        color: '#577590',
    },
    insideCorner: {
        label: 'inside corner',
        keyName: 'insideCorner',
        color: '#11B25F',
    },
    pv1: {
        label: 'pv 1',
        keyName: 'pv1',
        color: '#FF595E',
    },
    pv2: {
        label: 'pv 2',
        keyName: 'pv1',
        color: '#FF924C',
    },
    pv3: {
        label: 'pv 3',
        keyName: 'pv3',
        color: '#FFCA3A',
    },
    pv4: {
        label: 'pv 4',
        keyName: 'pv4',
        color: '#C5CA30',
    },
    pv5: {
        label: 'pv 5',
        keyName: 'pv5',
        color: '#8AC926',
    },
    pv6: {
        label: 'pv 6',
        keyName: 'pv6',
        color: '#36949D',
    },
    pv7: {
        label: 'pv 7',
        keyName: 'pv7',
        color: '#1982C4',
    },
    pv8: {
        label: 'pv 8',
        keyName: 'pv8',
        color: '#4267AC',
    },
    pv9: {
        label: 'pv 9',
        keyName: 'pv9',
        color: '#565AA0',
    },
    pv10: {
        label: 'pv 10',
        keyName: 'pv10',
        color: '#6A4C93',
    },
    pv11: {
        label: 'pv 11',
        keyName: 'pv11',
        color: '#59FFC8',
    },
    pv12: {
        label: 'pv 12',
        keyName: 'pv12',
        color: '#4DF0FF',
    },
    pv13: {
        label: 'pv 13',
        keyName: 'pv13',
        color: '#3BAAFF',
    },
    pv14: {
        label: 'pv 14',
        keyName: 'pv14',
        color: '#3059C9',
    },
    pv15: {
        label: 'pv 15',
        keyName: 'pv15',
        color: '#3426C9',
    },
    pv16: {
        label: 'pv 16',
        keyName: 'pv16',
        color: '#9E364C',
    },
    pv17: {
        label: 'pv 17',
        keyName: 'pv17',
        color: '#C4281A',
    },
    pv18: {
        label: 'pv 18',
        keyName: 'pv18',
        color: '#AB6641',
    },
    ev1: {
        label: 'ev 1',
        keyName: 'ev1',
        color: '#FF595E',
    },
    ev2: {
        label: 'ev 2',
        keyName: 'ev2',
        color: '#FF924C',
    },
    ev3: {
        label: 'ev 3',
        keyName: 'ev3',
        color: '#FFCA3A',
    },
    ev4: {
        label: 'ev 4',
        keyName: 'ev4',
        color: '#C5CA30',
    },
    ev5: {
        label: 'ev 5',
        keyName: 'ev5',
        color: '#8AC926',
    },
    ev6: {
        label: 'ev 6',
        keyName: 'ev6',
        color: '#36949D',
    },
    ev7: {
        label: 'ev 7',
        keyName: 'ev7',
        color: '#1982C4',
    },
    ev8: {
        label: 'ev 8',
        keyName: 'ev8',
        color: '#4267AC',
    },
    ev9: {
        label: 'ev 9',
        keyName: 'ev9',
        color: '#565AA0',
    },
    ev10: {
        label: 'ev 10',
        keyName: 'ev10',
        color: '#6A4C93',
    },
    ev11: {
        label: 'ev 11',
        keyName: 'ev11',
        color: '#59FFC8',
    },
    ev12: {
        label: 'ev 12',
        keyName: 'ev12',
        color: '#4DF0FF',
    },
    ev13: {
        label: 'ev 13',
        keyName: 'ev13',
        color: '#3BAAFF',
    },
    ev14: {
        label: 'ev 14',
        keyName: 'ev14',
        color: '#3059C9',
    },
    ev15: {
        label: 'ev 15',
        keyName: 'ev15',
        color: '#3426C9',
    },
    ev16: {
        label: 'ev 16',
        keyName: 'ev16',
        color: '#9E364C',
    },
    ev17: {
        label: 'ev 17',
        keyName: 'ev17',
        color: '#C4281A',
    },
    ev18: {
        label: 'ev 18',
        keyName: 'ev18',
        color: '#AB6641',
    },
    exteriorCabinetOrVanityWall: {
        label: 'exterior cabinet or vanity wall',
        keyName: 'exteriorCabinetOrVanityWall',
        color: '#0000ff',
    },
    exteriorEWPWall: {
        label: 'exterior ewp wall',
        keyName: 'exteriorEWPWall',
        color: '#0000ff',
    },
    blockExteriorWall: {
        label: 'block exterior wall',
        keyName: 'blockExteriorWall',
        color: '#0000ff',
    },
    blockExteriorCabinetOrVanityWall: {
        label: 'block exterior cabinet or vanity wall',
        keyName: 'blockExteriorCabinetOrVanityWall',
        color: '#0000ff',
    },
    walkOutWall: {
        label: 'walk out wall',
        keyName: 'walkOutWall',
        color: '#0000ff',
    },
    garageExteriorWall: {
        label: 'garage exterior wall',
        keyName: 'garageExteriorWall',
        color: '#8ac926',
    },
    interiorCabinetOrVanityWall: {
        label: 'interior cabinet or vanity wall',
        keyName: 'interiorCabinetOrVanityWall',
        color: '#8338ec',
    },
    interiorEWPWall: {
        label: 'interior ewp wall',
        keyName: 'interiorEWPWall',
        color: '#8338ec',
    },
    blockInteriorWall: {
        label: 'block interior wall',
        keyName: 'blockInteriorWall',
        color: '#8338ec',
    },
    blockInteriorCabinetOrVanityWall: {
        label: 'block interior cabinet or vanity wall',
        keyName: 'blockInteriorCabinetOrVanityWall',
        color: '#8338ec',
    },
    blockFireWall: {
        label: 'block fire wall',
        keyName: 'blockFireWall',
        color: '#cc0c0c',
    },
    blockPartyWall: {
        label: 'block party wall',
        keyName: 'blockPartyWall',
        color: '#e36414',
    },
    blockFoundationWall: {
        label: 'block foundation wall',
        keyName: 'blockFoundationWall',
        color: '#335c67',
    },
    parapetWall: {
        label: 'parapet wall',
        keyName: 'parapetWall',
        color: '#0000ff',
    },
    interiorWall: {
        label: 'interior wall',
        keyName: 'interiorWall',
        color: '#8338ec',
    },
    perimeterWall: {
        label: 'perimeter wall',
        keyName: 'perimeterWall',
        color: '#8338ec',
    },
    bearingWall: {
        label: 'bearing wall',
        keyName: 'bearingWall',
        color: '#00bbf9',
    },
    fireWall: {
        label: 'fire wall',
        keyName: 'fireWall',
        color: '#cc0c0c',
    },
    kneeWall: {
        label: 'knee wall',
        keyName: 'kneeWall',
        color: '#f72585',
    },
    partyWall: {
        label: 'party wall',
        keyName: 'partyWall',
        color: '#e36414',
    },
    foundationWall: {
        label: 'foundation wall',
        keyName: 'foundationWall',
        color: '#335c67',
    },
    exteriorWall: {
        label: 'exterior wall',
        keyName: 'exteriorWall',
        color: '#0000ff',
    },
}

export const DRAWABLE_COLORS_BY_LOCATION = {
    top: {
        label: 'top',
        keyName: 'top',
        color: '#277DA1',
    },
    legs: {
        label: 'legs',
        keyName: 'legs',
        color: '#41F7FA',
    },
    bottom: {
        label: 'bottom',
        keyName: 'bottom',
        color: '#2CA6F2',
    },
    windowStill: {
        label: 'window still',
        keyName: 'windowStill',
        color: '#1E78F7',
    },
    crownMold: {
        label: 'crown mold',
        keyName: 'crownMold',
        color: '#4BBAFA',
    },
    accent: {
        label: 'accent',
        keyName: 'accent',
        color: '#507DFA',
    },
    bendBoard: {
        label: 'bend board',
        keyName: 'bendBoard',
        color: '#9E6DBF',
    },
    waterTable: {
        label: 'water table',
        keyName: 'waterTable',
        color: '#AB435E',
    },
    beamWrap: {
        label: 'beam wrap',
        keyName: 'beamWrap',
        color: '#8F4C4C',
    },
    undersideBeamWrap: {
        label: 'underside beam wrap',
        keyName: 'undersideBeamWrap',
        color: '#8F7256',
    },
    cap: {
        label: 'cap',
        keyName: 'cap',
        color: '#A14F27',
    },
    deckFascia: {
        label: 'deck fascia',
        keyName: 'deckFascia',
        color: '#8AC926',
    },
}

export const MATERIAL_MODIFICATION_CONFLICT_COLOR = {
    UNTOUCHED: 'rgb(255, 0, 0)',
    TOUCHED: 'rgb(120, 166, 205)',
}

const SIDING_COLOR = 'rgb(239, 71, 111)'
const ROOFING_COLOR = 'rgb(255, 209, 102)'

export const SELECTED_AI_SUGGESTION_COLOR = 'rgb(255, 0, 0)'

const REFLECTED_GRADIENT_COLORS = {
    blue: {
        outer: 'rgb(0, 82, 204)',
        inner: 'rgb(0, 255, 255)',
    },
    green: {
        outer: 'rgb(13, 199, 0)',
        inner: 'rgb(188, 255, 183)',
    },
}

export const AI_MATERIAL_REFLECTED_GRADIENT_COLORS = {
    trim: {
        outerColor: REFLECTED_GRADIENT_COLORS.blue.outer,
        innerColor: REFLECTED_GRADIENT_COLORS.blue.inner,
    },
    wall: {
        outerColor: REFLECTED_GRADIENT_COLORS.blue.outer,
        innerColor: REFLECTED_GRADIENT_COLORS.blue.inner,
    },
    header: {
        outerColor: REFLECTED_GRADIENT_COLORS.green.outer,
        innerColor: REFLECTED_GRADIENT_COLORS.green.inner,
    },
}

// default colors
export const DRAWABLE_TYPES_COLORS = {
    window: 'rgb(232, 134, 73)',
    patio: 'rgb(45, 216, 206)',
    interiorDoor: 'rgb(170, 68, 153)',
    entryDoor: 'rgb(120, 94, 240)',
    siding: SIDING_COLOR,
    vent: 'transparent',
    section: 'rgb(232, 134, 73)',
    area: 'rgb(45, 216, 206)',
    point: 'rgb(45, 125, 216)',
    ai: 'rgb(45, 125, 216)',
    floorLine: 'rgb(66, 123, 204)',
    roofing: ROOFING_COLOR,
    trim: 'rgb(6, 214, 160)',
    corner: 'rgb(17, 138, 178)',
    post_wrap: 'rgb(7, 59, 76)',
    le_window: 'rgb(232, 134, 73)',
    le_door: 'rgb(45, 125, 216)',
    le_unknown: 'rgb(45, 125, 216)',
    sidelight: 'rgb(120, 94, 240)',
    le_joist: 'rgb(67, 122, 135)',
    valley_plate: 'rgb(153, 214, 11)',
    under_valley_sheathing: 'rgb(153, 214, 11)',
    [DRAWABLE_TYPES.WILDCARD_AREA]: 'rgb(45, 125, 216)',
    [DRAWABLE_TYPES.WILDCARD_COUNT]: 'rgb(45, 125, 216)',
    [DRAWABLE_TYPES.WILDCARD_LINE]: 'rgb(45, 125, 216)',
    [DRAWABLE_TYPES.SIDING_STARTER]: SIDING_COLOR,
    [DRAWABLE_TYPES.FLASHING]: ROOFING_COLOR,
    [DRAWABLE_TYPES.ROOF_LINE]: ROOFING_COLOR,
}

export enum DRAWING_TYPES {
    SECTION = 'section',
    AREA = 'area',
    POINT = 'point',
    RADIUS = 'radius',
    FLAG = 'flag',
}

/**
 * AI opening types for takeoff are stored in ClassIds. Docs on which number stands for which type is here:
 * https://confluence.myparadigm.com/pages/viewpage.action?pageId=82871644 - floor
 * https://confluence.myparadigm.com/pages/viewpage.action?pageId=86344723 - elevation
 */

export const AI_DRAWABLE_EL_TYPES = {
    0: DRAWABLE_TYPES.WINDOW, // Rectangular
    1: DRAWABLE_TYPES.WINDOW, // Non-Rectangular
    2: DRAWABLE_TYPES.ENTRY_DOOR, // Garage Door
    3: DRAWABLE_TYPES.ENTRY_DOOR, // Service Door
    4: DRAWABLE_TYPES.ENTRY_DOOR, // Entry Door
    5: DRAWABLE_TYPES.PATIO,
    6: DRAWABLE_TYPES.ENTRY_DOOR, // Sidelite
    7: DRAWABLE_TYPES.WINDOW, // Transom
    8: DRAWABLE_TYPES.VENT,
}

export const AI_DRAWABLE_FLOOR_TYPES = {
    0: DRAWABLE_TYPES.INTERIOR_DOOR, // bi_fold_door
    1: DRAWABLE_TYPES.INTERIOR_DOOR, // Double_door
    2: DRAWABLE_TYPES.ENTRY_DOOR, // Garage Door
    3: DRAWABLE_TYPES.ENTRY_DOOR, // left_door
    4: DRAWABLE_TYPES.ENTRY_DOOR, // right_door
    5: DRAWABLE_TYPES.WINDOW, // window
    6: DRAWABLE_TYPES.ENTRY_DOOR, // barn_door
    7: DRAWABLE_TYPES.INTERIOR_DOOR, // by_pass_door
    8: DRAWABLE_TYPES.PATIO, // patio_door
    9: DRAWABLE_TYPES.INTERIOR_DOOR, // pocket door
    10: DRAWABLE_TYPES.INTERIOR_DOOR, // sliding door
    11: DRAWABLE_TYPES.INTERIOR_DOOR, // cased opening (a door way w/o)
}
