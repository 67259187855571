import { Coordinates2D } from '../../../imup/types'

// Taken from JSTS library for length of linestring
export function lengthOFCoordinates(pts: Coordinates2D) {
    const n = pts.length

    if (n <= 1) return 0.0
    let len = 0.0
    let p = pts[0]
    let x0 = p[0]
    let y0 = p[1]

    for (let i = 1; i < n; i++) {
        p = pts[i]
        const x1 = p[0]
        const y1 = p[1]
        const dx = x1 - x0
        const dy = y1 - y0

        len += Math.sqrt(dx * dx + dy * dy)
        x0 = x1
        y0 = y1
    }

    return len
}
