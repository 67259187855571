import { createAction } from '@reduxjs/toolkit'
import { all, call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects'

import { setActiveDrawableGroup, UPDATE_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { deleteOpeningGroupMaterial, deleteOpenings } from '../../../api/projects-api'
import { ActiveFloor } from '../../../models/activeFloor'
import { Project } from '../../../models/project'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import { deleteDrawableLocation } from '../../slices/2D'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { ActiveGeometryGroup, GeometryGroup } from '../../slices/geometry'
import { selectProject } from '../2D/createDrawableLocation'
import { handleToggleDrawableGroups2D, selectDrawableGroups } from '../2D/handleToggleDrawableGroups'

export const deleteDrawableGroup = createAction<{ drawableGroupId: number; isAllPlans: boolean }>('deleteDrawableGroup')

export interface DeleteOpeningGroupApiResponse {
    groupDeleted: number
}

export interface DeleteOpeningApiResponse {
    deletedOpenings: number[]
    errors: {
        error: string
        opening_id: number
    }[]
}

export function* handleDeleteDrawableGroup({ payload }: ReturnType<typeof deleteDrawableGroup>) {
    try {
        const { drawableGroupId, isAllPlans } = payload

        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const [workspaceTool, selectTool]: [Workspace, Select] = yield call(manager.getTools, [
            Workspace.NAME,
            Select.NAME,
        ])

        const project: Project = yield select(selectProject)
        const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)

        if (!workspaceTool && !project && !activeFloor) return

        const allDrawableGroups: GeometryGroup[] = yield select(selectDrawableGroups)

        const currentDrawableGroup = allDrawableGroups?.find((drawableGroup) => drawableGroup.id === drawableGroupId)

        if (!currentDrawableGroup) return

        // all plans, delete group
        if (isAllPlans) {
            try {
                yield call(deleteOpeningGroupMaterial, project.id, currentDrawableGroup.id)

                // remove drawables and drawable groups
                yield put({
                    type: UPDATE_OPENING_GROUPS_SUCCESS,
                    payload: {
                        openingGroups: {
                            newGroup: {
                                ...currentDrawableGroup,
                                openings: [],
                            },
                            originalGroup: currentDrawableGroup,
                        },
                    },
                })

                // remove from drawablesLocations
                yield all(currentDrawableGroup.openings.map((opening) => put(deleteDrawableLocation([opening.id]))))

                // remove from blueprint
                yield call(workspaceTool.removeItemsWithOpeningGroupId, currentDrawableGroup.id)

                // we need this to enable material selection in material section and enable material dropdown
                yield call(setActiveDrawableGroup, null)

                yield call(selectTool.exitSelectMode)
            } catch (e) {
                yield call(console.error, `Error on API deletion: ${e}`)
            }
        } else {
            const drawablesIdsToDelete = currentDrawableGroup?.drawablesPerTab?.map((opening) => opening.id)

            if (!drawablesIdsToDelete?.length) return

            try {
                const res: SagaReturnType<typeof deleteOpenings> = yield call(
                    deleteOpenings,
                    project.id,
                    drawablesIdsToDelete
                )

                const openingToKeep = currentDrawableGroup.openings.filter(
                    (openingToKeep) => !res.deletedOpenings.includes(openingToKeep.id)
                )

                // set as empty because we delete everything from the group
                const drawableGroupWithoutDeletedDrawables: ActiveGeometryGroup = {
                    ...currentDrawableGroup,
                    openings: openingToKeep,
                    drawables: openingToKeep,
                }

                // remove drawables and drawable groups
                yield put({
                    type: UPDATE_OPENING_GROUPS_SUCCESS,
                    payload: {
                        openingGroups: {
                            newGroup: drawableGroupWithoutDeletedDrawables,
                            originalGroup: currentDrawableGroup,
                        },
                    },
                })

                // remove only deleted drawable locations from drawablesLocations
                yield all(res.deletedOpenings.map((drawableId) => put(deleteDrawableLocation([drawableId]))))

                // remove only deleted drawables from blueprint
                yield all(
                    res.deletedOpenings.map((openingId) => call(workspaceTool.removeItemsWithDrawableId, openingId))
                )

                // we need this to enable material selection in material section and enable material dropdown
                yield call(setActiveDrawableGroup, null)

                yield call(selectTool.exitSelectMode)
            } catch (e) {
                yield call(console.error, `Error on API deletion: ${e}`)
            }
        }

        yield call(handleToggleDrawableGroups2D)
    } catch (error) {
        console.error(error)
    }
}

export function* watchForDeleteDrawableGroup() {
    yield takeLatest(deleteDrawableGroup.type, handleDeleteDrawableGroup)
}
