import { FormValidation, UiSchema } from '@rjsf/core'

import {
    calculateAreaMeasurementsForMaterial,
    calculateLFMeasurementOfMaterial,
} from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { Region } from '../models/region'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { pitchNumberValidation } from '../shared/services/field-validation/field-validation-service'
import { calculateTotalArea } from '../utils/formSchema/formSchema-helper-functions'

const gablePattern = '(gable|GABLE)'
const eavePattern = '(eave|EAVE)'
const lengthPattern = '(length|LENGTH)'
const ceilingPattern = '(ceiling|CEILING)'
const porchCeilingPattern = `(porch|PORCH) ${ceilingPattern}`

export const eaveGablePattern = `(${eavePattern}|${gablePattern}) ${lengthPattern}`
export const hipPattern = '(hip|HIP)'
export const valleyPattern = '(valley|VALLEY)'
export const flushBeamPattern = '(flush|FLUSH)'
export const blockingPattern = '(blocking|BLOCKING)'
const evPvPattern = '^(ev|EV|pv|PV|Ev|Pv)'
const cedarAndPineValues = ['cedar tg', 'pine tg']
const vGrooveAndFineLineAndBeadedCeilingValues = ['v groove', 'fine line', 'beaded ceiling']
const pitchOtherValue = 'other'

const uiSchema: UiSchema = {
    label: {
        'ui:title': 'Label',
        'ui:widget': 'hidden',
    },
    name: {
        'ui:readonly': true,
        'ui:widget': 'hidden',
    },
    board_width_other: {
        'ui:title': 'TG Width Other',
    },
    pitch_other: {
        'ui:title': 'Pitch Other',
        'ui:placeholder': 'Pitch Other',
    },
    pitch1_other: {
        'ui:placeholder': 'Pitch 1 Other',
        'ui:title': 'Pitch 1 Other',
    },
    pitch2_other: {
        'ui:placeholder': 'Pitch 2 Other',
        'ui:title': 'Pitch 2 Other',
    },
    cornice_crown_molding: {
        'ui:title': 'Has Cornice Crown Molding?',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    folder: {
        'ui:readonly': true,
        'ui:widget': 'hidden',
    },
    fc_texture: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    fascia_fc_texture: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    frieze_fc_texture: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    'ui:order': [
        'folder',
        'name',
        'label',
        'selection',
        'fc_texture',
        'location',
        'fascia_type',
        'fascia_fc_texture',
        'fascia_width_1',
        'fascia_width_2',
        'fascia_thickness',
        'frieze_type',
        'frieze_fc_texture',
        'frieze_width_1',
        'frieze_width_2',
        'frieze_thickness',
        'board_width',
        'board_width_other',
        'pitch',
        'pitch_other',
        'pitch1',
        'pitch1_other',
        'pitch2',
        'pitch2_other',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    const pitchFields = ['pitch', 'pitch1', 'pitch2']

    pitchFields.forEach((pitchName) => {
        if (formData[pitchName] === pitchOtherValue) {
            const pitchOtherFieldName = `${pitchName}_other`

            if (!pitchNumberValidation(formData[pitchOtherFieldName])) {
                errors[pitchOtherFieldName].addError(FORM_ERROR_MESSAGES.PITCH_NUMBER)
            }
        }
    })
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }
    const isEveGable = new RegExp(eaveGablePattern, 'gm').test(newSettings.name)

    if (newSettings.selection !== '4x10 panel fc') {
        newSettings.fc_texture = null
    }

    if (newSettings.fascia_type !== 'FIBER CEMENT') {
        newSettings.fascia_fc_texture = null
    }

    if (newSettings.frieze_type !== 'FIBER CEMENT') {
        newSettings.frieze_fc_texture = null
    }

    if (isEveGable) {
        if (!formData.fascia_type) {
            newSettings.fascia_type = ''
            newSettings.fascia_fc_texture = null
            newSettings.fascia_width_1 = null
            newSettings.fascia_width_2 = null
            newSettings.fascia_thickness = null
        }

        if (!formData.frieze_type) {
            newSettings.frieze_type = ''
            newSettings.frieze_fc_texture = null
            newSettings.frieze_width_1 = null
            newSettings.frieze_width_2 = null
            newSettings.frieze_thickness = null
        }
    }

    if (
        !cedarAndPineValues.includes(formData.selection) ||
        !vGrooveAndFineLineAndBeadedCeilingValues.includes(formData.selection)
    ) {
        newSettings.board_width = ''
        newSettings.board_width_other = null
    }

    if (newSettings.pitch === pitchOtherValue && newSettings.pitch_other) {
        newSettings.pitch = String(newSettings.pitch_other).toUpperCase()

        delete newSettings.pitch_other
    } else if (newSettings.pitch !== pitchOtherValue) {
        newSettings.pitch_other = null
    }

    if (newSettings.pitch1 === pitchOtherValue && newSettings.pitch1_other) {
        newSettings.pitch1 = String(newSettings.pitch1_other).toUpperCase()

        delete newSettings.pitch1_other
    } else if (newSettings.pitch1 !== pitchOtherValue) {
        newSettings.pitch1_other = null
    }

    if (newSettings.pitch2 === pitchOtherValue && newSettings.pitch2_other) {
        newSettings.pitch2 = String(newSettings.pitch2_other).toUpperCase()

        delete newSettings.pitch2_other
    } else if (newSettings.pitch2 !== pitchOtherValue) {
        newSettings.pitch2_other = null
    }

    newSettings.label = !formData.label && settings.label ? null : formData.label

    return newSettings
}

/**
 * Augment the drawables of the from based on the new settings
 * @returns
 * @param input
 */
function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
    dpi: number | null
    pdfScale: number
    xCalibrationFactor: number
    yCalibrationFactor: number
    regions: Region[]
}): ActiveDrawable[] {
    const { drawables, newSettings, scaleFactor, dpi, pdfScale, xCalibrationFactor, yCalibrationFactor, regions } =
        input

    const isPorchCeiling =
        !newSettings.name || (newSettings.name && new RegExp(porchCeilingPattern, 'gm').test(newSettings.name))
    const isGableLength =
        !newSettings.name || (newSettings.name && new RegExp(gablePattern, 'gm').test(newSettings.name))
    const isValley = !newSettings.name || (newSettings.name && new RegExp(valleyPattern, 'gm').test(newSettings.name))
    const isHip = !newSettings.name || (newSettings.name && new RegExp(hipPattern, 'gm').test(newSettings.name))
    const isEvPv = !newSettings.name || (newSettings.name && new RegExp(evPvPattern, 'gm').test(newSettings.name))

    return drawables.map((drawable) => {
        if (isGableLength || isHip || isValley) {
            drawable.settings.quantity = calculateLFMeasurementOfMaterial(drawable.settings.linear_total, {
                type: DRAWABLE_TYPES.ROOFING,
                settings: newSettings,
            })
        } else if (isPorchCeiling || isEvPv) {
            const totalArea: number = calculateTotalArea(
                drawable.opening_locations,
                regions,
                scaleFactor,
                pdfScale,
                xCalibrationFactor,
                yCalibrationFactor,
                dpi
            )

            drawable.settings.quantity = calculateAreaMeasurementsForMaterial(totalArea, {
                type: DRAWABLE_TYPES.ROOFING,
                settings: newSettings,
            })
        }

        return drawable
    })
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form
