import { TakeableChannel } from 'redux-saga'
import { actionChannel, call, fork, select, StrictEffect, take } from 'redux-saga/effects'

import { createDrawableLocation } from '../../slices/2D'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { createPaperJSItem } from '../2D/createPaperJSItem'

export function* handleCreateDrawableLocation(
    action: ReturnType<typeof createDrawableLocation>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2DFor3D:
            case VIEW_MODE.Markup2D:
                yield fork(createPaperJSItem, action)
                break
            case VIEW_MODE.Markup3D:
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForCreateDrawableLocation(isTesting?: boolean): Generator<StrictEffect, void, unknown> {
    const creationRequestChannel = yield actionChannel(createDrawableLocation.type)

    while (true) {
        const action = yield take(creationRequestChannel as TakeableChannel<ReturnType<typeof createDrawableLocation>>)

        yield call(handleCreateDrawableLocation, action as ReturnType<typeof createDrawableLocation>)
        if (isTesting) {
            break
        }
    }
}
