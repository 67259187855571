import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import { fetchProjectPending, fetchProjectSuccess } from '../actions'
import {
    fetchConfiguratorSpecSheet,
    fetchProjectDataFromPortal,
    setErrorState,
    setProjectBuildings,
    setProjectModifications,
} from '../actions/drawable'
import { getToken } from '../authConfig'
import { RevisionFileResponse } from '../components/projects/project/utils/hooks/useRevisionFiles'
import {
    RuleEngineStateDto,
    SessionAPIResponse,
} from '../containers/main/navbar/center-panel/components/project-completion-dropdown/components/rules-engine/types'
import { UpdateCalibrationInput } from '../imup/sagas/2D/saveUpdatedCalibrationFactor'
import {
    DeleteOpeningApiResponse,
    DeleteOpeningGroupApiResponse,
} from '../imup/sagas/effects/handleDeleteDrawableGroup'
import { RotateImageResponse } from '../imup/sagas/effects/rotateImage'
import { setActiveBuilding } from '../imup/slices/buildings'
import { BasicFloorMapping } from '../imup/slices/mappings'
import {
    BulkOpeningCreationData,
    IMUP2DCoordinatesToUpdate,
    IMUP2DDrawableCutout,
    MeasurementsToUpdate,
    UpdateRuleEngineStateDto,
} from '../imup/types'
import { Coordinate, Opening_group, OpeningAPI, OpeningGroupAPI } from '../models/activeDrawable'
import { Floor } from '../models/activeFloor'
import { PublishAdminOptions } from '../models/adminOptions'
import { AIAutomatedObject } from '../models/aiClassifications'
import { DocumentChunk } from '../models/documentChunk'
import { DocumentMapping } from '../models/documentMapping'
import {
    FlagAPIPayload,
    FlagAPIResponse,
    FlagAPIUpdatePayload,
    FlagAxiosAPIResponse,
    FlagOpeningLinksAPIUpdatePayload,
    NewOpeningLink,
} from '../models/flags'
import { IImportNewPdfResponse } from '../models/importNewPdfResponse'
import { IMasterSetPlanOption, IMasterSetPlanToPost, IMaterialModificationConflict } from '../models/masterSetPlan'
import { Project } from '../models/project'
import ProjectModel from '../models/project.model'
import { Building } from '../models/projectBuilding'
import { Modification } from '../models/projectModification'
import { ProjectsList } from '../models/projectsList'
import {
    NewSpecSheet,
    NewSpecSheetData,
    ProjectServicePackageSpecSheet,
    SpecSheetQuestion,
    SpecSheetStep,
} from '../models/specSheet'
import { IToolObject, IToolObjectPost } from '../models/tool'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { PROJECT_TYPES_ENUM } from '../shared/constants/project-type.constants'
import { ENV_VAR_NAMES, getEnvVar } from '../shared/services/env-services'
import { CreateMaterialApiOpeningType, UpdateOpeningGroupApiResponse } from './api-helper'

export interface CreateOpeningResponse extends OpeningAPI {
    opening_group: Opening_group
    floor: Floor
}

const getProjects = (
    search?: string,
    limit?: number,
    offset?: number,
    completed?: boolean,
    setLastUpdated?: (dateNow: number) => void
): Promise<ProjectsList> => {
    return axios
        .get<void, ProjectsList>('projects', {
            params: {
                search,
                limit,
                offset,
                completed,
            },
        })
        .then((res) => {
            setLastUpdated && setLastUpdated(Date.now())

            return res
        })
}

const getProjectById = (id: number): Promise<Project> => {
    return axios.get<void, Project>(`project/${id}`).then((res) => {
        return res
    })
}

const getProjectsRevisions = (
    portal_number: string,
    order = 'desc',
    order_by = 'id',
    offset = 0,
    limit = 25,
    setLastUpdated?: (dateNow: number) => void
): Promise<ProjectsList> => {
    return axios
        .get<void, ProjectsList>('projectrevisions', {
            params: {
                portal_number,
                order,
                order_by,
                offset,
                limit,
            },
        })
        .then((res) => {
            setLastUpdated && setLastUpdated(Date.now())

            return res
        })
}

const finishProjectMapping = (id, data) => {
    const filteredData = data.filter((item) => !!item.regions.length)
    const body = {
        mappings: filteredData,
    }

    return axios.post(`project/${id}/document-mapping`, body)
}

const updateProjectMappings = (id, mappings) => {
    const body = {
        mappings: {
            updateMappingsOrder: mappings,
        },
    }

    return axios.put(`project/${id}/document-mapping`, body)
}

const getProjectSettings = (id: number): ((dispatch: any) => Promise<void | Project>) => {
    return (dispatch) => {
        dispatch(fetchProjectPending())

        return axios
            .get<void, Project>(`project/${id}/document-mappings`)
            .then((res) => {
                dispatch(fetchProjectSuccess(res))

                return res
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

const fetchProjectDocumentMapping = (id): Promise<Project> => {
    return axios.get<void, Project>(`project/${id}/document-mappings`)
}

const fetchProjectDocumentChunks = (id: number): Promise<DocumentChunk[]> => {
    return axios.get<void, DocumentChunk[]>(`project/${id}/document-chunks`)
}

const deleteProjectDocumentChunks = (
    projectId: number,
    documentChunksIds: number[]
): Promise<{ deletedDocumentChunks: number[] }> => {
    return axios.delete<void, { deletedDocumentChunks: number[] }>(`project/${projectId}/update/document-chunks`, {
        data: { document_chunk_ids: documentChunksIds },
    })
}

const restoreProjectDocumentChunks = (id: number, documentChunksIds: number[]) => {
    return axios.put(`project/${id}/update/document-chunks`, { document_chunk_ids: documentChunksIds })
}

const updateDocumentChunkCalibrationFactor = (
    documentChunkId: number,
    regionId: number | null,
    input: UpdateCalibrationInput
): Promise<DocumentChunk> => {
    return axios.put<void, DocumentChunk>(`document-chunk/${documentChunkId}`, {
        regionId,
        ...input,
    })
}

const fetchAllProjectDocumentMappings = (id: number): Promise<DocumentMapping[]> => {
    return axios.get<void, DocumentMapping[]>(`project/${id}/all-document-mappings`)
}

const getProject = (id: number): Promise<Project> => {
    return axios.get<void, Project>(`project/${id}`)
}

const specSheetConfigurator = {
    loadForQuote: (specSheetInfo: ProjectServicePackageSpecSheet): string => {
        const quoteId = specSheetInfo.configuratorQuoteId
        const lineItemId = specSheetInfo.configuratorLineItemId
        const brandName = specSheetInfo.configuratorBrandName

        const endPoint = getEnvVar(ENV_VAR_NAMES.PORTAL_CONFIGURATOR)

        return `${endPoint}/${quoteId}/${lineItemId}/loadforquote/${brandName}`
    },

    loadStep: (stepId: string, configuratorId: string): string => {
        const endPoint = getEnvVar(ENV_VAR_NAMES.PORTAL_CONFIGURATOR)

        return `${endPoint}/${configuratorId}/steps/${stepId}`
    },
}

const getProjectFromPortal = (portalId: string): any => {
    return async (dispatch) => {
        const token = await getToken()

        if (!token || !import.meta.env.VITE_PORTAL_PROJECT_API) {
            return Promise.resolve(null)
        }

        const projectApi = import.meta.env.VITE_PORTAL_PROJECT_API + `/${portalId}`

        fetch(projectApi, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Not able to fetch Portal Project ID: '${portalId}'`)
                }

                return res.json()
            })
            .then((res) => {
                const projectServicePackage = res.projectServicePackages.find((s) => s.configuratorLineItemId)

                if (projectServicePackage) {
                    fetch(specSheetConfigurator.loadForQuote(projectServicePackage as ProjectServicePackageSpecSheet), {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    })
                        .then(async (res) => {
                            const data = await res.json()

                            return {
                                data,
                                webcpCookie: res.headers.get('Webcp-Cookie'),
                            }
                        })
                        .then(async (res) => {
                            if (!res?.webcpCookie) return

                            const sessionId = res.webcpCookie.match(/SessionId=([^;]+)/)?.[1]
                            const configuratorId = res.data.configurator?.ConfiguratorKey
                            const stepsInfo = res.data.configurator?.ConfiguratorState?.StepsInfo?.filter(
                                (stepInfo) =>
                                    stepInfo.Name?.toLowerCase() !== 'product selection' &&
                                    stepInfo.Name?.toLowerCase() !== 'settings'
                            )

                            if (sessionId && configuratorId && stepsInfo.length) {
                                const preparedSteps: NewSpecSheetData[] = []

                                const isHeaderQuestion = (question: SpecSheetQuestion): boolean => {
                                    if (question.ExtraFields) {
                                        return question.ExtraFields.some(
                                            (f) => f.Key === 'SpecSheetGroupStyle' && f.Value === 'Header'
                                        )
                                    }

                                    return false
                                }

                                const getAnswerText = (question: SpecSheetQuestion): string | undefined => {
                                    let answerText: string | undefined = undefined

                                    if (question.QuestionType === 'FreeEntry') {
                                        answerText = question.SelectedAnswerID
                                    } else {
                                        answerText = question.Answers.find(
                                            (answer) => answer.ID === question.SelectedAnswerID
                                        )?.Text
                                    }

                                    if (!isEmpty(answerText) && answerText !== 'No') {
                                        return answerText
                                    }

                                    return undefined
                                }

                                const fetchConfig = {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Accept: 'application/json',
                                        Authorization: `Bearer ${token}`,
                                        'Webcp-Cookie': res.webcpCookie,
                                    },
                                }

                                for (const stepInfo of stepsInfo) {
                                    await fetch(
                                        specSheetConfigurator.loadStep(stepInfo.ID, configuratorId),
                                        fetchConfig
                                    )
                                        .then<SpecSheetStep>((res) => res.json())
                                        .then((res) => {
                                            const prepareData = res.Questions.map<NewSpecSheet | null>((question) => {
                                                const specSheetQA: NewSpecSheet = {
                                                    label: question.QuestionText,
                                                    isHeader: isHeaderQuestion(question),
                                                }

                                                if (specSheetQA.isHeader) {
                                                    specSheetQA.value = question.SelectedAnswerID

                                                    return specSheetQA
                                                }

                                                specSheetQA.value = getAnswerText(question)

                                                if (isEmpty(specSheetQA.value)) {
                                                    // It's not an Header but it have not Value?
                                                    // We don't need to display it.
                                                    // Remove it
                                                    return null
                                                }

                                                return specSheetQA
                                            }).filter((e) => e !== null) as NewSpecSheet[]

                                            if (prepareData.length > 0) {
                                                // Display a Step only if it have at least one selection
                                                const step: NewSpecSheetData = {
                                                    name: res.Name,
                                                    data: prepareData,
                                                    order: res.Order,
                                                }

                                                preparedSteps.push(step)
                                            }

                                            return res
                                        })
                                        .catch((error) => {
                                            console.error(error)
                                        })
                                }
                                dispatch(fetchConfiguratorSpecSheet(preparedSteps.sort((a, b) => a.order - b.order)))
                            }

                            return res
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                }
                dispatch(fetchProjectDataFromPortal(res))

                return res
            })
            .catch((error) => {
                console.error(error)
            })
    }
}

const createTakeoffProject = (project: ProjectModel): Promise<Project> => {
    const formData = new FormData()
    const planFiles = project.plan_files ? project.plan_files : []

    planFiles.forEach((file: File, index) => {
        if (!file) {
            return
        }
        formData.append(`plan_files[${index}]`, file)
    })

    // Delete already filled FormData keys
    delete project.plan_files
    delete project.floors

    // Delete not supported keys
    delete project.floor_plans
    delete project.elevation_views

    // The window takeoff endpoint does not support project_number & is3D parameter
    // 3D projects go through IMUP workflow
    delete project.project_number
    delete project.is3D

    Object.keys(project).forEach((key) => {
        formData.append(key, project[key])
    })

    // Default to widows takeoff project creation endpoint
    return axios.post<void, Project>('project', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

const createProject = (project: ProjectModel): Promise<Project> => {
    if (project.type === PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP) {
        return axios.post<void, Project>(
            `markupproject/${project.project_number}/${project.is3D}`,
            {}, // no body needed, only params.
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }

    return createTakeoffProject(project)
}

const getAvailableFloors = (): Promise<BasicFloorMapping[]> => {
    return axios.get<void, BasicFloorMapping[]>('floors')
}

/**This API updates the project status only from AI_PROCESSING to READY_FOR_TAKEOFF */
const updateProjectStatus = (projectId: number) => {
    return axios.post(`project/${projectId}/bypassaiclassification`)
}

/**
 *
 * @param projectId
 * @param chunk_id
 * @param floor_hash 'front' / 'rear' / 'floor_1' etc
 * @param scale_factor Scale factor should be in the format like '1:72' where 72 is the scale
 */
const updateProjectScale = (projectId: string | number, chunk_id: number, floor_hash: string, scale_factor: string) => {
    const data = {
        chunk_id: chunk_id,
        scale_factor: scale_factor,
        floor_hash: floor_hash,
    }
    const body = {
        mappings: data,
    }

    return axios.put(`project/${projectId}/document-mapping`, body)
}

const postRotatedImages = (imageChunks): Promise<RotateImageResponse> => {
    return axios.post<void, RotateImageResponse>('project/imageProcess', { imageChunks })
}

const resetProject = (id?: number, isMerge = false, is3D = false) => {
    return axios.post(`project/${id}/markup/reset`, { isMerge, is3D })
}

const reset3DProject = (portalNumber) => {
    return axios.post(`project/reset3d/${portalNumber}`)
}

const fetchProjectBuildings = (projectId: number) => {
    return (dispatch) => {
        return axios
            .get<void, Building[]>(`project/${projectId}/buildings`)
            .then((res) => {
                dispatch(setProjectBuildings(res))

                // set first building as active for settings page
                dispatch(setActiveBuilding({ building: res[0] }))
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

const fetchProjectModifications = (projectId: number) => {
    return (dispatch) => {
        return axios
            .get<void, Modification[]>(`project/${projectId}/modifications`)
            .then((res) => {
                dispatch(setProjectModifications(res))
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

const saveProjectBuildingsSettings = (projectId: number, buildings: Building[]) => {
    return axios.put(`project/${projectId}/update-buildings`, { buildings })
}

const updateProjectPdfGenerationSettings = (projectId: number, isPdfGenerationChecked: boolean) => {
    return axios.put(`project/${projectId}/update-pdf-generation-settings`, { isPdfGenerationChecked })
}

const updateProjectSettings = (
    projectId: number,
    settings: {
        isPdfGenerationChecked?: boolean
        isSendingOutputToPortal?: boolean
    } = { isPdfGenerationChecked: false, isSendingOutputToPortal: true }
): Promise<Project> => {
    return axios.put(`project/${projectId}/update-settings`, settings)
}

/** API creates opening group material and adds openings to it.
 * Adding openings is optional, opening group can be created as empty.
 * If groupId is passed, openings will be added to the existing group,
 * if groupId is not passed, new opening_group material will be created first
 *
 * @param projectId
 * @param groupId
 * @param settings  has to be provided to create new opening_group
 * @param type has to be provided to create new opening_group
 * @param remarks optional for group creation
 * @param configurations optional for group creation
 * @param color optional for group creation
 * @param openings
 */

// Unable to specify the return type due to unknown data.
// ActiveDrawable | undefined OR OpeningGroupAPI
const createOpeningGroupMaterial = (
    projectId: number,
    groupId?: number,
    settings?: Object,
    type?: DRAWABLE_TYPES,
    remarks?: string,
    configurations?: string,
    color?: string,
    openings?: CreateMaterialApiOpeningType[]
) => {
    const body = {
        type,
        settings,
        openings,
        configurations,
        remarks,
        color,
    }

    return axios.post<void, OpeningGroupAPI>(`project/${projectId}/openinggroup${groupId ? '/' + groupId : ''}`, body)
}

/** API soft deletes opening_group, its openings and opening_locations
 * @param projectId
 * @param groupId
 */
const deleteOpeningGroupMaterial = (projectId: number, groupId: number): Promise<DeleteOpeningGroupApiResponse> => {
    return axios.delete<void, DeleteOpeningGroupApiResponse>(`project/${projectId}/openinggroup/${groupId}/delete`)
}

/** API deletes openings and updates group measurements
 * @param projectId
 * @param openings_ids
 */
const deleteOpenings = (projectId: number, openings_ids: number[]): Promise<DeleteOpeningApiResponse> => {
    const body = {
        opening_ids: openings_ids,
    }

    return axios.put<void, DeleteOpeningApiResponse>(`project/${projectId}/delete/openings`, body)
}

/** API updates opening_location coordinates for the opening of IMUP
 * @param projectId
 * @param openingId
 * @param locationId
 * @param coordinates
 * @param region_id
 */
const updateOpeningLocationCoordinates = (
    projectId: number,
    openingId: number,
    locationId: number,
    coordinates: IMUP2DCoordinatesToUpdate,
    region_id: number | null
) => {
    coordinates.region_id = region_id
    const body = coordinates

    return axios.put<void, UpdateOpeningGroupApiResponse>(
        `project/${projectId}/opening/${openingId}/location/${locationId}/update`,
        body
    )
}

/** API creates an opening inside the existing material group */
const createOpening = (
    projectId: number,
    floor_hash: string,
    opening_group_id: number,
    coordinates: Coordinate[],
    document_chunk_id: number,
    measurements: MeasurementsToUpdate,
    settings: {
        unit_of_measure: string
        shape_type?: string
    },
    region_id: number | null,
    additional_data?: Record<string, any>,
    ai_suggestion_id?: string,
    cutouts?: IMUP2DDrawableCutout[]
): Promise<CreateOpeningResponse> => {
    const body = {
        coordinates,
        opening_group_id,
        document_chunk_id,
        measurements,
        settings,
        region_id,
        additional_data,
        ai_suggestion_id,
        cutouts,
    }

    return axios.post<void, CreateOpeningResponse>(`project/${projectId}/floor/${floor_hash}/opening`, body)
}

/** API creates an opening inside the existing material group */
const bulkCreateOpenings = (
    projectId: number,
    openings: BulkOpeningCreationData[]
): Promise<CreateOpeningResponse[]> => {
    const body = {
        openings,
    }

    return axios.post<void, CreateOpeningResponse[]>(`project/${projectId}/bulk-openings`, body)
}

const deleteOpening = (projectId: number, floor: string, openingId: number) =>
    axios.delete<void, string>(`project/${projectId}/floor/${floor}/opening/${openingId}`)

const getProjectDeliverablesStatus = async (portalId?: number) => {
    const token = await getToken()

    if (!token || !import.meta.env.VITE_PORTAL_PROJECT_DELIVERABLES_STATUS_API) {
        return Promise.resolve(null)
    }

    const projectApi = import.meta.env.VITE_PORTAL_PROJECT_DELIVERABLES_STATUS_API + `/${portalId}`

    return fetch(projectApi, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            return res
        })
        .catch((error) => {
            console.error(error)
        })
}

const getProjectRevisionFiles = (
    portalNumber: number | undefined,
    projectId: number
): Promise<RevisionFileResponse[]> => {
    return axios.get<void, RevisionFileResponse[]>('files', {
        params: {
            file_location: `${portalNumber}/${projectId}`,
            is_dir: 'true',
        },
    })
}

const reCreateMarkupOnlyProject = (portalNumber: number): void => {
    axios.put(`recreate/markuponly/${portalNumber}`)
}

/**
 * API adds opening_location to the existing opening
 * @param opening_id
 * @param coordinates
 * @param document_chunk_id
 * @param projectId
 * @param region_id
 * @param additional_data
 * @returns updated opening
 */
const addOpeningLocation = (
    opening_id: number,
    coordinates: Coordinate[],
    document_chunk_id: number,
    projectId: number,
    region_id?: number | null,
    additional_data?: any | null
): Promise<OpeningAPI> => {
    return axios.post<void, OpeningAPI>(`project/${projectId}/opening/${opening_id}/location`, {
        coordinates,
        opening_id,
        document_chunk_id,
        region_id,
        additional_data,
    })
}

/**
 * API deletes opening_location from the exsisting opening
 * A single opening_location can not be deleted, deleteOpening Api should be used instead
 * @param opening_id
 * @param opening_location_id
 * @returns updated opening
 */
const deleteOpeningLocation = (opening_id: number, opening_location_id: number): Promise<OpeningAPI> => {
    return axios.delete<void, OpeningAPI>(`opening/${opening_id}/deletelocation/${opening_location_id}`)
}

const duplicateDocumentChunk = (project_id: number, document_chunk_id: number) => {
    return axios.post(`project/${project_id}/copy-document/${document_chunk_id}`, {
        project_id,
        document_chunk_id,
    })
}

const duplicateDocumentChunkAndEstimations = (project_id: number, document_chunk_id: number) => {
    return axios.post(`project/${project_id}/copy-document-and-estimations/${document_chunk_id}`, {
        project_id,
        document_chunk_id,
    })
}

const recalculateMeasurementsByProjectId = (projectId: number) => axios.put(`recalculate-materials`, { projectId })

/**
 * Api triggers the queue to process project-pdf into images
 * and update project data
 * @param projectId
 * @returns
 */
const importNewPdf = (projectId: number): Promise<IImportNewPdfResponse> => {
    return axios.put<void, IImportNewPdfResponse>(`import-new-pdfs/${projectId}`)
}

const triggerAI = (projectId: number, documentMappingId?: number): Promise<null> => {
    return documentMappingId
        ? axios.post<void, null>(`project/${projectId}/aiclassification/${documentMappingId}`)
        : axios.post<void, null>(`project/${projectId}/aiclassification`)
}

const getAIsuggestions = (projectID: number): Promise<AIAutomatedObject[] | void> => {
    return axios
        .get<void, AIAutomatedObject[]>(`project/${projectID}/automated-objects`)
        .then((res) => {
            return res.map((ob) => {
                return {
                    ...ob,
                    settings: JSON.parse(ob.settings.toString()),
                }
            })
        })
        .catch((error) => {
            console.error(error)
        })
}

const fetchProjectFlags = (project_id: number): Promise<FlagAPIResponse[]> => {
    return axios.get<void, FlagAPIResponse[]>(`project/${project_id}/flags`)
}

const createFlag = (project_id: number, body: FlagAPIPayload): Promise<FlagAPIResponse> => {
    return axios.post<void, FlagAPIResponse>(`project/${project_id}/flag`, body)
}

const updateFlag = (project_id: number, flag_id: number, body: FlagAPIUpdatePayload): Promise<FlagAxiosAPIResponse> => {
    return axios.put<void, FlagAxiosAPIResponse>(`project/${project_id}/flag/${flag_id}`, body)
}

const updateFlagOpenings = (body: { opening_links: FlagOpeningLinksAPIUpdatePayload[] }) => {
    return axios.put(`project/flagopenings`, body)
}

const deleteMultipleFlags = (flag_ids: number[]) => {
    return axios.post(`project/flag`, { flag_ids })
}

const linkGeometryFromFlag = (
    flag_id: number,
    body: { opening_links: NewOpeningLink[] }
): Promise<FlagAxiosAPIResponse> => {
    return axios.put(`project/flag/${flag_id}/link`, body)
}

const unlinkGeometryFromFlag = (openingLinkedItemId: number) => {
    return axios.delete(`project/flag/${openingLinkedItemId}/unlink`)
}

// Unable to specify the return type due to unknown data.
// ResponseAdminOptions | FlagOptions
const fetchProjectFlagsOptions = () => {
    return axios.get(`flag/options`)
}

const publishAdminOptions = (body: PublishAdminOptions) => {
    return axios.post(`flag/options`, body)
}

export type Copy3DProjectResponse = {
    message: string
    modTicket: string
    projectNumber: string
}
const copy3DProjectFromProductionToStaging = (portalProjectNumber: string): Promise<Copy3DProjectResponse> => {
    return axios.post<void, Copy3DProjectResponse>('copyPortalProject', {
        portalProjectNumber,
    })
}

/**
 * Create a new tool object on the current project
 * @param projectId
 * @param document_chunk_id
 * @param toolObject
 */
const createToolObject = (
    projectId: number,
    document_chunk_id: number | undefined,
    toolObject: IToolObjectPost
): Promise<IToolObject> => {
    return axios.post<void, IToolObject>(
        `project/${projectId}/document-chunk/${document_chunk_id}/context_markup`,
        toolObject
    )
}

/**
 * Create Master Set Plan Options
 * @param projectId
 *  * @param document_chunk_id
 *  * @param masterSetPlanOptionRegionsToCreate
 */

const createMasterSetPlans = (
    projectId: number,
    document_chunk_id: number,
    masterSetPlanOptionRegionsToCreate: IMasterSetPlanToPost
): Promise<IMasterSetPlanOption> => {
    return axios.post<void, IMasterSetPlanOption>(
        `project/${projectId}/document-chunk/${document_chunk_id}/plan-options`,
        masterSetPlanOptionRegionsToCreate
    )
}

/**
 * Fetch Project Context Markups, for now uses for only tool Ojects , maybe will be extended in the future
 * @param project_id
 */
const fetchProjectContextMarkups = (project_id: number): Promise<IToolObject[]> => {
    return axios.get<void, IToolObject[]>(`project/${project_id}/context_markups`)
}

/**
 * Update tool object data
 * @param projectId
 * @param document_chunk_id
 * @param toolObject
 */
const updateProjectContextMarkups = (
    projectId: number,
    document_chunk_id: number,
    toolObject: IToolObject
): Promise<IToolObject> => {
    return axios.put<void, IToolObject>(
        `project/${projectId}/document-chunk/${document_chunk_id}/context_markup/${toolObject.id}`,
        toolObject
    )
}

/**
 * Delete tool object data
 * @param contextMarkupIds
 */
const deleteProjectContextMarkups = (contextMarkupIds: number[]) => {
    return axios.delete('context_markup', { data: { context_markup_ids: contextMarkupIds } })
}

const get3DDesignOptionRulesSystemAndUpdate = (projectId: number) => {
    return axios.get<void, SessionAPIResponse>(`project/${projectId}/3d-design-options-and-phases`)
}

const createModification = (modificationConflict: IMaterialModificationConflict[], projectId: number) => {
    const postData = {
        modifications: modificationConflict.map((m) => omit(m, ['id', 'shapeColor'])),
    }

    return axios.post<void, IMaterialModificationConflict>(`project/${projectId}/material-modification`, postData)
}

const editModification = (input: IMaterialModificationConflict, projectId: number) => {
    return axios.patch<void, IMaterialModificationConflict>(
        `project/${projectId}/material-modification/${input.id}`,
        input
    )
}

const updateOmniRuleSystemState = (updateRuleEngineStateDtoArray: UpdateRuleEngineStateDto[], projectId: number) => {
    return axios.post<RuleEngineStateDto, RuleEngineStateDto>(
        `project/${projectId}/get-updated-omni-rule-system`,
        updateRuleEngineStateDtoArray
    )
}

const requestNextBake = (rulesEngineStateDto: RuleEngineStateDto | null, projectId: number) => {
    if (!rulesEngineStateDto) {
        return
    }

    const extractValues = (variables, code) => {
        const values: string[] = []

        for (const variable of variables) {
            if (variable.Code === code) {
                for (const property of variable.Properties) {
                    for (const option of property.Value) {
                        for (const innerVariable of option.Variables) {
                            for (const innerProperty of innerVariable.Properties) {
                                if (innerProperty.Code === 'value' && innerProperty.Type === 'String') {
                                    values.push(innerProperty.Value)
                                }
                            }
                        }
                    }
                }
            }
        }

        return values
    }

    const designIds: string[] = []
    let phaseId: string | null = null

    for (const instance of rulesEngineStateDto.ContainerInstances) {
        designIds.push(...extractValues(instance.Variables, 'DesignOptions'))

        if (!phaseId) {
            const phaseValues = extractValues(instance.Variables, 'Phases')

            if (phaseValues.length > 0) {
                phaseId = phaseValues[0]
            }
        }
    }

    const params = {
        designIds,
        phaseId,
        digProjectId: projectId.toString(),
    }

    return axios.post(`project/${projectId}/request-next-bake-from-omni`, params)
}

const deleteDocument = (documentMappingId: number) => {
    return axios.delete(`document/${documentMappingId}`)
}

export {
    addOpeningLocation,
    bulkCreateOpenings,
    copy3DProjectFromProductionToStaging,
    createFlag,
    createMasterSetPlans,
    createModification,
    createOpening,
    createOpeningGroupMaterial,
    createProject,
    createToolObject,
    deleteDocument,
    deleteMultipleFlags,
    deleteOpening,
    deleteOpeningGroupMaterial,
    deleteOpeningLocation,
    deleteOpenings,
    deleteProjectContextMarkups,
    deleteProjectDocumentChunks,
    duplicateDocumentChunk,
    duplicateDocumentChunkAndEstimations,
    editModification,
    fetchAllProjectDocumentMappings,
    fetchProjectBuildings,
    fetchProjectContextMarkups,
    fetchProjectDocumentChunks,
    fetchProjectDocumentMapping,
    fetchProjectFlags,
    fetchProjectFlagsOptions,
    fetchProjectModifications,
    finishProjectMapping,
    get3DDesignOptionRulesSystemAndUpdate,
    getAIsuggestions,
    getAvailableFloors,
    getProject,
    getProjectById,
    getProjectDeliverablesStatus,
    getProjectFromPortal,
    getProjectRevisionFiles,
    getProjects,
    getProjectSettings,
    getProjectsRevisions,
    importNewPdf,
    linkGeometryFromFlag,
    postRotatedImages,
    publishAdminOptions,
    recalculateMeasurementsByProjectId,
    reCreateMarkupOnlyProject,
    requestNextBake,
    reset3DProject,
    resetProject,
    restoreProjectDocumentChunks,
    saveProjectBuildingsSettings,
    triggerAI,
    unlinkGeometryFromFlag,
    updateDocumentChunkCalibrationFactor,
    updateFlag,
    updateFlagOpenings,
    updateOmniRuleSystemState,
    updateOpeningLocationCoordinates,
    updateProjectContextMarkups,
    updateProjectMappings,
    updateProjectPdfGenerationSettings,
    updateProjectScale,
    updateProjectSettings,
    updateProjectStatus,
}
