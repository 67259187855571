import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, takeEvery } from 'redux-saga/effects'

import { unlinkGeometryFromFlag } from '../../../api/projects-api'
import { OpeningLink } from '../../../models/flags'
import managers from '../../lib/managers'
import { Workspace } from '../../lib/toolBoxes/2D'
import { unlinkFlagFromMaterial } from '../../slices/materialFlags'

export const unlinkGeometryFromFlagAction = createAction<{
    flag_id: number
    openingLinksToUnlink: OpeningLink[]
}>('unlinkGeometryFromFlag')

export function* handleUnlinkGeometryFromFlag({ payload }: ReturnType<typeof unlinkGeometryFromFlagAction>) {
    try {
        const paperManager = yield call(managers.get2DManager)

        if (!paperManager) return

        const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

        const { flag_id, openingLinksToUnlink } = payload

        yield all(openingLinksToUnlink.map(({ id }) => call(unlinkGeometryFromFlag, id)))

        yield all(openingLinksToUnlink.map(({ id }) => put(unlinkFlagFromMaterial({ flag_id, opening_link_id: id }))))

        const temporaryFlagItems: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => flag_id === data?.flag_id && openingLinksToUnlink.some((ol) => ol.opening_id === data?.openingId)
        )

        yield all(temporaryFlagItems.map((item) => call(item.remove.bind(item))))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForUnlinkGeometryFromFlag(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(unlinkGeometryFromFlagAction.type, handleUnlinkGeometryFromFlag)
}
