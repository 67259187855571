import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    'ui:order': ['material', 'glulam_type'],
    material: {
        'ui:title': 'Ledger Material',
        'ui:placeholder': 'Select Ledger Material',
    },
    glulam_type: {
        'ui:placeholder': 'Select Glulam Type',
    },
    type: {
        'ui:title': 'Ledger Type',
        'ui:placeholder': 'Select Type',
    },
    plies: {
        'ui:title': 'Plies',
        'ui:placeholder': 'Select Plies',
    },
    is_treated: {
        'ui:title': 'Treated',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (newSettings.material !== 'DIMENSIONAL LUMBER') {
        newSettings.thickness = null
        newSettings.width = null
    }
    // NOT one of these 'GL', 'LSL', 'LVL', 'PSL'
    if (!['GL', 'LSL', 'LVL', 'PSL'].includes(newSettings.material)) {
        newSettings.ewp_width = null
        newSettings.height = null
        newSettings.width_other = null
        newSettings.height_other = null
    }

    if (newSettings.material !== 'GL') {
        newSettings.glulam_type = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
