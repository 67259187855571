import { fork, select, takeLatest } from 'redux-saga/effects'

import { SET_ACTIVE_DRAWABLE, setActiveDrawable } from '../../../actions/drawable'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleSetActiveDrawable2D } from '../2D/handleSetActiveDrawable'
import { handleSetActiveDrawable3D } from '../3D/handleSetActiveDrawable'

export function* handleSetActiveDrawable(action: ReturnType<typeof setActiveDrawable>) {
    const activeMode = yield select(selectActiveMode)

    switch (activeMode) {
        case VIEW_MODE.Markup2DFor3D:
        case VIEW_MODE.Markup2D:
            yield fork(handleSetActiveDrawable2D, action)
            break
        case VIEW_MODE.Markup3D:
            yield fork(handleSetActiveDrawable3D, action)
            break
        default:
            break
    }
}

export function* watchForSetActiveDrawable() {
    yield takeLatest(SET_ACTIVE_DRAWABLE, handleSetActiveDrawable)
}
