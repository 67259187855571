import isNull from 'lodash/isNull'
import { call, select, takeLatest } from 'redux-saga/effects'

import { NormalizedFlag } from '../../../models/flags'
import { ACTIVE_FLAG_OPACITY, INACTIVE_FLAG_OPACITY } from '../../../shared/constants/flags'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Flag } from '../../lib/toolBoxes/2D'
import { resetActiveFlag, selectMaterialActiveFlag, setActiveFlag } from '../../slices/materialFlags'

export function* handleHighlightActiveFlag() {
    try {
        const paperManager: PaperManager | null = yield call(managers.get2DManager)

        if (!paperManager) return

        const activeFlag: NormalizedFlag | null = yield select(selectMaterialActiveFlag)

        const flagTool: Flag = yield call(paperManager.getTool, Flag.NAME)

        const flagItems: paper.Group[] = yield call(flagTool.getCachedFlags)

        if (flagItems.length === 0) return

        for (const flagItem of flagItems) {
            const isActive = flagItem?.data?.flag_id === activeFlag?.id

            flagItem.opacity = isActive || isNull(activeFlag) ? ACTIVE_FLAG_OPACITY : INACTIVE_FLAG_OPACITY
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForHighlightActiveFlag() {
    yield takeLatest([setActiveFlag, resetActiveFlag], handleHighlightActiveFlag)
}
