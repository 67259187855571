import { createAction } from '@reduxjs/toolkit'
import { call, fork, select, StrictEffect, takeEvery } from 'redux-saga/effects'

import { DocumentMapping } from '../../../models/documentMapping'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleUpdateSingleDocumentMapping2D } from '../2D/updateSingleDocumentMapping'

export const updateSingleDocumentMapping = createAction<DocumentMapping>('updateSingleDocumentMapping')

export function* handleUpdateSingleDocumentMapping(
    action: ReturnType<typeof updateSingleDocumentMapping>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2DFor3D:
            case VIEW_MODE.Markup2D:
            case VIEW_MODE.Markup3D:
            default:
                yield fork(handleUpdateSingleDocumentMapping2D, action)
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForUpdateSingleDocumentMapping(): Generator<StrictEffect, void, unknown> {
    yield takeEvery(updateSingleDocumentMapping.type, handleUpdateSingleDocumentMapping)
}
