import { createSelector } from '@reduxjs/toolkit'
import { all, call, put, select, StrictEffect } from 'redux-saga/effects'

import { selectProject } from './createDrawableLocation'
import { createHighlightFromLocation } from './markupDocument'
import {
    ADD_DRAWABLE_TO_ALL_GROUPS,
    setActiveDrawableGroup,
    UPDATE_OPENING_GROUPS_SUCCESS,
} from '../../../actions/drawable'
import { deleteOpening, deleteOpenings } from '../../../api/projects-api'
import { updateAutomatedObject } from '../../../api/takeoff-api'
import { ActiveFloor } from '../../../models/activeFloor'
import { AIAutomatedObject } from '../../../models/aiClassifications'
import { Project } from '../../../models/project'
import { RootState } from '../../../stores'
import { markAiSuggestionsDeleted } from '../../../utils/aiSuggestions/aiParser'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Flag, Select, Workspace } from '../../lib/toolBoxes/2D'
import { Highlight } from '../../lib/toolBoxes/2D/tools/highlight/Highlight.tool'
import {
    deleteOpeningLocation,
    selectAiSuggestions,
    setActiveHighlightId,
    setAiSuggestions,
    setDrawableLocations,
} from '../../slices/2D'
import {
    ActiveGeometryGroup,
    GeometricDrawable,
    GeometryGroup,
    selectActiveGeometryGroup,
    selectDrawableGeometries,
    selectDrawableGroupsGeometries,
} from '../../slices/geometry'
import { IMUP2DDrawableLocation } from '../../types'

export const selectBaseDrawableState = createSelector(
    (state: RootState) => {
        return {
            drawables: selectDrawableGeometries(state),
            drawableLocations: state.IMUP['2D'].drawableLocations,
        }
    },
    (state) => state
)

// TODO: in future, add possibility to send multiple drawable_ids
export function* deleteHighlightLocation(
    highlightTool: Highlight,
    colorTool: Color,
    selectTool: Select,
    currentDrawableLocations: IMUP2DDrawableLocation[],
    project: Project,
    activeFloor: ActiveFloor,
    item: paper.Item
): Generator<StrictEffect | IMUP2DDrawableLocation | undefined, void, string & (IMUP2DDrawableLocation | undefined)> {
    const highlightDrawableLocation: IMUP2DDrawableLocation | undefined = yield currentDrawableLocations.find(
        (location) => location.drawable_id === item.data.drawable_id
    )

    if (!highlightDrawableLocation) return

    // remove active highlight id to toggle clean up of selectedItem in Select.tool.ts
    yield put(setActiveHighlightId(null))

    yield put(deleteOpeningLocation(highlightDrawableLocation.opening_location_id))

    yield all([call(item.remove.bind(item)), call(selectTool.removeHandles)])

    // try the API call. if there's an error -> undo the deletion actions to reset the drawable & paper state
    try {
        const success: string = yield call(deleteOpening, project.id, activeFloor.hash, item.data.drawable_id)

        if (success !== 'True') {
            throw new Error('Deletion was unsuccessful')
        }
    } catch (e) {
        yield call(console.error, `Error on API deletion: ${e}`)

        yield call(createHighlightFromLocation, highlightTool, colorTool, highlightDrawableLocation)

        // Reset the drawable locations in the store to
        // add back the deleted highlight
        yield put(setDrawableLocations(currentDrawableLocations))
    }
}

export function* deleteAiSuggestion(aiSuggestionId: string[]) {
    try {
        const aiDataArray: AIAutomatedObject[] = yield select(selectAiSuggestions)
        const updatedAiData: AIAutomatedObject[] = yield markAiSuggestionsDeleted(aiDataArray, aiSuggestionId)

        // TODO: improve to allow array of suggestion ids
        // Update Ai Api call
        yield all(aiSuggestionId.map((id) => call(updateAutomatedObject, id, true, false)))

        // Update Ai in the store
        yield put(setAiSuggestions(updatedAiData))
    } catch (e) {
        console.error('Error on deleting updating AI', e)
    }
}

/**
 * Deletes the active drawable
 *  - Takes an optimistic approach to deletion: removes the paper item -> removes it from the store -> sends api call -> on failure resets the store to pre-delete state
 */
export function* deleteDrawableLocation2D({
    payload,
}: {
    payload: number[]
}): Generator<StrictEffect, void, PaperManager & Highlight & Workspace & ActiveGeometryGroup & ActiveFloor & Project> {
    try {
        const openingIdsSet = new Set(payload)
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const [workspaceTool, selectTool]: [Workspace, Select] = yield call(manager.getTools, [
            Workspace.NAME,
            Select.NAME,
        ])

        const project: Project = yield select(selectProject)

        const {
            drawableLocations,
            drawables,
        }: {
            drawableLocations: IMUP2DDrawableLocation[]
            drawables: GeometricDrawable[]
        } = yield select(selectBaseDrawableState)

        const activeDrawables = drawables.filter((dr) => openingIdsSet.has(dr.id))

        // get flags by opening id
        const flagItemsByOpeningId: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => openingIdsSet.has(data?.openingId) && data.toolName === Flag.NAME
        )

        // remove flags
        yield all(flagItemsByOpeningId.map((item) => call(item.remove.bind(item))))

        const currentDrawables = drawables.filter((d) => openingIdsSet.has(d.id))

        // exit if there is not a current drawables
        if (!currentDrawables.length) {
            return
        }

        let activeDrawableGroup: ActiveGeometryGroup = yield select(selectActiveGeometryGroup)

        // if there is no active drawable group, or an active drawable group is not the same as opening which
        // we want to delete, get drawableGroup from all drawable groups,
        // we can use [0] here, as we don't have activeDrawableGroup only when we delete single opening
        if (!activeDrawableGroup || activeDrawableGroup.id !== currentDrawables[0].opening_group_id) {
            const drawableGroups: GeometryGroup[] = yield select(selectDrawableGroupsGeometries)
            // drawableGroups don't have drawables

            activeDrawableGroup = drawableGroups.find(
                (drawableGroup) => drawableGroup.id === currentDrawables[0].opening_group_id
            ) as unknown as ActiveGeometryGroup

            if (!activeDrawableGroup?.drawables?.length) {
                activeDrawableGroup = {
                    ...activeDrawableGroup,
                    drawables: activeDrawableGroup.openings,
                }
            }
        }

        const newGroup: ActiveGeometryGroup = {
            ...activeDrawableGroup,
            openings: activeDrawableGroup.openings.filter((opening) => !openingIdsSet.has(opening.id)),
            drawables: activeDrawableGroup.drawables.filter((drawable) => !openingIdsSet.has(drawable.id)),
        }

        const itemsToDelete: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
            openingIdsSet.has(data.drawable_id)
        )

        yield all([
            put({
                type: UPDATE_OPENING_GROUPS_SUCCESS,
                payload: { openingGroups: { newGroup: newGroup, originalGroup: activeDrawableGroup } },
            }),
            ...itemsToDelete.map((i) => call(i.remove.bind(i))),
            call(selectTool.removeHandles),
        ])

        if (newGroup.openings.length === 0) {
            yield put(setActiveDrawableGroup(null))
        }

        // try the API call. if there's an error -> undo the deletion actions to reset the drawable & paper state
        try {
            const success = yield call(deleteOpenings, project.id, payload)

            if (!success) {
                throw new Error('Deletion was unsuccessful')
            }
        } catch (e) {
            yield call(console.error, `Error on API deletion: ${e}`)

            // redraw the item on the blueprint
            yield all(itemsToDelete.map((i) => call(workspaceTool.addPaperItem, i)))

            yield all([
                put({
                    type: UPDATE_OPENING_GROUPS_SUCCESS,
                    payload: { openingGroups: { newGroup: activeDrawableGroup, originalGroup: activeDrawableGroup } },
                }),
                ...currentDrawables.map((c) =>
                    put({
                        type: ADD_DRAWABLE_TO_ALL_GROUPS,
                        payload: c,
                    })
                ),
                put({
                    type: setDrawableLocations.type,
                    payload: drawableLocations,
                }),
            ])
        }

        // Delete ai suggestion
        if (activeDrawables.length) {
            for (const activeDrawable of activeDrawables) {
                if (activeDrawable?.ai_suggestion_id) {
                    yield call(deleteAiSuggestion, [activeDrawable.ai_suggestion_id])
                }
            }
        }
    } catch (error) {
        yield call(console.error, error as any)
    }
}
