import { distinctUntilChanged, map, takeWhile } from 'rxjs'

import { PolygonTool } from '../polygon/Polygon.tool'
import { REGION_COLOR } from '../../../../../../shared/constants/colors'
import { convertScaleEnumToString } from '../../../../../../utils/calculations/scaleConversion/scaleConversion'
import { initialToolsState, ToolsState } from '../../../../../slices/tools'
import {
    Coordinates2D,
    Cursors,
    IMUP2DDrawableLocation,
    IMUPState,
    ItemScale,
    PaperToolConfig,
    REGION_ENUMS,
    VIEW_MODE,
} from '../../../../../types'
import addSelectFunctionalityToRegion from '../../../../utils/functionality-bindings/addSelectFunctionalityToRegion'

/**
 * Region.tool.tsx
 * Creates a corner to corner region upon mouse drag
 */
export class RegionTool extends PolygonTool {
    static NAME = 'REGION'
    static CURSOR = Cursors.CROSSHAIR

    private labelTextOffsetPoint: paper.Point

    private dashArray: ToolsState['dashArray'] = initialToolsState.dashArray
    private opacity: number = initialToolsState.areaOpacityValue
    private color: string = initialToolsState.color
    private currentPaperId: number | null = null
    protected startingPoint: paper.Point | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = RegionTool.NAME
        this.labelTextOffsetPoint = new this.paper.Point(-100, -80)

        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                map(({ tools: { color, areaOpacityValue, dashArray, areaStrokeWidth } }: IMUPState) => ({
                    areaOpacityValue,
                    color,
                    dashArray,
                    areaStrokeWidth,
                })),
                distinctUntilChanged()
            )
            .subscribe(({ areaOpacityValue, color, dashArray, areaStrokeWidth }) => {
                this.opacity = areaOpacityValue
                this.color = color
                this.dashArray = dashArray
                this.strokeWidth = areaStrokeWidth
            })
    }

    /**
     * Abstracted function to build the styles of the regions.
     */
    buildRegionProperties = (
        path: paper.Path,
        color: paper.Color,
        region_id: number,
        scaleText = '',
        calibrationApplied?: Boolean
    ): paper.Path => {
        path.strokeColor = this.dashArray.length ? new this.paper.Color(REGION_COLOR) : color
        path.opacity = this.dashArray.length ? 1 : this.opacity

        path.strokeWidth = this.strokeWidth || initialToolsState.strokeWidth // if the stroke is not set, then set it to a default value
        path.strokeWidth *= ItemScale.LINE
        path.fillColor = null
        path.data.selectable = true

        // these are multiplied to scale the dashes based on the stroke width (the default numbers in the store are based on 1px width)
        path.dashArray = [this.dashArray[0] * path.strokeWidth, this.dashArray[1] * path.strokeWidth]

        const scaleLabel = new this.paper.PointText(path.bounds.bottomRight.add(this.labelTextOffsetPoint))

        scaleLabel.fillColor = new this.paper.Color(REGION_COLOR)
        scaleLabel.justification = 'right'
        scaleLabel.fontSize = `${Math.round(path.strokeWidth) * 10}px`
        scaleLabel.content = calibrationApplied ? '*' : convertScaleEnumToString(scaleText)

        path.data.scale = scaleText
        path.data.shapeType = REGION_ENUMS.TYPE
        path.data.region_id = region_id
        this.currentPaperId = path.id

        path.data.labelId = scaleLabel.id

        return path // The group will contain the path at index 0 and the label at index 1
    }

    renderRegion = (
        color: paper.Color,
        coords: Coordinates2D,
        region_id: number,
        scaleText: string,
        calibrationApplied?: boolean
    ): paper.Path => {
        const path = this.createPolygon(coords)

        return this.buildRegionProperties(path, color, region_id, scaleText, calibrationApplied)
    }

    drawRegion = (color: paper.Color, from: paper.Point, to: paper.Point): paper.Path => {
        const path = new this.paper.Path.Rectangle(from, to)

        addSelectFunctionalityToRegion(path)

        return this.buildRegionProperties(path, color, 0)
    }

    onMouseMove = (event: paper.MouseEvent): void => {
        if (this.startingPoint instanceof this.paper.Point) {
            // remove the previous group that is no longer relevant
            this.paper.project.activeLayer.children[this.paper.project.activeLayer.children.length - 2].remove()
            this.paper.project.activeLayer.children[this.paper.project.activeLayer.children.length - 1].remove()

            this.drawRegion(new this.paper.Color(this.color), this.startingPoint, event.point)
        }
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        if (this.startingPoint) {
            this.startingPoint = null
            if (this.currentPaperId) this.setState('2D', { drawablesToCreate: [this.currentPaperId] })
            this.currentPaperId = null
            // The shape has been created at this point, tool state has been reset
        } else {
            this.startingPoint = event.point

            this.setScaleFromPointClick(event.point)

            // Begin to draw the shape
            this.drawRegion(new this.paper.Color(this.color), this.startingPoint, this.startingPoint)
        }
    }

    /**
     * Determines if a given paper Item is inside a given region.
     * An item is considered "inside" a region if and only if all points along the path are inside a region.
     * @param item The item that we are looking to see lies within the region
     * @param regionPath The region that we are testing
     * @returns A boolean that is true if and only if all points of item lie within regionPath
     */
    public isItemInsideRegion = (item: paper.Item, regionPath: paper.Path): boolean => {
        // The region will "contain" an item if and only if all points lie within it
        // In this case, this array will have only the value true in it (once for each point along the item path)
        let regionContainsPoints: boolean[] = []

        if (item instanceof this.paper.CompoundPath) {
            regionContainsPoints = (item as paper.CompoundPath).children.flatMap((i) =>
                (i as paper.Path).segments.map((segment) => regionPath.contains(segment.point))
            )
        } else if (item instanceof this.paper.Path) {
            regionContainsPoints = (item as paper.Path).segments.map((segment) => regionPath.contains(segment.point))
        } else if (item instanceof this.paper.Point) {
            return regionPath.contains(item as paper.Point)
        } else {
            return false
        }

        // If the array contains false, at least one point is outside the region -> return false
        return !regionContainsPoints.includes(false)
    }

    /**
     *  Determines if a given location is inside a given region
     * An item is considered "inside" a region if and only if all points along the path are inside a region.
     * @param location The IMUP2DDrawableLocation that we are looking to see lies within the region
     * @param regionPath The region that we are testing
     * @returns A boolean that is true if and only if all points of location lie within regionPath
     */
    public isLocationInsideRegion = (location: IMUP2DDrawableLocation, regionPath: paper.Path): boolean => {
        // The region will "contain" a location if and only if all points lie within it
        const locationPoints = location.coordinates.map((c) => new this.paper.Point(c[0], c[1]))

        return !locationPoints.map((p) => regionPath.contains(p)).includes(false)
    }

    /**
     * Use the fixed position of the label text and the region item
     * to reposition the scale text at the bottom right corner of the region
     * @param scaleText the scaleText item
     * @param regionPath the region that the scale item is associated
     */
    public updateScaleTextPositionRelativeToRegionPath = (scaleText: paper.TextItem, regionPath: paper.Path) => {
        scaleText.position = regionPath.bounds.bottomRight.add(this.labelTextOffsetPoint)
    }
}

export default RegionTool
