import { FormValidation, UiSchema } from '@rjsf/core'

import { calculateLFMeasurementOfMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { pitchNumberValidation } from '../shared/services/field-validation/field-validation-service'

const pitchOtherValue = 'other'

export const hipRooflineType = 'Hip'
export const valleyRoofLineType = 'Valley'
const ridgeLineType = 'Ridge'

const uiSchema: UiSchema = {
    'ui:order': ['type', 'selection', 'pitch1', 'pitch1_other', 'pitch2', 'pitch2_other'],
    type: {
        'ui:placeholder': 'Select Type',
    },
    selection: {
        'ui:placeholder': 'Select Roofing',
    },
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (newSettings.pitch1 === pitchOtherValue && newSettings.pitch1_other) {
        newSettings.pitch1 = String(newSettings.pitch1_other).toUpperCase()

        delete newSettings.pitch1_other
    } else if (newSettings.pitch1 !== pitchOtherValue) {
        newSettings.pitch1_other = null
    }

    if (newSettings.pitch2 === pitchOtherValue && newSettings.pitch2_other) {
        newSettings.pitch2 = String(newSettings.pitch2_other).toUpperCase()

        delete newSettings.pitch2_other
    } else if (newSettings.pitch2 !== pitchOtherValue) {
        newSettings.pitch2_other = null
    }

    if (formData.type === ridgeLineType) {
        newSettings.pitch1 = null
        newSettings.pitch2 = null
    }

    return newSettings
}

const onValidate = (formData: any, errors: FormValidation) => {
    const pitchFields = ['pitch1', 'pitch2']

    pitchFields.forEach((pitchName) => {
        if (formData[pitchName] === pitchOtherValue) {
            const pitchOtherFieldName = `${pitchName}_other`

            if (!pitchNumberValidation(formData[pitchOtherFieldName])) {
                errors[pitchOtherFieldName].addError(FORM_ERROR_MESSAGES.PITCH_NUMBER)
            }
        }
    })
}

/**
 * Augment the drawables of the from based on the new settings
 * @param drawables the drawables in the group
 * @param newSettings the new group settings
 * @param scaleFactor the scaleFactor of the page
 * @returns
 */
function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
}): ActiveDrawable[] {
    const { drawables, newSettings } = input

    return drawables.map((drawable) => {
        drawable.settings.quantity = calculateLFMeasurementOfMaterial(drawable.settings.linear_total, {
            type: DRAWABLE_TYPES.ROOF_LINE,
            settings: newSettings,
        })

        return drawable
    })
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form
