import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../../imup/types'

export const FloorTrussType = 'Floor Truss'
export const uiSchema: UiSchema = {}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return {
        ...settings,
        ...formData,
    }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
