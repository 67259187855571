import { createAction } from '@reduxjs/toolkit'
import { call, fork, select, StrictEffect, takeLatest } from 'redux-saga/effects'

import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleToggleOpeningLocationOnHover2D } from '../2D/animateOpeningLocationOnHover'

export const toggleOpeningLocationOnHoverAction = createAction<{ opening_location_id: number; exiting?: boolean }>(
    'toggleOpeningLocationOnHover'
)

export function* handleToggleOpeningLocationOnHover(
    action: ReturnType<typeof toggleOpeningLocationOnHoverAction>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2DFor3D:
            case VIEW_MODE.Markup2D:
                yield fork(handleToggleOpeningLocationOnHover2D, action)
                break
            case VIEW_MODE.Markup3D:
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForToggleOpeningLocationOnHover() {
    yield takeLatest(toggleOpeningLocationOnHoverAction.type, handleToggleOpeningLocationOnHover)
}
