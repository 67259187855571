import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

const stoneMaterialName = 'STONE'
const lpMaterialName = 'LP'
const fiberCement = 'FIBER CEMENT'
const locationOutside = 'OUTSIDE'

export const uiSchema: UiSchema = {
    'ui:order': [
        'selection',
        'fc_texture',
        'location',
        'height',
        'width',
        'thickness',
        'floor_level',
        'second_piece_width',
    ],
    height: {
        'ui:title': 'Height',
    },
    lp_one_piece: {
        'ui:widget': 'checkbox',
    },
    board_length: {
        'ui:placeholder': 'Select Length',
    },
    fc_texture: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    floor_level: {
        'ui:placeholder': 'Select Floor Level',
    },
    location: {
        'ui:placeholder': 'Select Corner Location',
    },
    thickness: {
        'ui:placeholder': 'None',
    },
    width: {
        'ui:placeholder': 'None',
    },
    selection: {
        'ui:placeholder': 'Select selection',
    },
    second_piece_width: {
        'ui:placeholder': 'Select Second Piece Width',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }
    const isStone = formData.selection === stoneMaterialName
    const isLP = formData.selection === lpMaterialName
    const isFiberCement = formData.selection === fiberCement
    const isLocationOutside = formData.location === locationOutside

    if (!isFiberCement) {
        newSettings.fc_texture = null
    }

    if (!(isLP && isLocationOutside)) {
        newSettings.lp_one_piece = false
    }

    if (!isLP && !isLocationOutside && settings.lp_one_piece) {
        newSettings.board_length = null
    }

    if (!isStone) {
        newSettings.height = null
    }

    if (isStone) {
        newSettings.width = null
        newSettings.thickness = null
        newSettings.second_piece_width = null
    }

    if (settings.board_length && !formData.board_length) {
        newSettings.board_length = null
    }

    if (settings.second_piece_width && !formData.second_piece_width) {
        newSettings.second_piece_width = null
    }

    if (settings.thickness && !formData.thickness) {
        newSettings.thickness = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
