import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const selectionFiberCement = 'FIBER CEMENT'
export const selectionCemtrim = 'CEMTRIM'
export const selectionWoodtone = 'WOODTONE'

const selectionHorizontalRailLF = 'L/F HORIZONTAL RAIL'
const selectionStoneLedgeLF = 'L/F STONE LEDGE'
const selectionVerticalRailLF = 'L/F VERTICAL RAIL'

const locationCap = 'CAP'
const locationCrownMold = 'CROWN MOLD'

const selectionSteel = 'STEEL'
const selectionStucco = 'STUCCO'
const locationBottomWindow = 'BOTTOM WINDOW'

export const woodtoneThickness2X = '2X'
export const woodtoneThickness4X = '4/4X'
export const woodtoneThickness5X = '5/4X'

export const uiSchema: UiSchema = {
    label: {
        'ui:title': 'Label',
    },
    location: {
        'ui:placeholder': 'Select Trim Location',
    },
    thickness: {
        'ui:placeholder': 'None',
    },
    width: {
        'ui:placeholder': 'Select Width',
    },
    fiber_cement_width: {
        'ui:placeholder': 'Select Width',
    },
    cemtrim_width: {
        'ui:placeholder': 'Select Width',
    },
    width_four_feet_four_inches: {
        'ui:title': 'Woodtone Width',
        'ui:placeholder': 'Select Woodtone Width',
    },
    width_two_feet: {
        'ui:title': 'Woodtone Width',
        'ui:placeholder': 'Select Woodtone Width',
    },
    width_five_feet_four_inches: {
        'ui:title': 'Woodtone Width',
        'ui:placeholder': 'Select Woodtone Width',
    },
    texture: {
        'ui:placeholder': 'Not Specified',
    },
    selection: {
        'ui:placeholder': 'Select Selection',
    },
    include_sill: {
        'ui:title': 'Interior Window Sill',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    include_apron: {
        'ui:title': 'Interior Window Apron',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    'ui:order': [
        'location',
        'include_sill',
        'include_apron',
        'selection',
        'label',
        'thickness',
        'width',
        'fiber_cement_width',
        'cemtrim_width',
        'width_four_feet_four_inches',
        'width_two_feet',
        'width_five_feet_four_inches',
        'texture',
    ],
}

const onValidate = () => {}

// removing all fields in next cases:
// when location is CAP or CROWN MOLD
// when selection is L/F HORIZONTAL RAIL or L/F STONE LEDGE or L/F VERTICAL RAIL
const onSubmitBaseCleanUp = (settings) => {
    const preparedSettings = { ...settings }

    // clean base conditional fields
    preparedSettings.thickness = null
    preparedSettings.width = null

    // used only in woodtone
    preparedSettings.texture = null

    // remove fiber cement width field
    delete preparedSettings.fiber_cement_width

    // remove cemtrim width field
    delete preparedSettings.cemtrim_width

    // remove woodtone width fields
    delete preparedSettings.width_five_feet_four_inches
    delete preparedSettings.width_four_feet_four_inches
    delete preparedSettings.width_two_feet

    return preparedSettings
}

const onSubmit = (settings, formData) => {
    let newSettings = { ...settings, ...formData }

    if (newSettings.location !== locationBottomWindow) {
        newSettings.include_sill = null
        newSettings.include_apron = null
    }

    if ([selectionSteel, selectionStucco].includes(formData.selection)) {
        newSettings.thickness = null
        newSettings.width = null
    }

    if ([locationCap, locationCrownMold].includes(newSettings.location)) {
        newSettings = onSubmitBaseCleanUp(newSettings)
        newSettings.selection = null
    }

    if ([selectionHorizontalRailLF, selectionStoneLedgeLF, selectionVerticalRailLF].includes(newSettings.selection)) {
        newSettings = onSubmitBaseCleanUp(newSettings)
        newSettings.location = null
    }

    // re-assign fiber cement width
    if (newSettings.selection === selectionFiberCement) {
        if (newSettings?.fiber_cement_width) {
            newSettings.width = newSettings.fiber_cement_width
        }
    }

    delete newSettings?.fiber_cement_width

    // re-assign cemtrim width
    if (newSettings.selection === selectionCemtrim) {
        if (newSettings?.cemtrim_width) {
            newSettings.width = newSettings.cemtrim_width
        }
    }

    delete newSettings?.cemtrim_width

    // re-assign woodtone width
    if (newSettings.selection === selectionWoodtone) {
        if (newSettings?.width_five_feet_four_inches) {
            newSettings.width = newSettings.width_five_feet_four_inches
        }

        if (newSettings?.width_two_feet) {
            newSettings.width = newSettings.width_two_feet
        }

        if (newSettings?.width_four_feet_four_inches) {
            newSettings.width = newSettings.width_four_feet_four_inches
        }
    } else {
        // texture exist only in woodtone
        newSettings.texture = null
    }

    // remove woodtone width
    delete newSettings?.width_five_feet_four_inches
    delete newSettings?.width_two_feet
    delete newSettings?.width_four_feet_four_inches

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
