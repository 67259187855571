import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    'ui:order': ['item_number', 'sheathing'],
    item_number: {
        'ui:placeholder': 'Select Decking Material',
    },
    sheathing: {
        'ui:placeholder': 'Select Decking Sheathing Type',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return { ...settings, ...formData }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
