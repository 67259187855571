import Base from '../paperTool/PaperTool'
import { PaperToolConfig, Vector2D } from '../../../../../types'

export class Overlay extends Base {
    public static NAME = 'Overlay'
    public static DEFAULT_OPACITY = 0.5

    private static RASTER_CROSSHAIR_GROUP_NAME = 'raster-crosshair'
    private static RASTER_PAPER_ITEM_NAME = 'Raster'
    private static BOUND_NAMES = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight']
    private static BOUND_NAME_OPPOSITE_CORNER: Record<string, string> = {
        topLeft: 'bottomRight',
        topRight: 'bottomLeft',
        bottomLeft: 'topRight',
        bottomRight: 'topLeft',
    }
    private static CROSS_HAIR_FILL_COLOR = 'white'
    private static CROSS_HAIR_STROKE_COLOR = '#0086C9'

    private paperRastersDict: Record<number, paper.Raster> = {}
    private selectedImage: paper.Raster | null = null
    private selectedImageBackground: paper.Item | null = null
    private selectedImageCrossHairs: paper.Item | null = null
    private selectedImageCrossHairParent: paper.Group | null = null
    private originalImageSizeDictionary: Record<number, paper.Size> = {}
    private rasterBackgroundDict: Record<number, paper.Path.Rectangle> = {}

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Overlay.NAME
    }

    private updateCrosshairPositions = (imageRaster: paper.Raster) => {
        const { bounds } = imageRaster

        this.selectedImageCrossHairParent?.children.forEach((corner) => {
            corner.position = bounds[corner.data.boundName]
        })
    }

    private placeImageRasterInTopRightCorner = (imageRaster: paper.Raster) => {
        const viewTopRight = this.paper.view.bounds.topRight

        imageRaster.position = new this.paper.Point(
            viewTopRight.x - imageRaster.width / 2,
            viewTopRight.y + imageRaster.height / 2
        )
    }

    public insertOverlay = async (src: string, id: number, opacity: number, overlayColor: string) => {
        if (this.paperRastersDict[id]) return

        const imageRaster = await new Promise<paper.Raster>((resolve) => {
            const raster = new this.paper.Raster({
                source: src,
                blendMode: 'multiply',
            })

            raster.name = Overlay.RASTER_PAPER_ITEM_NAME
            raster.data.id = id
            raster.opacity = opacity
            raster.selectedColor = new this.paper.Color(Overlay.CROSS_HAIR_STROKE_COLOR)
            raster.onLoad = (): void => {
                resolve(raster)
            }
        })

        this.placeImageRasterInTopRightCorner(imageRaster)

        // The rasterBackground sits just behind the overlay raster image and is responsible for tinting the image blue.
        const rasterBackground = new this.paper.Path.Rectangle({
            rectangle: imageRaster.bounds,
            fillColor: new this.paper.Color(overlayColor),
            position: imageRaster.position,
            opacity: imageRaster.opacity,
        })

        this.paper.project.activeLayer.insertChild(1, imageRaster)
        rasterBackground.insertBelow(imageRaster)

        this.originalImageSizeDictionary[id] = imageRaster.size
        this.paperRastersDict[id] = imageRaster
        this.rasterBackgroundDict[id] = rasterBackground
    }

    public rotateImageRaster = (id: number, angleChange: number) => {
        const imageRaster = this.paperRastersDict[id]
        const rasterBackground = this.rasterBackgroundDict[id]

        if (!imageRaster || !rasterBackground) return
        imageRaster.rotate(angleChange)
        rasterBackground.rotate(angleChange)
        this.updateCrosshairPositions(imageRaster)
    }

    private removeOverlay = (id: number) => {
        const imageRaster = this.paperRastersDict[id]
        const rasterBackground = this.rasterBackgroundDict[id]

        if (!imageRaster || !rasterBackground) return
        imageRaster.remove()
        rasterBackground.remove()
        delete this.paperRastersDict[id]
        delete this.rasterBackgroundDict[id]
    }

    public resetOverlayPosition = (id: number) => {
        const imageRaster = this.paperRastersDict[id]
        const rasterBackground = this.rasterBackgroundDict[id]

        if (!imageRaster || !rasterBackground) return
        this.placeImageRasterInTopRightCorner(imageRaster)
        rasterBackground.position = imageRaster.position
        this.updateCrosshairPositions(imageRaster)
    }

    public cancel = () => {
        Object.entries(this.paperRastersDict).forEach(([_, raster]) => {
            raster.selected = false
            raster.locked = true
        })

        this.selectedImageCrossHairParent?.removeChildren()
        this.selectedImageCrossHairParent?.remove()
        this.selectedImageCrossHairParent = null
        this.selectedImageCrossHairs = null
        this.selectedImage = null
    }

    onActivate = () => {
        Object.entries(this.paperRastersDict).forEach(([_, raster]) => {
            raster.locked = false
        })
    }

    public resetOverlayRasters = () => {
        for (const id in this.paperRastersDict) {
            if (this.paperRastersDict.hasOwnProperty(id)) {
                this.removeOverlay(parseInt(id))
            }
        }
    }

    public changeOverlayOpacity = (id: number, newOpacity: number) => {
        if (!isFinite(newOpacity) || newOpacity < 0 || newOpacity > 1) return
        const imageRaster = this.paperRastersDict[id]
        const rasterBackground = this.rasterBackgroundDict[id]

        if (!imageRaster || !rasterBackground) return
        imageRaster.opacity = newOpacity
        rasterBackground.opacity = newOpacity
    }

    public changeOverlayColor = (id: number, newColor: string) => {
        const imageRaster = this.paperRastersDict[id]
        const rasterBackground = this.rasterBackgroundDict[id]

        if (!imageRaster || !rasterBackground) return
        rasterBackground.fillColor = new this.paper.Color(newColor)
    }

    private limitHitResultToRasterImages = (hitResult: paper.HitResult) => {
        return (
            (this.selectedImage === null && hitResult.item.name === Overlay.RASTER_PAPER_ITEM_NAME) ||
            (this.selectedImage !== null && hitResult.item.name === Overlay.RASTER_CROSSHAIR_GROUP_NAME)
        )
    }

    public resetSelectedImagesAndCrossHairs = () => {
        if (this.selectedImage) this.selectedImage.selected = false
        this.selectedImage = null
        this.selectedImageBackground = null
        if (this.selectedImageCrossHairs) {
            this.selectedImageCrossHairs?.parent?.removeChildren()
            this.selectedImageCrossHairs?.parent?.remove()
            this.selectedImageCrossHairs = null
            this.selectedImageCrossHairParent = null
        } else {
            this.selectedImageCrossHairParent?.removeChildren()
            this.selectedImageCrossHairParent?.remove()
            this.selectedImageCrossHairParent = null
        }
    }

    private createSelectionCrossHairsForImage = () => {
        if (!this.selectedImage) return

        const bounds = this.selectedImage.bounds

        const boundingPointsParentGroup = new this.paper.Group()

        const boundItems = Overlay.BOUND_NAMES.map((boundName) => {
            const boundPoint = bounds[boundName] as paper.Point
            const crossHairCircle = new this.paper.Path.Circle(boundPoint, this.calculateHandleRadiusAtCurrentZoom())

            crossHairCircle.fillColor = new this.paper.Color(Overlay.CROSS_HAIR_FILL_COLOR)
            crossHairCircle.strokeColor = new this.paper.Color(Overlay.CROSS_HAIR_STROKE_COLOR)
            crossHairCircle.data = {
                boundName,
            }
            crossHairCircle.name = Overlay.RASTER_CROSSHAIR_GROUP_NAME

            return crossHairCircle
        })

        boundingPointsParentGroup.addChildren(boundItems)
        this.selectedImageCrossHairParent = boundingPointsParentGroup
    }

    public onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        const hitResult = this.getPaperScope().project.hitTest(event.point)

        if (hitResult === null || (hitResult !== null && !this.limitHitResultToRasterImages(hitResult))) {
            this.resetSelectedImagesAndCrossHairs()

            return
        }

        const { item } = hitResult

        if (item.name === Overlay.RASTER_PAPER_ITEM_NAME) {
            this.selectedImage = item as paper.Raster
            this.selectedImage.selected = true
            this.selectedImageBackground = this.rasterBackgroundDict[item.data.id]
            this.createSelectionCrossHairsForImage()
        } else if (item.name === Overlay.RASTER_CROSSHAIR_GROUP_NAME) {
            this.selectedImageCrossHairs = item
        }
    }

    public onMouseDrag = (event: paper.ToolEvent): void => {
        if (this.toolPanning(event)) return

        const { delta } = event

        if (this.selectedImageCrossHairs && this.selectedImage && this.selectedImageBackground) {
            const oppositePoint = this.selectedImageCrossHairs?.parent.children.find(
                (item) =>
                    item.data.boundName ===
                    Overlay.BOUND_NAME_OPPOSITE_CORNER[this.selectedImageCrossHairs?.data.boundName]
            )

            if (!oppositePoint) return

            const newCorner = this.selectedImageCrossHairs.position.add(delta)
            const originalSize = this.selectedImageCrossHairs.position.subtract(oppositePoint.position)
            const aspectRatio = originalSize.x / originalSize.y
            const newSize = newCorner.subtract(oppositePoint.position)

            this.selectedImage.scale(
                newSize.x / originalSize.x,
                newSize.x / aspectRatio / originalSize.y,
                oppositePoint.position
            )
            this.selectedImageBackground.scale(
                newSize.x / originalSize.x,
                newSize.x / aspectRatio / originalSize.y,
                oppositePoint.position
            )
            this.updateCrosshairPositions(this.selectedImage)
        } else if (this.selectedImage && this.selectedImageBackground) {
            this.selectedImage.position = this.selectedImage.position.add(delta)
            this.selectedImageBackground.position = this.selectedImageBackground.position.add(delta)
            this.selectedImageCrossHairParent?.removeChildren()
            this.selectedImageCrossHairParent?.remove()
            this.selectedImageCrossHairParent = null
            this.createSelectionCrossHairsForImage()
        }
    }

    public extractOverlayDataToSave = (id: number) => {
        const imageRaster = this.paperRastersDict[id]
        const imageRasterOriginalData = this.originalImageSizeDictionary[id]

        if (!imageRaster || !imageRasterOriginalData) return null

        return {
            scale: imageRaster.bounds.size.width / imageRasterOriginalData.width,
            rotation: imageRaster.rotation > 0 ? imageRaster.rotation : 360 - Math.abs(imageRaster.rotation),
            position: [imageRaster.position.x, imageRaster.position.y] as Vector2D,
        }
    }

    public isPathInsideOverlay = (pathItem: paper.Item, id: number) => {
        const imageRaster = this.paperRastersDict[id]

        return pathItem.intersects(imageRaster) || pathItem.isInside(imageRaster.bounds)
    }

    public toggleVisibilityOfOverlayById = (id: number) => {
        const imageRaster = this.paperRastersDict[id]
        const rasterBackground = this.rasterBackgroundDict[id]

        if (!imageRaster || !rasterBackground) return

        imageRaster.visible = !imageRaster.visible
        rasterBackground.visible = !rasterBackground.visible
    }
}
