export const EAVE_GABLE_SOFFIT_TYPE_FOR_TG_WIDTH = [
    'CEDAR TG',
    'PINE TG',
    'TG PAT122',
    '#2YP PAT122',
    'D YP TG SPRUCE AQUA SEAL',
    'V GROOVE',
    'FINE LINE',
    'BEADED CEILING',
]

// options in soffit type which display conditional field soffit_vent_type
export const EAVE_GABLE_SOFFIT_TYPE_VENT_TYPES = [
    '4X8 BC PLY',
    '4X8 FC',
    '4X8 PANEL CEDAR',
    '4X8 PLYBEAD',
    '4X8 PLYWOOD',
    '4X8 ROUGH SAWN',
    '4X8 LP SMARTFIBER (3/8)',
    '4X8 T111',
    'CEDAR TG',
    'CHANNEL RUSTIC CEDAR',
    'PANEL',
]
