import { distinctUntilChanged, map, takeWhile } from 'rxjs'

import Base from '../paperTool/PaperTool'
import { Coordinate } from '../../../../../../models/activeDrawable'
import { State2D } from '../../../../../slices/2D'
import { initialToolsState, ToolsState } from '../../../../../slices/tools'
import { IMUPState, PaperToolConfig, VIEW_MODE } from '../../../../../types'

/**
 * Color.tool.tsx
 * Updates color state in IMUP 2D
 * Designed to be used as handler for a color picker and directly pass color to store
 */
// TODO: Implement tool further when editing functionality is created
export class Color extends Base {
    static NAME = 'COLOR'

    private selectedItem: State2D['selectedItem'] = null
    private color: ToolsState['color'] = initialToolsState.color

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Color.NAME
        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                map((state: IMUPState) => ({ selectedItem: state['2D'].selectedItem, color: state.tools.color })),
                distinctUntilChanged()
            )
            .subscribe(({ selectedItem, color }) => {
                this.selectedItem = selectedItem
                this.color = color
            })
    }

    protected handleChanges = (): void => {
        if (this.selectedItem !== null) {
            const item = this.paper.project.getItem({ id: this.selectedItem })

            item.fillColor = new this.paper.Color(this.color)
        }
    }

    createColor = (fromString: string): paper.Color => new this.paper.Color(fromString)

    createReflectedGradient = (outerColor: string, innerColor: string, coordinates: Coordinate[]): paper.Color => {
        const gradient = new this.paper.Gradient()
        const outerStop = new this.paper.GradientStop(new this.paper.Color(outerColor))
        const innerStop = new this.paper.GradientStop(new this.paper.Color(innerColor))
        const origin = coordinates[0]
        const destination = coordinates[1]

        gradient.stops = [outerStop, innerStop, outerStop]
        const gradientColor = new this.paper.Color(gradient, origin, destination)

        return gradientColor
    }
}

export default Color
