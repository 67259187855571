import { call, fork, select, StrictEffect, takeEvery } from 'redux-saga/effects'

import { createHighlight } from '../../slices/2D'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { createHighlights2D } from '../2D/createHighlights'

export function* handleCreateHighlights(
    action: ReturnType<typeof createHighlight>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2DFor3D:
            case VIEW_MODE.Markup2D:
                yield fork(createHighlights2D, action)
                break
            case VIEW_MODE.Markup3D:
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForCreateHighlights(): Generator<StrictEffect, void, unknown> {
    yield takeEvery(createHighlight.type, handleCreateHighlights)
}
