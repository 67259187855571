import { FormValidation, UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

const uiSchema: UiSchema = {}

const onValidate = (formData: any, errors: FormValidation) => {}

const onSubmit = (settings, formData) => {
    return { ...settings, ...formData }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
