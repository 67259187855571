import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

const uiSchema: UiSchema = {
    name: {
        'ui:title': 'Generic SKU',
        'ui:placeholder': 'Enter Generic SKU',
    },
    description: {
        'ui:title': 'Description',
        'ui:placeholder': 'Enter Description',
    },
    package: {
        'ui:placeholder': 'Select Package',
    },
    quantity: {
        'ui:title': 'Quantity',
        'ui:placeholder': 'Enter Quantity',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return { ...settings, ...formData }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
