import isNan from 'lodash/isNaN'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import { select } from 'redux-saga/effects'

import { materialToFormMap } from '../../../formSchemas'
import { OpeningGroup } from '../../../models/activeDrawable'
import { DRAWABLE_TYPES, IMUP_WINDOW_AND_DOOR_TYPES } from '../../../shared/constants/drawable-types'
import { deckOrPorch } from '../../../utils/formSchema/constants'
import { selectJoistDirectionOther } from '../../slices/2D'
import { selectActiveChunkScalesAndDPI } from '../../slices/documents'
import { ActiveGeometryGroup, selectActiveGeometryGroup } from '../../slices/geometry'
import { selectScaleFactor } from '../2D/createDrawableLocation'

/**
 * Tries to parse the input. If it is NaN -> default to null
 */
function tryParseFloat(input: string): number | null {
    const parsedInput = parseFloat(input)

    return !isNan(parsedInput) ? parsedInput : null
}

/**
 * Cleans the advanced settings object by removing properties with `undefined` or `false` values.
 * Retains first-level keys as empty objects if all nested properties are removed.
 *
 * @param {Object} advancedSettings - The advanced settings object to clean.
 *
 * @returns {Object} - The cleaned advanced settings object.
 */
function cleanAdvancedSettings(advancedSettings) {
    return Object.entries(advancedSettings).reduce((acc, [key, value]) => {
        if (value && typeof value === 'object' && !Array.isArray(value)) {
            const cleanedValue = cleanAdvancedSettings(value)

            acc[key] = Object.keys(cleanedValue).length > 0 ? cleanedValue : {} // keep as an empty object if all properties are removed
        } else if (value !== undefined && value !== false) {
            acc[key] = value
        } else if (typeof value === 'object' && value !== null) {
            acc[key] = {} // set to empty object if all values are removed
        }

        return acc
    }, {})
}

export function* prepareActiveDrawableBeforeSubmit(payload: { formData: OpeningGroup['settings'] }) {
    const activeDrawableGroup: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)

    if (isNull(activeDrawableGroup)) return

    const scaleFactor: number = yield select(selectScaleFactor)

    const { dpi, pdfScale, xCalibrationFactor, yCalibrationFactor } = yield select(selectActiveChunkScalesAndDPI)

    const prepareSettingsFromForm = materialToFormMap[activeDrawableGroup.type]?.onSubmit

    const prepareDrawables = materialToFormMap[activeDrawableGroup.type]?.prepareDrawables

    if (isUndefined(prepareSettingsFromForm)) return

    const settings = prepareSettingsFromForm({}, payload.formData)

    // since bundle fields are general, keep clean up general
    if (!deckOrPorch.includes(settings.bundle_name)) {
        settings.bundle_location = null
        settings.bundle_floor_level = null
    }

    if (settings.bundle_floor_level !== 'other') {
        settings.bundle_other_floor_level = null
    }

    const directionOther: string | null = yield select(selectJoistDirectionOther)

    if (directionOther) {
        settings.direction_other = directionOther
    }

    settings.linear_total = tryParseFloat(settings.linear_total)
    settings.quantity = tryParseFloat(settings.quantity)
    settings.total_quantity = tryParseFloat(settings.total_quantity)

    const activeGeometryGroup: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)
    const openings = activeGeometryGroup && activeGeometryGroup.openings.filter((opening) => opening.isActive)

    const configuration = IMUP_WINDOW_AND_DOOR_TYPES.includes(activeDrawableGroup.type)
        ? settings.configuration ?? undefined
        : undefined

    const remarks = IMUP_WINDOW_AND_DOOR_TYPES.includes(activeDrawableGroup.type)
        ? settings.remarks ?? undefined
        : undefined

    if (IMUP_WINDOW_AND_DOOR_TYPES.includes(activeDrawableGroup.type) && settings.configuration) {
        delete settings.configuration
    }

    if (IMUP_WINDOW_AND_DOOR_TYPES.includes(activeDrawableGroup.type) && settings.remarks) delete settings.remarks

    // remove the type from settings in case of headers, because we don't have a type in the form at all
    if (activeDrawableGroup.type === DRAWABLE_TYPES.HEADER && settings?.type) {
        delete settings.type
    }

    // if advanced settings exist, clean them
    const preparedSettings = settings?.advanced_settings
        ? {
              ...settings,
              advanced_settings: cleanAdvancedSettings(settings.advanced_settings),
          }
        : settings

    return {
        activeDrawableGroup,
        prepareDrawables,
        openings,
        settings: JSON.parse(JSON.stringify(preparedSettings, (_, value) => (value === undefined ? null : value))),
        configuration,
        remarks,
        scaleFactor,
        dpi,
        pdfScale,
        xCalibrationFactor,
        yCalibrationFactor,
    }
}
