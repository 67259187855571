import { createAction } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { updateSingleDocumentMapping } from '../effects/updateSingleDocumentMapping'
import { highlightOptionConflictsAction } from './highlightOptionConflicts'
import { DocumentMapping } from '../../../models/documentMapping'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Overlay, Select } from '../../lib/toolBoxes/2D'
import { selectDocumentMappingByActiveFloorId, selectDocumentMappings } from '../../slices/documents'
import { Vector2D } from '../../types'

export const lockOverlayAction = createAction<number | null>('lockOverlay')

export function* handleLockOverlay(action: ReturnType<typeof lockOverlayAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    const activeChunkMapping: DocumentMapping | null = yield select(selectDocumentMappingByActiveFloorId)

    if (isNull(manager) || action.payload === null || !activeChunkMapping) return

    const [overlayTool]: [Overlay] = yield call(manager.getTools, [Overlay.NAME])

    const overlayData: {
        scale: number
        rotation: number
        position: Vector2D
    } | null = yield call(overlayTool.extractOverlayDataToSave, action.payload)

    if (!overlayData) return

    const mappings: DocumentMapping[] | null = yield select(selectDocumentMappings)

    if (!mappings) return

    const mapping = mappings.find((map) => map.document_chunk_id === action.payload)

    if (!mapping) return

    if (action.payload) {
        yield put(highlightOptionConflictsAction({ overlayPaperRasterId: action.payload }))

        yield call(manager.useTool, Select.NAME)
    }

    yield put(
        updateSingleDocumentMapping({
            ...mapping,
            overlay_center: overlayData.position,
            overlay_rotation: overlayData.rotation,
            overlay_scale: overlayData.scale,
            scale_factor: activeChunkMapping.scale_factor,
        })
    )
}

export function* watchForOverlayLocking() {
    yield takeEvery(lockOverlayAction.type, handleLockOverlay)
}
