import { PayloadAction } from '@reduxjs/toolkit'
import { call, fork, select, takeEvery } from 'redux-saga/effects'

import { SET_ACTIVE_DRAWABLE_GROUP } from '../../../actions/drawable'
import { OpeningGroup } from '../../../models/activeDrawable'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleSetActiveDrawableGroup2D } from '../2D/handleSetActiveDrawableGroup'
import { handleSetActiveDrawableGroup3D } from '../3D/handleSetActiveDrawableGroup'

export function* handleSetActiveDrawableGroup(
    action: PayloadAction<{ activeDrawableGroup: OpeningGroup; activeDrawableId: number | undefined }>
) {
    try {
        const activeMode = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2DFor3D:
            case VIEW_MODE.Markup2D:
                yield fork(handleSetActiveDrawableGroup2D, action)
                break
            case VIEW_MODE.Markup3D:
                yield fork(handleSetActiveDrawableGroup3D, action)
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForSetActiveDrawableGroup() {
    yield takeEvery(SET_ACTIVE_DRAWABLE_GROUP, handleSetActiveDrawableGroup)
}
