import { FieldValues } from 'react-hook-form'
import { FormValidation, UiSchema } from '@rjsf/core'
import omit from 'lodash/omit'

import { FormProperties } from '../imup/types'
import { DRAWABLE_SETTINGS_TYPES } from '../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { pitchNumberValidation } from '../shared/services/field-validation/field-validation-service'
import { prepareValue } from '../shared/services/opening-form-services'

const otherValue = 'other'

export const valuesPreparation = (formData: FieldValues) => {
    const settings = { ...formData }

    if (prepareValue(settings.material) !== 'dimensional') {
        settings.board_size = null
    }

    if (prepareValue(settings.material) !== 'i-joist') {
        settings.ijoist_size = null
        settings.ijoist_serie = null
    }

    if (!['lsl', 'lvl', 'psl'].includes(prepareValue(settings.material))) {
        settings.dimension = null
    }

    if (settings.dimension !== 'other') {
        settings.dimension_other_width = null
        settings.dimension_other_height = null
    }

    // clear fields that can't be selected when type is Rafter
    if (prepareValue(settings.type) !== DRAWABLE_SETTINGS_TYPES.RAFTER) {
        settings.rafter_tail = null
    }

    if (prepareValue(settings.ijoist_size).includes('tji')) {
        settings.bci_series = null
        settings.lpi_series = null
        settings.ajs_series = null
        settings.pwi_series = null
    }

    if (prepareValue(settings.ijoist_size).includes('bci')) {
        settings.tji_series = null
        settings.lpi_series = null
        settings.ajs_series = null
        settings.pwi_series = null
    }

    if (prepareValue(settings.ijoist_size).includes('lpi')) {
        settings.bci_series = null
        settings.tji_series = null
        settings.ajs_series = null
        settings.pwi_series = null
    }

    if (prepareValue(settings.ijoist_size).includes('pwi')) {
        settings.bci_series = null
        settings.tji_series = null
        settings.ajs_series = null
        settings.lpi_series = null
    }

    if (prepareValue(settings.ijoist_size).includes('ajs')) {
        settings.lpi_series = null
        settings.bci_series = null
        settings.tji_series = null
        settings.pwi_series = null
    }

    if (!settings.floor_level) {
        settings.floor_level_other = null
    }

    return settings
}

export const uiSchema: UiSchema = {
    'ui:order': [
        'type',
        'oc_spacing',
        'oc_spacing_other',
        'material',
        'brand',
        'dimension',
        'dimension_other_width',
        'dimension_other_height',
        'board_size',
        'ijoist_size',
        'ajs_series',
        'bci_series',
        'lpi_series',
        'pwi_series',
        'tji_series',
        'direction',
        'pitch',
        'pitch_other',
        'floor_level',
        'floor_level_other',
        'rafter_tail',
        'is_treated',
        'engineered',
    ],
    type: {
        'ui:placeholder': 'Select Joist Type',
    },
    oc_spacing: {
        'ui:placeholder': 'Select Joist O.C Spacing',
    },
    direction: {
        'ui:field': 'DropdownField',
        'ui:placeholder': 'Select Joist Direction',
    },
    dimension: {
        'ui:title': 'Dimension',
        'ui:placeholder': 'Select Dimension',
    },
    is_treated: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    ijoist_size: {
        'ui:placeholder': 'Select I-Joist Size',
    },
    ajs_series: {
        'ui:placeholder': 'Select AJS Series',
    },
    bci_series: {
        'ui:placeholder': 'Select BCI Series',
    },
    lpi_series: {
        'ui:placeholder': 'Select LPI Series',
    },
    pwi_series: {
        'ui:placeholder': 'Select PWI Series',
    },
    tji_series: {
        'ui:placeholder': 'Select TJI Series',
    },
    pitch: {
        'ui:placeholder': 'Select Pitch',
    },
    board_size: {
        'ui:placeholder': 'Select Board Size',
    },
    rafter_tail: {
        'ui:placeholder': 'Select Rafter Tail',
    },
    floor_level: {
        'ui:placeholder': 'Select Floor Level',
    },
    floor_level_other: {
        'ui:placeholder': 'Select Floor Level Other',
    },
    engineered: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    brand: {
        'ui:placeholder': 'Select Brand',
    },
}

const onValidate = (formData: any, errors: FormValidation) => {
    const pitchFields = ['pitch']

    pitchFields.forEach((pitchName) => {
        if (formData[pitchName] === otherValue) {
            const pitchOtherFieldName = `${pitchName}_other`

            if (!pitchNumberValidation(formData[pitchOtherFieldName])) {
                errors[pitchOtherFieldName].addError(FORM_ERROR_MESSAGES.PITCH_NUMBER)
            }
        }
    })
}

const onSubmit = (settings, formData) => {
    const newSettings = {
        ...settings,
        ...omit(valuesPreparation(formData), ['quantity']),
    }

    newSettings.pitch = 'pitch' in newSettings ? newSettings.pitch : null

    if (newSettings.pitch === otherValue && newSettings.pitch_other) {
        newSettings.pitch = String(newSettings.pitch_other).toUpperCase()

        delete newSettings.pitch_other
    } else if (newSettings.pitch !== otherValue) {
        newSettings.pitch_other = null
    }

    if (newSettings.oc_spacing === otherValue && newSettings.oc_spacing_other) {
        newSettings.oc_spacing = newSettings.oc_spacing_other
        delete newSettings.oc_spacing_other
    } else if (newSettings.oc_spacing !== otherValue) {
        newSettings.oc_spacing_other = null
    }

    if (newSettings.brand) {
        newSettings.ijoist_size = `${newSettings.brand} ${newSettings.ijoist_size}`

        // it's only for UI, we don't need to send it
        delete newSettings.brand
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
