import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { deleteMultipleFlags } from '../../../api/projects-api'
import { NormalizedFlag } from '../../../models/flags'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Flag, Workspace } from '../../lib/toolBoxes/2D'
import { removeFlag, resetActiveFlag, selectMaterialActiveFlag } from '../../slices/materialFlags'

export const deleteMultipleFlagsAction = createAction<{ flag_ids: number[] }>('deleteMultipleFlags')

export function* handleDeleteMultipleFlags({ payload }: ReturnType<typeof deleteMultipleFlagsAction>) {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const [workspaceTool, flagTool]: [Workspace, Flag] = yield call(manager.getTools, [Workspace.NAME, Flag.NAME])

        const { flag_ids } = payload

        const activeFlag: NormalizedFlag | null = yield select(selectMaterialActiveFlag)

        if (activeFlag && flag_ids.includes(activeFlag.id)) {
            yield put(resetActiveFlag())
        }

        const flagItems: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
            flag_ids.includes(data?.flag_id)
        )

        yield all(flagItems.map((item) => call(item.remove.bind(item))))

        yield call(flagTool.removeCachedFlagsByDataFlagId, flag_ids)

        yield call(deleteMultipleFlags, flag_ids)

        yield put(removeFlag({ flag_ids }))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForDeleteMultipleFlags(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(deleteMultipleFlagsAction.type, handleDeleteMultipleFlags)
}
