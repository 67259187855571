import { FormValidation, UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'

export const uiSchema: UiSchema = {
    usage_type: {
        'ui:title': 'Trayed Ceiling and Soffit Type',
        'ui:placeholder': 'Select Type',
    },
    floor_level: {
        'ui:title': 'Trayed Ceiling and Soffit Floor Level',
        'ui:placeholder': 'Select Floor Level',
    },
    floor_level_other: {
        'ui:title': 'Floor level other',
        'ui:placeholder': 'Floor level other',
    },
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.floor_level_other) {
        const floorOtherValue = parseInt(formData.floor_level_other)

        if (!(floorOtherValue > 0)) {
            errors.floor_level_other.addError(FORM_ERROR_MESSAGES.FULL_NUMBER)
        }
    }
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    newSettings.floor_level_other = formData.floor_level === 'other' ? formData.floor_level_other : ''

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
