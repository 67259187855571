export enum PROJECT_STATUS_NAME {
    JIRA_STATUS = 'JIRA STATUS',
    AI_PROCESSING = 'AI processing',
    COMPLETED = 'Completed',
    CREATION_IN_PROGRESS = 'Creation In Progress',
    DELIVERED = 'Delivered',
    ERROR = 'Error',
    ERROR_PENDING_COMPLETION = 'Error Pending Completion',
    IN_PROGRESS = 'In Progress',
    INTERNAL_HOLD = 'Internal Hold',
    MATERIAL_CALCS_ERROR = 'Material Calcs Error',
    NEW = 'New',
    OFFSHORE_REVIEW_1 = 'Offshore Review 1',
    OFFSHORE_REVIEW_2 = 'Offshore Review 2',
    ONSHORE_REVIEW = 'Onshore Review',
    PENDING_COMPLETION = 'Pending Completion',
    PENDING_MATERIAL_CALCS = 'Pending Material Calcs',
    PENDING_MATERIAL_CALCULATION = 'Pending Material Calculation',
    READY_FOR_OFFSHORE_REVIEW_1 = 'Ready for Offshore Review 1',
    READY_FOR_OFFSHORE_REVIEW_2 = 'Ready for Offshore Review 2',
    READY_FOR_ONSHORE_REVIEW = 'Ready for Onshore Review',
    READY_FOR_PROJECT_DELIVERY = 'Ready for Project Delivery',
    READY_FOR_ESTIMATING = 'Ready for Estimating',
    READY_FOR_REVIEW = 'Ready For Review',
    READY_FOR_REWORK = 'Ready for Rework',
    READY_FOR_TAKEOFF = 'Ready For Takeoff',
    REQUEST_MORE_INFO = 'Request More Info',
    IN_REWORK = 'In Rework',
    RUNNING_AI = 'Running AI',
    TECHNICAL_ISSUE = 'Technical Issue',
    WAITING_FOR_CUSTOMER = 'Waiting for Customer',
    REWORK = 'Rework',
}

/**
 * List of project statuses when flags and flag tool should be visible
 */
export const isProjectStatusEnableFlags: Set<PROJECT_STATUS_NAME> = new Set([
    PROJECT_STATUS_NAME.ONSHORE_REVIEW,
    PROJECT_STATUS_NAME.OFFSHORE_REVIEW_1,
    PROJECT_STATUS_NAME.OFFSHORE_REVIEW_2,
    PROJECT_STATUS_NAME.IN_REWORK,
    PROJECT_STATUS_NAME.REWORK,
])
