import { all, AllEffect, StrictEffect } from 'redux-saga/effects'

import { watchForBuildBulkUpdateOpeningLocationCoordinates } from './2D/bulkUpdateOpeningLocationCoordinates'
import { watchForResettingMaterialModifications } from './2D/clearMaterialModificationsFromCanvas'
import { watchForMultiSelectingMaterial } from './2D/clearMultiSelectedDrawables'
import { watchForCopyFlag } from './2D/copyFlag'
import { watchForCreateFlag } from './2D/createFlag'
import { watchForModificationCreation } from './2D/createModifiction'
import { watchForCreateRegion } from './2D/createRegion'
import { watchForCreateMasterSetPlanAction } from './2D/createSetMasterSetPlan'
import { watchForFetchAdminData } from './2D/data-prep/fetchAdminPageData'
import { watchForFetchAllProjectData } from './2D/data-prep/fetchAllProjectData'
import { watchForSelectedProject } from './2D/data-prep/prepareMarkupData'
import { watchForDeleteDrawableLocationsFromUI } from './2D/deleteDrawableLocationsFromUI'
import { watchForDeleteMasterSetPlan } from './2D/deleteMasterSetPlan'
import { watchForDeleteModification } from './2D/deleteModification'
import { watchForDeleteMultipleFlags } from './2D/deleteMultipleFlags'
import { watchForDeleteMultiSelectedDrawables } from './2D/deleteMultiSelectedDrawables'
import { watchForDeleteRegion } from './2D/deleteRegion'
import { watchForDuplicateDocumentChunk } from './2D/duplicateDocumentChunk'
import { watchForDuplicateDocumentChunkAndEstimations } from './2D/duplicateDocumentChunksAndEstimation'
import { watchForHighlightEditSave } from './2D/editHighlights'
import { watchForApplyToAllBuilding } from './2D/handleApplyToAllBuildings'
import { watchForHandleCreateMeasurements } from './2D/handleCreateMeasurements'
import { watchForDrawAISuggestions } from './2D/handleDrawAISuggestions'
import { watchForHighlightActiveFlag } from './2D/handleHighlightActiveFlag'
import { watchForToggleMaterialFlagsVisibility } from './2D/handleToggleMaterialFlagsVisibility'
import { watchForToggleMeasureLinesVisibility } from './2D/handleToggleMeasurementVisibility'
import { watchForToggleRegionLinesVisibility } from './2D/handleToggleRegionLinesVisibility'
import { watchForToolCoordinatesUpdate } from './2D/handleToolCoordinatesUpdate'
import { watchForHighlightOptionConflicts } from './2D/highlightOptionConflicts'
import { watchForSelectingNewImageOverlay } from './2D/insertOverlay'
import { watchForLinkGeometryFromFlag } from './2D/linkGeometryFromFlag'
import { watchForOverlayLocking } from './2D/lockOverlay'
import { watchForDocumentMarkup } from './2D/markupDocument'
import { watchForPasteMaterial } from './2D/pasteMaterial'
import { watchForPublishAdminOptions } from './2D/publishAdminOptions'
import { watchHandleRemoveGroupIdsFromMultiSelection } from './2D/removeGroupIdsFromMultiSelect'
import { watchForDrawableReset } from './2D/resetDrawables'
import { watchForOverlayPositionReset } from './2D/resetOverlayPosition'
import {
    watchForDrawPlanReview,
    watchForExitedPlanReview,
    watchForHydrateMasterSetPlanForm,
    watchForHydrateRegionsForm,
} from './2D/reviewPlan'
import { watchForOverlayRotation } from './2D/rotateOverlay'
import { watchForSaveUpdatedCalibrationFactor } from './2D/saveUpdatedCalibrationFactor'
import { watchForFetchTakeoffData } from './2D/takeoffData'
import { watchForToggleVisibilityOfOverlay } from './2D/toggleVisiblityofOverlay'
import { watchForUnlinkGeometryFromFlag } from './2D/unlinkGeometryFromFlag'
import { watchForDoubleJoistToggle } from './2D/updateDoubleJoistLines'
import { watchForUpdateFlag } from './2D/updateFlag'
import { watchForUpdateFlagCoordinates } from './2D/updateFlagOpeningCoordinates'
import { watchForUpdateMasterSetPlanFromId } from './2D/updateMasterSetPlanFromId'
import { watchForPageChangeAndResetOverlay } from './2D/updateOverlayChunkOnPageChange'
import { watchForSelectingNewImageOverlayColor } from './2D/updateOverlayColor'
import { watchForSelectingNewImageOverlayOpacity } from './2D/updateOverlayOpacity'
import { watchForUpdateRegionFromId } from './2D/updateRegionFromId'
import { watchForBuild3DModel } from './3D/build3DModel'
import { watchFloorVisibilityChange } from './3D/changeFloorVisibility'
import { watchForPrepare3DModelAction } from './3D/data-prep/prepare3DModel'
import { watchForMeasurementPointsChange } from './3D/drawMeasurementPoints'
import { watchForReset3DState } from './3D/reset3DState'
import { watchForSelectedMesh } from './3D/selectMesh'
import { watchForSelectedMeshLabelId } from './3D/selectMeshLabel'
import { watchFroDesignOptionChange } from './3D/setDesignOptions'
import { watchSelectedOpeningGroup } from './3D/updateSelectedOpeningGroupAndHighlightRequiredMeshes'
import { watchForToggleDrawableOnHover } from './effects/animateOnHover'
import { watchForToggleOpeningLocationOnHover } from './effects/animateOpeningLocationOnHover'
import { watchForSetSelectToolState } from './effects/changeSelectToolState'
import { watchForCreateDrawableByTool } from './effects/createDrawableByTool'
import { watchForCreateDrawableLocation } from './effects/createDrawableLocation'
import { watchForCreateHighlights } from './effects/createHighlights'
import { watchForCreateSingleDocumentMapping } from './effects/createSingleDocumentMapping'
import { watchForBuildingDelete } from './effects/deleteBuilding'
import { watchForDocumentChunksDelete } from './effects/deleteDocumentChunks'
import { watchForDeleteDrawableLocation } from './effects/deleteDrawableLocation'
import { watchForDeleteOpeningLocation } from './effects/deleteOpeningLocation'
import { watchForCloseFlagForm } from './effects/deleteTemporaryFlags'
import { watchForEditingActive } from './effects/editingActive'
import { watchForMaterialFormChange } from './effects/getFormByType'
import { watchForCloseMaterialForm } from './effects/handleCloseMaterialForm'
import { watchForCloseToolForm } from './effects/handleCloseToolForm'
import { watchForCoordinatesUpdate } from './effects/handleCoordinatesUpdate'
import { watchForDeleteDrawableGroup } from './effects/handleDeleteDrawableGroup'
import { watchForMaterialFormSubmit } from './effects/handleMaterialFormSubmit'
import { watchForMaterialFormSubmitAndRefresh } from './effects/handleMaterialFormSubmitAndRefresh'
import { watchForRecalculateMeasurements } from './effects/handleRecalculateMeasurements'
import { watchForActiveTabChange } from './effects/handleSetModeFromActiveTab'
import { watchForToolFormSubmit } from './effects/handleToolFormSubmit'
import { watchForDocumentChunksRestore } from './effects/restoreDocumentChunks'
import { watchForRotateImageLocal, watchForRotateImageRemote } from './effects/rotateImage'
import { watchForSetActiveDrawable } from './effects/setActiveDrawable'
import { watchForSetActiveDrawableGroup } from './effects/setActiveDrawableGroup'
import { watchForSetActiveDrawableGroupFromId } from './effects/setActiveDrawableGroupFromId'
import { watchForSetActiveFloorFromDocChunkId } from './effects/setActiveFloorFromDocChunkId'
import { watchForToggleActiveDrawable } from './effects/toggleActiveDrawable'
import { watchForToggleActiveDrawableGroup } from './effects/toggleActiveDrawableGroup'
import { watchForToggleDrawableGroups } from './effects/toggleDrawableGroups'
import { watchForMoveChunksToBuilding } from './effects/updateDocumentMappingOnGroupMove'
import { watchForUpdateJoistLines } from './effects/updateJoistLines'
import { watchForSelectionCompletion } from './effects/updateOpeningGroupSuccess'
import { watchForUpdateSingleDocumentMapping } from './effects/updateSingleDocumentMapping'

export function* rootSaga(): Generator<AllEffect<Generator<StrictEffect>>> {
    yield all([
        // COMMON
        watchForSelectedProject(), // [selectProjectWithId.type] requests/transforms data, forks to 3D procedure if three_d_identifiers present in openings data
        watchForToggleActiveDrawable(), // [TOGGLE_ACTIVE_DRAWABLE] turns on/off the active state for an opening location
        watchForSetActiveDrawableGroup(), // [SET_ACTIVE_DRAWABLE_GROUP] turns on active state for drawable group
        watchForToggleActiveDrawableGroup(), // [TOGGLE_ACTIVE_DRAWABLE_GROUP, sceneRendered.type] turns on/off the active state for an opening group
        watchForToggleDrawableGroups(), // [TOGGLE_DRAWABLE_GROUPS, TOGGLE_DRAWABLE_GROUPS_BY_TYPE] turns on/off the active state for opening groups
        watchForToggleDrawableOnHover(), // [TOGGLE_DRAWABLE_ON_HOVER] // turns on/off animations on hover of a selectable item
        watchForToggleOpeningLocationOnHover(), // [TOGGLE_OPENING_LOCATION_ON_HOVER] // turns on/off animations on hover of a selectable item
        watchForEditingActive(), //[editingActive.type]
        watchForUpdateJoistLines(), // [UPDATE_JOIST_LINES]  // for updating joist lines on refresh
        watchForMaterialFormChange(), // [SET_ACTIVE_DRAWABLE_GROUP] // hydrates material form and populates store with form schema for material selection
        watchForMaterialFormSubmit(),
        watchForMaterialFormSubmitAndRefresh(),
        watchForSetActiveFloorFromDocChunkId(), // [SET_ACTIVE_FLOOR_FROM_DOC_CHUNK_ID]
        watchForMoveChunksToBuilding(), // [moveChunksToBuilding.type]
        watchForUpdateSingleDocumentMapping(), // [updateSingleDocumentMapping.type]
        watchForCreateSingleDocumentMapping(), // [createSingleDocumentMapping.type]
        watchForFetchAllProjectData(), // [fetchAllProjectData.type]
        watchForBuildingDelete(),
        watchForDocumentChunksDelete(),
        watchForDocumentChunksRestore(),
        watchForCloseMaterialForm(),
        watchForDeleteDrawableGroup(),
        watchForSetSelectToolState(),
        watchForFetchAdminData(), // [fetchAdminData.type]
        watchForPublishAdminOptions(), // [publishAdminOptionsAction.type]
        watchForActiveTabChange(),

        // 3D
        watchForBuild3DModel(), // [build3DModel.type] // builds the 3D model with Babylon.js tools
        watchForSelectedMesh(), // [selectMesh.type] //
        watchForSelectedMeshLabelId(),
        watchForSetActiveDrawable(), // [SET_ACTIVE_DRAWABLE]
        watchSelectedOpeningGroup(),
        watchForReset3DState(),
        watchForMeasurementPointsChange(),
        watchFloorVisibilityChange(),
        watchFroDesignOptionChange(),
        watchForPrepare3DModelAction(),

        // 2D
        watchForApplyToAllBuilding(),
        watchForDocumentMarkup(), // [draw2DMarkup.type]
        watchForSetActiveDrawableGroupFromId(), // [SET_ACTIVE_DRAWABLE_GROUP_FROM_ID] dispatches SET_ACTIVE_DRAWABLE_GROUP or TOGGLE_ACTIVE_DRAWABLE
        watchForDrawableReset(), // [RESET_DRAWABLE_GROUPS, SET_GROUP_STATUS]
        watchForSelectionCompletion(), // [UPDATE_OPENING_GROUPS_SUCCESS]
        watchForCoordinatesUpdate(),
        watchForDeleteDrawableLocation(), // [deleteDrawable.type]
        watchForDeleteOpeningLocation(), // [deleteDrawableOpening.type]
        watchForDrawPlanReview(), // [drawPlanReview] // renders plan in canvas with regions and scales information
        watchForExitedPlanReview(), // [exitPlanReview] // renders plan in canvas with regions and scales information
        watchForHydrateRegionsForm(), // [hydrateRegionsForm] // renders plan in canvas with regions and scales information
        watchForCreateHighlights(), // [createHighlights] // Create highlighted areas
        watchForRotateImageLocal(), // [rotateImageLocal.type]
        watchForRotateImageRemote(), // [rotateImageRemote.type]
        watchForUpdateRegionFromId(), // [updateRegionFromId.type]
        watchForCreateRegion(), // [createRegion.type],
        watchForHighlightEditSave(), // [saveEditsToHighlight]
        watchForDeleteRegion(), //  [deleteRegionAction.type]
        watchForSaveUpdatedCalibrationFactor(), // [saveUpdatedCalibrationFactor.type]
        watchForCreateDrawableLocation(), // [createDrawableLocation.type]
        watchForDuplicateDocumentChunk(), // [duplicateDocumentChunkAction.type]
        watchForDoubleJoistToggle(), // [updateDoubleJoistAction.type]
        watchForRecalculateMeasurements(), // [recalculateMeasurements.type]
        watchForFetchTakeoffData(),
        watchForCreateFlag(), // [createFlagAction.type]
        watchForDeleteMultipleFlags(), // [deleteMultipleFlagsAction.type]
        watchForUpdateFlag(), // [updateFlagAction.type]
        watchForCloseFlagForm(),
        watchForUnlinkGeometryFromFlag(), // [unlinkGeometryFromFlagAction.type]
        watchForLinkGeometryFromFlag(), // [linkGeometryFromFlagAction.type]
        watchForToggleRegionLinesVisibility(), // [toggleRegionLinesVisibilityAction.type]
        watchForToggleMaterialFlagsVisibility(), // [toggleMaterialFlagsVisibilityAction.type]
        watchForDrawAISuggestions(), // [drawAISuggestions.type]
        watchForCreateDrawableByTool(), // [watchForCreateDrawableByTool.type]
        watchForCloseToolForm(), // [closeToolFormAction.type]
        watchForDeleteDrawableLocationsFromUI(),
        watchForToolFormSubmit(), // [submitToolForm.type]
        watchForToolCoordinatesUpdate(),
        watchForCreateMasterSetPlanAction(), // [createMasterSetPlanAction.type]
        watchForHydrateMasterSetPlanForm(), // [hydrateMasterSetPlanForm.type]
        watchForUpdateMasterSetPlanFromId(), //[updateMasterSetPlanFromId.type]
        watchForDeleteMasterSetPlan(), //[deleteMasterSetPlanAction.type]
        watchForSelectingNewImageOverlay(), // [setOverlayChunkId.type]
        watchForSelectingNewImageOverlayOpacity(), // [updateOverlayOpacity.type ]
        watchForSelectingNewImageOverlayColor(), // [updateOverlayColor.type]
        watchForPageChangeAndResetOverlay(), // [SET_ACTIVE_FLOOR]
        watchForOverlayRotation(), // [rotateOverlayAction.type]
        watchForOverlayPositionReset(), // [resetOverlayPositionAction.type]
        watchForOverlayLocking(), // [lockOverlayAction.type],
        watchForHighlightOptionConflicts(), // [highlightOptionConflictsAction.type]
        watchForModificationCreation(), //[createModificationAction.type]
        watchForDeleteModification(), //[deleteModificationAction.type]
        watchForDuplicateDocumentChunkAndEstimations(), //[duplicateDocumentChunkAndEstimationsAction.type]
        watchForMultiSelectingMaterial(), //[multiSelectDrawables.type]
        watchForBuildBulkUpdateOpeningLocationCoordinates(), //[bulkUpdateOpeningLocationCoordinatesAction.type]
        watchForPasteMaterial(), //[pasteMaterialAction.type]
        watchForDeleteMultiSelectedDrawables(), //[deleteMultiSelectedDrawablesAction.type]
        watchHandleRemoveGroupIdsFromMultiSelection(), //[removeGroupIdsFromMultiSelectItemsAction.type]
        watchForHandleCreateMeasurements(),
        watchForToggleMeasureLinesVisibility(),
        watchForToggleVisibilityOfOverlay(),
        watchForResettingMaterialModifications(),
        watchForHighlightActiveFlag(),
        watchForCopyFlag(), //[copyFlagAction.type]
        watchForUpdateFlagCoordinates(),
    ])
}

export default rootSaga
