import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo'
import { Engine } from '@babylonjs/core/Engines/engine'
import { IPointerEvent } from '@babylonjs/core/Events/deviceInputEvents'
import { Color3, Color4 } from '@babylonjs/core/Maths/math.color'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { Nullable } from '@babylonjs/core/types'
import noop from 'lodash/noop'
import { distinctUntilChanged, map, takeWhile } from 'rxjs/operators'

import BabylonTool from '../babylonTool/BabylonTool'
import { BabylonPredicateFn, BabylonToolConfig, IMUPState, MouseButtonCodes, VIEW_MODE } from '../../../../../types'

/**
 * This class assumes that there will be only one active tool at a time and thus uses the
 * scene and camera babylon objects directly to attach the correct listener functions
 * directly on to the scene object. If this assumption is no longer valid
 * then the replacement should be the addition of an EventManager class that attaches
 * and removes functions to the scene instead of the tool classes doing it directly
 */
export class Select extends BabylonTool {
    static NAME = 'SELECT'

    private static MAIN_HIGHLIGHT_COLOR: Color4 = Color3.Black().toColor4()
    private static EDGE_WIDTH = 25

    private isContextMenuOpen = false
    private meshesInLayer: Mesh[] = []
    private selectionPredicateFunction: BabylonPredicateFn | undefined

    constructor(config: BabylonToolConfig) {
        super(config)
        this.registerActivationFunctions([this.onMouseDown])
        this.name = Select.NAME

        this.mediator
            .get$()
            .pipe(
                takeWhile((state: IMUPState) => state.common.activeMode === VIEW_MODE.Markup3D),
                map((state: IMUPState) => {
                    return {
                        shouldOpenMeshOpeningGroupMenu: state['3D'].shouldOpenMeshOpeningGroupMenu,
                    }
                }),
                distinctUntilChanged()
            )
            .subscribe(({ shouldOpenMeshOpeningGroupMenu }) => {
                this.isContextMenuOpen = shouldOpenMeshOpeningGroupMenu
            })
    }

    /**
     * Predicate function for determining which mesh is clickable used in selection function to determine what
     * meshes are selectable
     * @returns returns the babylonJS picking info or null
     */
    private pickWithRayPicker = (): Nullable<PickingInfo> => {
        return this.scene.pickWithRay(this.createPickingRay(), this.selectionPredicateFunction)
    }

    /**
     * Select a mesh
     * @param mesh the mesh to be added to the layer (to be highlgihted)
     * @param isMainMesh optional parameter whether or not the mesh is "main selected"
     */
    private selectMesh = (mesh: Mesh, isMainMesh?: boolean): void => {
        if (isMainMesh) {
            // Enable Edge rendering for Meshes that are the main selected
            // Mesh, we have to use an internal line shader
            // attribute to make sure that we set the correct depth
            // function to always show the main selected mesh edges even
            // though it would be occluded
            mesh.enableEdgesRendering()
            mesh.edgesRenderer!['_lineShader'].depthFunction = Engine.ALWAYS
            mesh.edgesColor = Select.MAIN_HIGHLIGHT_COLOR
            mesh.edgesWidth = Select.EDGE_WIDTH
        } else {
            mesh.disableEdgesRendering()
        }

        if (!this.meshesInLayer.includes(mesh)) {
            this.meshesInLayer.push(mesh)
        }
    }

    /**
     * Selection event handler function
     * @param evt  the babylonjs pointer observer info
     */
    private handleSelection = (evt: IPointerEvent): void => {
        const pick = this.pickWithRayPicker()
        const leftClicked = evt.button === MouseButtonCodes.Left
        const hitAMesh = pick && pick.hit

        if (leftClicked && hitAMesh && !this.isContextMenuOpen) {
            const pickedMesh = pick!.pickedMesh as Mesh
            const selectedMeshID = pickedMesh.id

            this.mediator.mediate('3D', { selectedMeshID })
        } else if (this.isContextMenuOpen) {
            this.mediator.mediate('3D', { shouldOpenMeshOpeningGroupMenu: false })
            this.clearSelectedMeshes()
        }
    }

    /**
     * Add an observer to the pointer observable
     * and returns the function that is used in
     * deactivation
     * @returns
     */
    private onMouseDown = (): (() => void) => {
        this.scene.onPointerDown = this.handleSelection

        return (): void => {
            this.scene.onPointerDown = noop
        }
    }

    /**
     * Select a list of mesh ids
     * @param ids array of ids to add to highlight layer
     * @param useMainHighlight
     */
    public selectMeshIds = (ids: string[], useMainHighlight?: boolean): void => {
        ids.forEach((id) => {
            const meshes = this.scene.getMeshesById(id)

            meshes.length > 0 && meshes.forEach((mesh) => this.selectMesh(mesh as Mesh, useMainHighlight))
        })
    }

    /**
     * Clears the selection of all meshes
     * and stop flashing material
     */
    public clearSelectedMeshes = (): void => {
        this.meshesInLayer.forEach((mesh) => {
            mesh.disableEdgesRendering()
        })
        this.meshesInLayer = []
        this.mediator.mediate('3D', { selectedMeshID: null })
    }

    /**
     * Sets the predicate function that will be used to determine mesh
     * selection
     * @param predicate function to be called on selection
     */
    public setSelectionPredicate = (predicate: BabylonPredicateFn): void => {
        this.selectionPredicateFunction = predicate
    }
}

export default Select
