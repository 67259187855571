import { createAction, createSelector } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import omit from 'lodash/omit'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import drawToolByType from './drawToolByType'
import { selectMarkupState } from './markupDocument'
import { getFormSchema } from '../../../api/takeoff-api'
import { materialToFormMap } from '../../../formSchemas'
import { DocumentChunk } from '../../../models/documentChunk'
import { DocumentMapping } from '../../../models/documentMapping'
import { MASTER_SET_PLAN_ENUMS } from '../../../models/masterSetPlan'
import { Region } from '../../../models/region'
import { IToolObject } from '../../../models/tool'
import { REGION_COLOR } from '../../../shared/constants/colors'
import { RootState } from '../../../stores'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import {
    Color,
    Image,
    Label,
    MasterSetPlanRegion,
    PathTool,
    RegionTool,
    Select,
    Workspace,
} from '../../lib/toolBoxes/2D'
import addSelectFunctionalityToRegion from '../../lib/utils/functionality-bindings/addSelectFunctionalityToRegion'
import { initial2DState, selectDrawableLocationsForPage, updateSelectedMaterialType } from '../../slices/2D'
import { selectActiveDocumentChunk, selectDocumentMappings } from '../../slices/documents'
import { activateForm, cacheForm, clearActiveForm, GENERAL_CACHE } from '../../slices/forms'
import { sceneRendered, sceneRendering } from '../../slices/loading'
import { resetMappingFormState, updateActiveMapping } from '../../slices/mappings'
import {
    addMasterSetPlanOptionToStore,
    selectMasterSetPlanOptionsForActivePage,
    updateMasterSetPlanFormState,
} from '../../slices/masterSetPlan'
import { selectAllRegions } from '../../slices/region'
import {
    changeAreaOpacity,
    changeAreaStrokeWidth,
    changeColor,
    changeLineDashes,
    initialToolsState,
    selectMeasurementToolObjects,
} from '../../slices/tools'
import { IMUP2DDrawableLocation, REGION_ENUMS } from '../../types'

export const drawPlanReview = createAction('drawPlanReview')
export const exitPlanReview = createAction('exitPlanReview')
export const hydrateRegionsForm = createAction('hydrateRegionsForm')
export const hydrateMasterSetPlanForm = createAction<MASTER_SET_PLAN_ENUMS>('hydrateRegionsForm')

function* configureToolsForRegionCreation() {
    yield all([
        put(updateSelectedMaterialType(REGION_ENUMS.TYPE)),
        put(changeAreaOpacity(0.001)),
        put(changeAreaStrokeWidth(20)),
    ])
}

function* cleanupPlanReview() {
    yield all([
        put(updateSelectedMaterialType(initial2DState.selectedMaterialType)),
        put(changeLineDashes(initialToolsState.dashArray)),
        put(changeColor(initialToolsState.color)),
        put(changeAreaStrokeWidth(initialToolsState.areaStrokeWidth)),
        put(changeAreaOpacity(initialToolsState.areaOpacityValue)),
        put(clearActiveForm()),
        put(resetMappingFormState()),
    ])
}

export const selectRegionForm = createSelector(
    (state: RootState) => {
        return state.IMUP.forms.formCache[GENERAL_CACHE]?.[REGION_ENUMS.TYPE]
    },
    (form) => form ?? null
)

function* handleHydrateRegionsForm() {
    const { uiSchema } = materialToFormMap[REGION_ENUMS.TYPE]
    const completeSchema = yield call(getFormSchema, REGION_ENUMS.TYPE, GENERAL_CACHE)
    // cache the new form, fields, and activate the form for use

    yield put(
        cacheForm({
            form: { schema: omit(completeSchema, 'description'), uiSchema },
            type: REGION_ENUMS.TYPE,
            buildingId: GENERAL_CACHE,
        })
    )
    yield put(activateForm(completeSchema.title!))
}

function* handleHydrateMasterSetPlanForm({ payload }: ReturnType<typeof hydrateMasterSetPlanForm>) {
    if (payload === undefined) return

    try {
        const schemaMasterSetEnumType: MASTER_SET_PLAN_ENUMS = payload
        const { uiSchema } = materialToFormMap[schemaMasterSetEnumType]

        const completeSchema = yield call(getFormSchema, schemaMasterSetEnumType, GENERAL_CACHE)
        // cache the new form, fields, and activate the form for use

        yield put(
            cacheForm({
                form: { schema: omit(completeSchema, 'description'), uiSchema },
                type: schemaMasterSetEnumType,
                buildingId: GENERAL_CACHE,
            })
        )
        yield put(activateForm(completeSchema.title!))
    } catch (e) {
        console.error(e)
    }
}

/**
 * createRegionGroupWithFunctionality
 * Private helper function to draw a region on the canvas then attach selection functionality to the drawn path
 * @param region The region to render
 * @param color The color of the region
 * @param regionTool An instance of the region tool to use to draw
 */
export function* createRegionGroupWithFunctionality(region: Region, color: paper.Color, regionTool: RegionTool) {
    const regionPath: paper.Path = yield call(
        regionTool.renderRegion,
        color,
        region.coordinates,
        region.id,
        region.scale
    )
    // Assign the region id to the path itself

    regionPath.data.region_id = region.id

    const allDrawablesOnPage: IMUP2DDrawableLocation[] = yield select(selectDrawableLocationsForPage)
    const drawablesInRegion = allDrawablesOnPage.filter((d) => regionTool.isLocationInsideRegion(d, regionPath))

    regionPath.data.selectable = drawablesInRegion.length === 0

    yield call(addSelectFunctionalityToRegion, regionPath)
}

export function* handleDrawPlanReview() {
    // set material type and configure tools for region creation
    yield fork(configureToolsForRegionCreation)

    // get the 2D drawing manager
    const manager: PaperManager | null = yield call(managers.get2DManager)

    // do nothing if the manager is null
    if (isNull(manager)) return

    const activeDocumentChunk: DocumentChunk | null = yield select(selectActiveDocumentChunk)

    if (isNull(activeDocumentChunk)) return

    const documentMappings: DocumentMapping[] | null = yield select(selectDocumentMappings)

    if (isNull(documentMappings)) return

    const activeDocumentMapping = documentMappings.find(
        (mapping) => mapping.document_chunk_id === activeDocumentChunk.id
    )

    if (activeDocumentMapping) {
        yield put(updateActiveMapping(activeDocumentMapping))
    }

    // scene is rendering
    yield put(sceneRendering())

    // get tools from manager
    const [imageTool, workspaceTool, colorTool, regionTool, masterSetPlanRegionTool, pathTool, labelTool] = yield call(
        manager.getTools,
        [Image.NAME, Workspace.NAME, Color.NAME, RegionTool.NAME, MasterSetPlanRegion.NAME, PathTool.NAME, Label.NAME]
    )

    yield call(workspaceTool.clear)

    // load the plan blueprint image for the active floor
    yield call(imageTool.insertImage, activeDocumentChunk.src)

    yield call(manager.useTool, Select.NAME)

    const regions = yield select(selectAllRegions)

    const localRegions: Region[] = yield regions.filter((region) => region.document_chunk_id === activeDocumentChunk.id)

    if (localRegions.length) {
        const color: paper.Color = colorTool.createColor(REGION_COLOR)

        yield all(localRegions.map((region) => call(createRegionGroupWithFunctionality, region, color, regionTool)))
    }

    const masterSetPlanOptionsForActivePage: ReturnType<typeof selectMasterSetPlanOptionsForActivePage> = yield select(
        selectMasterSetPlanOptionsForActivePage
    )

    // draw master set plan options
    if (masterSetPlanOptionsForActivePage.length) {
        yield
        yield all(
            masterSetPlanOptionsForActivePage.flatMap((msOption) => [
                call(
                    masterSetPlanRegionTool.renderBaseHomeOptionPageRegion,
                    msOption.coordinates,
                    msOption.id,
                    msOption.isOption
                ),
                put(addMasterSetPlanOptionToStore(msOption)),
            ])
        )
    }

    const measurementToolObjects: IToolObject[] = yield select(selectMeasurementToolObjects)

    const localMeasurementToolObjects = measurementToolObjects.filter(
        (option) => option.document_chunk_id === activeDocumentChunk.id
    )

    if (localMeasurementToolObjects.length > 0) {
        const { lineOpacity }: ReturnType<typeof selectMarkupState> = yield select(selectMarkupState)

        yield all(
            localMeasurementToolObjects.map((measurement) =>
                call(drawToolByType, measurement, colorTool, pathTool, workspaceTool, lineOpacity, labelTool)
            )
        )
    }

    if (activeDocumentMapping?.additional_data.parentDocumentMapping) {
        yield put(
            updateMasterSetPlanFormState({
                name: activeDocumentMapping.page_name,
                type: activeDocumentMapping.type,
                scale: activeDocumentMapping.scale_factor,
            })
        )
    }

    yield put(sceneRendered())
}

export function* watchForDrawPlanReview() {
    yield takeLatest(drawPlanReview.type, handleDrawPlanReview)
}

export function* watchForExitedPlanReview() {
    yield takeLatest(exitPlanReview.type, cleanupPlanReview)
}

export function* watchForHydrateRegionsForm() {
    yield takeLatest(hydrateRegionsForm.type, handleHydrateRegionsForm)
}

export function* watchForHydrateMasterSetPlanForm() {
    yield takeLatest(hydrateMasterSetPlanForm.type, handleHydrateMasterSetPlanForm)
}
