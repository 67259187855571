import { UiSchema } from '@rjsf/core'

import { calculateAreaMeasurementsForMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { Region } from '../models/region'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { calculateTotalArea } from '../utils/formSchema/formSchema-helper-functions'

export const uiSchema: UiSchema = {
    'ui:order': [
        'interior_finished_ceiling_selection',
        'interior_finished_ceiling_tg_width',
        'interior_finished_ceiling_pitch',
        'interior_finished_ceiling_ignore_for_report',
    ],
    selection: {
        'ui:title': 'Interior Ceiling Type',
        'ui:placeholder': 'Select Interior Ceiling Type',
    },
    tg_width: {
        'ui:title': 'Ceiling TG Width',
        'ui:placeholder': 'TG Width',
    },
    pitch: {
        'ui:title': 'Pitch',
        'ui:placeholder': 'Pitch',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return {
        ...settings,
        ...formData,
        tg_width: formData.selection === 'LITIA SQ FT' ? '' : formData.tg_width,
    }
}

/**
 * Augment the drawables of the from based on the new settings
 * @returns
 * @param input
 */
function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
    dpi: number | null
    pdfScale: number
    xCalibrationFactor: number
    yCalibrationFactor: number
    regions: Region[]
}): ActiveDrawable[] {
    const { drawables, newSettings, scaleFactor, dpi, pdfScale, xCalibrationFactor, yCalibrationFactor, regions } =
        input

    return drawables.map((drawable) => {
        const totalArea: number = calculateTotalArea(
            drawable.opening_locations,
            regions,
            scaleFactor,
            pdfScale,
            xCalibrationFactor,
            yCalibrationFactor,
            dpi
        )

        drawable.settings.quantity = calculateAreaMeasurementsForMaterial(totalArea, {
            type: DRAWABLE_TYPES.INTERIOR_FINISHED_CEILING,
            settings: newSettings,
        })

        return drawable
    })
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form
