import { FieldValues } from 'react-hook-form'
import { UiSchema } from '@rjsf/core'
import omit from 'lodash/omit'

import { FormProperties } from '../imup/types'
import { DRAWABLE_SETTINGS_TYPES } from '../shared/constants/drawable-types'
import { prepareValue } from '../shared/services/opening-form-services'

const otherValue = 'other'

export const valuesPreparation = (formData: FieldValues) => {
    const settings = { ...formData }

    if (prepareValue(settings.type) !== DRAWABLE_SETTINGS_TYPES.DIMENSIONAL_JOIST) {
        settings.bridging_blocking = null
        settings.blocking_material = null
        settings.bridging_material = null
    }

    if (prepareValue(settings.type) === DRAWABLE_SETTINGS_TYPES.DIMENSIONAL_JOIST) {
        if (prepareValue(settings.bridging_blocking) === 'bridging') {
            settings.blocking_material = null
        }

        if (prepareValue(settings.bridging_blocking) === 'blocking') {
            settings.bridging_material = null
        }
    }

    if (prepareValue(settings.type) !== DRAWABLE_SETTINGS_TYPES.FLOOR_TRUSS) {
        settings.truss_size = null
        settings.rimboard_ribbon = null
        settings.rimboard_material = null
        settings.rimboard_thickness = null
        settings.ribbon_material = null
    }

    if (prepareValue(settings.type) === DRAWABLE_SETTINGS_TYPES.FLOOR_TRUSS) {
        if (prepareValue(settings.rimboard_ribbon) === 'rimboard') {
            settings.ribbon_material = null
            settings.ribbon_size = null
        }

        if (prepareValue(settings.rimboard_ribbon) === 'ribbon') {
            settings.rimboard_material = null
            settings.rimboard_thickness = null
        }
    }

    if (
        prepareValue(settings.type) !== DRAWABLE_SETTINGS_TYPES.DIMENSIONAL_SLEEPER_JOIST &&
        prepareValue(settings.type) !== DRAWABLE_SETTINGS_TYPES.DIMENSIONAL_JOIST
    ) {
        settings.board_size = null
        settings.rimboard = null
    }

    if (prepareValue(settings.type) !== DRAWABLE_SETTINGS_TYPES.I_JOIST) {
        settings.ijoist_size = null
        settings.bci_series = null
        settings.lpi_series = null
        settings.pwi_series = null
        settings.tji_series = null
        settings.ajs_series = null
    }

    if (prepareValue(settings.type) === DRAWABLE_SETTINGS_TYPES.I_JOIST) {
        if (prepareValue(settings.ijoist_size).includes('tji')) {
            settings.bci_series = null
            settings.lpi_series = null
            settings.pwi_series = null
            settings.ajs_series = null
        }

        if (prepareValue(settings.ijoist_size).includes('bci')) {
            settings.tji_series = null
            settings.lpi_series = null
            settings.pwi_series = null
            settings.ajs_series = null
        }

        if (prepareValue(settings.ijoist_size).includes('lpi')) {
            settings.bci_series = null
            settings.tji_series = null
            settings.ajs_series = null
            settings.pwi_series = null
        }

        if (prepareValue(settings.ijoist_size).includes('pwi')) {
            settings.bci_series = null
            settings.tji_series = null
            settings.ajs_series = null
            settings.lpi_series = null
        }

        if (prepareValue(settings.ijoist_size).includes('ajs')) {
            settings.lpi_series = null
            settings.bci_series = null
            settings.tji_series = null
            settings.pwi_series = null
        }
    }

    return settings
}

export const uiSchema: UiSchema = {
    'ui:order': [
        'type',
        'oc_spacing',
        'oc_spacing_other',
        'brand',

        'board_size',
        'rimboard',
        'bridging_blocking',
        'bridging_material',
        'blocking_material',

        'truss_size',
        'rimboard_ribbon',
        'rimboard_material',
        'rimboard_thickness',
        'ribbon_size',

        'ijoist_size',
        'ajs_series',
        'bci_series',
        'lpi_series',
        'pwi_series',
        'tji_series',

        'is_treated',
        'direction',
        'include_rimboard',
        'engineered',
    ],
    type: {
        'ui:placeholder': 'Select Floor System Type',
    },
    oc_spacing: {
        'ui:placeholder': 'Select O.C Spacing',
    },
    board_size: {
        'ui:placeholder': 'Select Board Size',
    },
    rimboard: {
        'ui:placeholder': 'Select RimBoard',
    },
    bridging_blocking: {
        'ui:placeholder': 'Select Bridging/Blocking',
    },
    direction: {
        'ui:field': 'DropdownField',
        'ui:placeholder': 'Select Joist Direction',
    },
    is_treated: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    ijoist_size: {
        'ui:placeholder': 'Select I-Joist Size',
    },
    ajs_series: {
        'ui:placeholder': 'Select AJS Series',
    },
    bci_series: {
        'ui:placeholder': 'Select BCI Series',
    },
    lpi_series: {
        'ui:placeholder': 'Select LPI Series',
    },
    pwi_series: {
        'ui:placeholder': 'Select PWI Series',
    },
    tji_series: {
        'ui:placeholder': 'Select TJI Series',
    },
    truss_size: {
        'ui:placeholder': 'Select Truss Size',
    },
    rimboard_ribbon: {
        'ui:placeholder': 'Select Rimboard Ribbon',
    },
    engineered: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    brand: {
        'ui:placeholder': 'Select Brand',
    },
    include_rimboard: {
        'ui:title': 'Include Rimboard?',
        'ui:field': 'SwitchField',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = {
        ...settings,
        ...omit(valuesPreparation(formData), ['quantity']),
    }

    if (newSettings.oc_spacing === otherValue) {
        newSettings.oc_spacing = newSettings.oc_spacing_other
    }

    newSettings.oc_spacing_other = null

    if (newSettings.brand) {
        newSettings.ijoist_size = `${newSettings.brand} ${newSettings.ijoist_size}`

        // it's only for UI, we don't need to send it
        delete newSettings.brand
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
