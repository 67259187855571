import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    lf_type: {
        'ui:title': '"LF Type',
        'ui:placeholder': 'Select LF Type',
    },
    item_number: {
        'ui:title': 'Railing Material',
        'ui:placeholder': 'Select Railing Material',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return { ...settings, ...formData }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
