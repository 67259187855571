import { distinctUntilChanged, takeWhile } from 'rxjs'

import Base from '../paperTool/PaperTool'
import { initial2DState } from '../../../../../slices/2D'
import { ArrowDirection, Cursors, IMUPState, PaperToolConfig, VIEW_MODE } from '../../../../../types'

/**
 * Pan.tool.ts
 * Pans paper canvas based on mouse coordinates
 */
export class Pan extends Base {
    static NAME = 'PAN'
    static CURSOR = Cursors.GRAB
    static ACTIVE_CURSOR = Cursors.GRABBING

    private defaultCenter = initial2DState.defaultCenter

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Pan.NAME
        this.cursor = Pan.CURSOR
        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                distinctUntilChanged()
            )
            .subscribe((state) => {
                this.defaultCenter = state['2D'].defaultCenter
            })
    }

    onMouseUp = (event: paper.ToolEvent): void => {
        this.setState('common', { cursor: Pan.CURSOR })
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        this.setState('common', { cursor: Pan.ACTIVE_CURSOR })
    }

    onMouseDrag = (event: paper.ToolEvent): void => {
        const diffBetweenStartAndCurrentPos = event.downPoint.subtract(event.point)
        const diffReferencedToPaperCenter = diffBetweenStartAndCurrentPos.add(this.paper.view.center)

        this.paper.view.center = diffReferencedToPaperCenter
    }

    /**
     * Move around the 2D plane incrementally
     * @param direction enum: up, left, down, right
     * @param step amount to move in paper coordinates
     */
    move = (direction: ArrowDirection, step: number) => {
        const center = this.paper.view.center

        const scaledStep = step / this.paper.view.zoom // scale the step to the zoom so step appears to be same amount

        let deltaPoint: paper.Point = new this.paper.Point(0, 0)

        switch (direction) {
            case 'UP':
                deltaPoint = new this.paper.Point(0, -scaledStep)
                break
            case 'LEFT':
                deltaPoint = new this.paper.Point(-scaledStep, 0)
                break
            case 'DOWN':
                deltaPoint = new this.paper.Point(0, scaledStep)
                break
            case 'RIGHT':
                deltaPoint = new this.paper.Point(scaledStep, 0)
                break
        }

        this.paper.view.center = center.add(deltaPoint)
    }

    /**
     * Center the project based on the center of the image
     */
    center = () => {
        const [x, y] = this.defaultCenter

        this.paper.view.center = new this.paper.Point(x, y)
    }
}

export default Pan
