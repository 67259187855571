import { distinctUntilChanged, map, takeWhile } from 'rxjs'

import Base from '../paperTool/PaperTool'
import { initialToolsState, ToolsState } from '../../../../../slices/tools'
import { IMUPState, PaperToolConfig, VIEW_MODE } from '../../../../../types'

/**
 * Pencil Tool
 * Creates a freeform line underneath a mouse drag
 */
// TODO: Implement tool further when editing functionality is created
export class Pencil extends Base {
    private path: paper.Path | null = null

    static NAME = 'PENCIL'

    private color: ToolsState['color'] = initialToolsState.color

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Pencil.NAME

        this.mediator
            .get$()
            .pipe(
                takeWhile(
                    (state: IMUPState) =>
                        state.common.activeMode === VIEW_MODE.Markup2D ||
                        state.common.activeMode === VIEW_MODE.Markup2DFor3D
                ),
                map((state: IMUPState) => ({ color: state.tools.color, strokeWidth: state.tools.strokeWidth })),
                distinctUntilChanged()
            )
            .subscribe(({ color, strokeWidth }) => {
                this.color = color
                this.strokeWidth = strokeWidth
            })
    }

    onMouseDown = (event: paper.MouseEvent): void => {
        this.path = new this.paper.Path()
        this.path.strokeColor = new this.paper.Color(this.color)
        this.path.strokeWidth = this.strokeWidth
        this.path.add(event.point)
    }

    onMouseDrag = (event: paper.MouseEvent): void => {
        if (this.path) {
            this.path.add(event.point)
        }
    }
}

export default Pencil
