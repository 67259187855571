import { createAction } from '@reduxjs/toolkit'
import { call, put, select, throttle } from 'redux-saga/effects'

import { updateFlagOpenings } from '../../../api/projects-api'
import { FlagOpeningLinksAPIUpdatePayload, NormalizedFlag } from '../../../models/flags'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Flag } from '../../lib/toolBoxes/2D'
import { updateToolbarMessage } from '../../slices/common'
import { selectMaterialFlags, updateFlagOpeningCoordinates } from '../../slices/materialFlags'
import { SAGA_THROTTLE_TIMER, SAVING_MESSAGE } from '../../types'

export const updateFlagCoordinatesAction = createAction<{ id: number; openingId: number | null }>(
    'updateFlagCoordinates'
)

export function* handleUpdateFlagCoordinates({ payload }: ReturnType<typeof updateFlagCoordinatesAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const flagTool: Flag = yield call(manager.getTool, Flag.NAME)

    const flagGroups: paper.Group[] = yield call(flagTool.getCachedFlags)

    const flagGroupInQuestion: paper.Group | undefined = flagGroups.find((group) => group.data.flag_id === payload.id)

    if (!flagGroupInQuestion) return

    const flags: NormalizedFlag[] = yield select(selectMaterialFlags)

    const flagInQuestion: NormalizedFlag | undefined = flags.find((flag) => flag.id === payload.id)

    if (!flagInQuestion) return

    yield put(updateToolbarMessage(SAVING_MESSAGE))

    const openingLinks = flagInQuestion.opening_links.reduce((oLinks, oL) => {
        if (oL.opening_id === payload.openingId) {
            oLinks.push({
                id: oL.id,
                flag_id: payload.id,
                coordinates: flagTool.getFlagCoordinates(flagGroupInQuestion) as [number, number],
                opening_id: payload.openingId || undefined,
            })
        } else {
            oLinks.push({
                id: oL.id,
                flag_id: payload.id,
                coordinates: oL.coordinates,
                opening_id: oL.opening_id || undefined,
            })
        }

        return oLinks
    }, [] as FlagOpeningLinksAPIUpdatePayload[])

    yield call(updateFlagOpenings, { opening_links: openingLinks })

    yield put(updateFlagOpeningCoordinates({ newOpeningLinkData: openingLinks }))

    yield put(updateToolbarMessage(null))
}

export function* watchForUpdateFlagCoordinates() {
    yield throttle(SAGA_THROTTLE_TIMER, updateFlagCoordinatesAction.type, handleUpdateFlagCoordinates)
}
