import { createAction } from '@reduxjs/toolkit'
import { call, fork, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { handleCloseMaterialForm } from '../effects/handleCloseMaterialForm'
import { selectProject } from './createDrawableLocation'
import { deleteAiSuggestion, deleteHighlightLocation, selectBaseDrawableState } from './deleteDrawableLocation'
import { deleteMasterSetOptionOrBaseHome } from './deleteMasterSetOptionsAndBaseHomeDocuments'
import { deleteModificationAction } from './deleteModification'
import { deleteToolObject } from './deleteToolObject'
import { ActiveFloor } from '../../../models/activeFloor'
import { Project } from '../../../models/project'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Highlight, Select, Workspace } from '../../lib/toolBoxes/2D'
import { deleteDrawableLocation, removeSelectedAiSuggestionIds, selectedAiSuggestionIds } from '../../slices/2D'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { IMUP2DDrawableLocation } from '../../types'

export const deleteDrawableLocationsFromUI = createAction<number[] | null>('deleteDrawableLocationsFromUI')

export function* handleDeleteDrawableLocationsFromUI({ payload }: ReturnType<typeof deleteDrawableLocationsFromUI>) {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const [workspaceTool, selectTool, highlightTool, colorTool]: [Workspace, Select, Highlight, Color] = yield call(
            manager.getTools,
            [Workspace.NAME, Select.NAME, Highlight.NAME, Color.NAME]
        )

        // get the data needed for the api call
        const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)
        const project: Project = yield select(selectProject)
        const activeAiSuggestionIds: string[] = yield select(selectedAiSuggestionIds)
        const { drawableLocations }: { drawableLocations: IMUP2DDrawableLocation[] } = yield select(
            selectBaseDrawableState
        )

        // we can't use payload, because when we click on as a suggestion, we create an opening group,
        // new opening for clicked material and remove an AI suggestion from the UI
        if (!!activeAiSuggestionIds.length) {
            // remove a selected AI suggestion
            yield call(deleteAiSuggestion, activeAiSuggestionIds)

            // remove a created group, opening and close the form
            yield fork(handleCloseMaterialForm)

            // unset selected ai suggestion ids
            yield put(removeSelectedAiSuggestionIds())

            return
        }

        // used to delete tool objects and highlights
        if (payload) {
            const items: paper.Item[] | null = yield call(workspaceTool.getItemsWithCriteria, 'id', (id) =>
                payload.includes(id)
            )

            if (!items?.length) return

            if (items[0].data.isToolObject) {
                yield call(deleteToolObject, items)

                return
            }

            // delete material conflicts
            const materialModificationConflictItems = items.filter((i) => i.data.materialModificationConflict)

            if (materialModificationConflictItems.length) {
                yield put(deleteModificationAction(items))
            }

            // TODO: add support of multiple deleting of highlight
            if (items[0].data.isHighlight) {
                yield call(
                    deleteHighlightLocation,
                    highlightTool,
                    colorTool,
                    selectTool,
                    drawableLocations,
                    project,
                    activeFloor,
                    items[0]
                )

                return
            }

            if (items[0].data.masterSetPlanId) {
                yield deleteMasterSetOptionOrBaseHome(items)
            }
        }

        // used to delete selected materials
        // gets all selected elements on a page
        const activeItems: paper.Item[] | null = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => data.drawable_id && data.isSelected
        )

        if (!activeItems?.length) return

        const activeItemsDrawableIds: number[] = activeItems.map((item) => item.data.drawable_id)

        // update the store, delete drawable locations, this also triggers deleteDrawableLocation effect,
        // which will delete the items from UI and call api to delete them
        yield put(deleteDrawableLocation(activeItemsDrawableIds))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForDeleteDrawableLocationsFromUI(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(deleteDrawableLocationsFromUI.type, handleDeleteDrawableLocationsFromUI)
}
